import styled from 'styled-components/native';
import TouchableRipple from '../../../components/TouchableRipple';
import theme from '../../theme';
export const bgColor = (btnTheme, disabledState, { WhiteColor, LightBackground, Background, disabledGreenButtonColor, BrighteGreen }) => {
    switch (btnTheme) {
        case 'white':
            return disabledState ? WhiteColor : WhiteColor;
        case 'grey':
            return disabledState ? LightBackground : Background;
        default:
            return disabledState ? disabledGreenButtonColor : BrighteGreen;
    }
};
const Button = styled(TouchableRipple) `
  align-items: center;
  background-color: ${({ theme, btnTheme, disabled }) => bgColor(btnTheme, disabled, theme)};
  border-radius: ${({ theme }) => theme.blockBorderRadius};
  border-color: ${({ theme, btnTheme }) => (btnTheme === 'white' ? theme.FormElements : 'transparent')};
  border-width: ${({ btnTheme }) => (btnTheme === 'white' ? '1px' : '0')};
  elevation: 1;
  height: ${({ theme }) => theme.buttonHeight};
  min-height: ${({ theme }) => theme.buttonHeight};
  justify-content: center;
  shadow-offset: 0px 2px;
  shadow-opacity: 1;
  shadow-color: ${({ theme, btnTheme, disabled }) => {
    switch (btnTheme) {
        case 'green':
            return disabled ? theme.disabledGreenButtonColor : theme.GreenShadow;
        default:
            return theme.Background;
    }
}};
  shadow-radius: 0;
  width: 100%;

  ${props => props.customStyle}
`;
Button.defaultProps = {
    theme: {
        WhiteColor: theme.WhiteColor,
        hoverGreyButtonColor: theme.hoverGreyButtonColor,
        Background: theme.Background,
        disabledGreenButtonColor: theme.disabledGreenButtonColor,
        BrighteGreen: theme.BrighteGreen,
        LightBackground: theme.LightBackground,
        blockBorderRadius: theme.blockBorderRadius,
        FormElements: theme.FormElements,
        buttonHeight: theme.buttonHeight,
        GreenShadow: theme.GreenShadow,
    },
};
export default Button;
