var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components/native';
import { View } from 'react-native';
import theme from '../../theme';
const BodyCell = styled((_a) => {
    var { theme, responsiveClass } = _a, rest = __rest(_a, ["theme", "responsiveClass"]);
    return <View {...rest}/>;
}) `
  display: flex;
  position: relative;
  top: 0;
  width: auto;
  height: auto;
  align-items: center;
  background-color: ${theme.WhiteColor};
  ${({ responsiveClass, theme }) => responsiveClass === 'XS' &&
    `margin-left: ${theme.fifteenPT};
    margin-right: ${theme.fifteenPT};`}
  ${props => props.customStyle}
`;
BodyCell.defaultProps = {
    theme: {
        BrighteGreen: theme.BrighteGreen,
        thirtyPT: theme.thirtyPT,
        fifteenPT: theme.fifteenPT,
        hunredThirtyPT: theme.hunredThirtyPT,
        WhiteColor: theme.WhiteColor,
    },
};
export default BodyCell;
