/**
 * Handles mobile responsiveness in mobile/RN
 */
import React, { createContext, useState, useMemo, useContext } from 'react';
import { Dimensions, Animated } from 'react-native';
import { ThemeContext } from 'styled-components/native';
import { getResponsiveClass, ResponseSize } from '@brighte/brighte-one-core';
import useAnimation from '../hooks/useAnimation';
export const ResponsiveContext = createContext(ResponseSize.XS);
const ResponsProvider = ({ children }) => {
    const theme = useContext(ThemeContext);
    const initWidth = Math.round(Dimensions.get('window').width);
    const [responsiveClass, setResponsiveClass] = useState(getResponsiveClass(initWidth, theme));
    const [opacity] = useAnimation();
    return useMemo(() => (<ResponsiveContext.Provider value={responsiveClass}>
        <Animated.View style={{ opacity, flex: 1 }} onLayout={({ nativeEvent: { layout: { width }, }, }) => setResponsiveClass(getResponsiveClass(width, theme))}>
          {children}
        </Animated.View>
      </ResponsiveContext.Provider>), [responsiveClass]);
};
export default ResponsProvider;
