var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import StyledLabel from '../../styles/elements/Label';
const Label = (_a) => {
    var { customStyle, children, text, id } = _a, rest = __rest(_a, ["customStyle", "children", "text", "id"]);
    return (<StyledLabel {...(id ? { nativeID: id } : {})} customStyle={customStyle} {...rest}>
    {text || children}
  </StyledLabel>);
};
Label.defaultProps = {
    children: undefined,
    customStyle: {},
    text: '',
    dataSet: undefined,
};
export default Label;
