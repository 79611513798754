var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useMemo, useContext } from 'react';
import { Animated } from 'react-native';
import { isFunction, isEmpty, get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { actions, FormContext } from '@brighte/brighte-one-core';
import useAnimation from '../hooks/useAnimation';
const withVisibility = (settings) => (WrapperComponent) => {
    const WithVisibility = (props) => {
        const { clearFieldValues, visibilityLogic, watchVisibleFields, flex, zIndex } = props, rest = __rest(props, ["clearFieldValues", "visibilityLogic", "watchVisibleFields", "flex", "zIndex"]);
        // Redux Hooks
        const { formName } = useContext(FormContext);
        const store = useSelector(state => state);
        const dispatch = useDispatch();
        // logic && setState && setState(logic);
        const visible = isFunction(visibilityLogic) ? visibilityLogic(store, formName) : true;
        const [animationValue] = useAnimation(visible ? 1 : 0, [visible]);
        useEffect(() => {
            const { fieldName } = props;
            // Check if store is available
            const hasStore = get(store, `formData[${formName}].fields[${fieldName}]`, false);
            if (hasStore && fieldName && !isEmpty(fieldName)) {
                dispatch(actions.setVisibility(visible, fieldName, formName, clearFieldValues));
            }
            // This is for group visibilities like sections, rows to set childs visibility
            if (isEmpty(props.fieldName) && watchVisibleFields && !visible) {
                dispatch(actions.setVisibility(visible, watchVisibleFields, formName, clearFieldValues));
            }
        }, [visible]);
        const defaultViewStyle = { opacity: animationValue, flex };
        // only set zIndex, if specified.
        const style = typeof zIndex === 'number' ? Object.assign({ zIndex }, defaultViewStyle) : Object.assign({}, defaultViewStyle);
        return useMemo(() => {
            if (settings.flagOnly) {
                return <WrapperComponent visible={visible} {...rest}/>;
            }
            return visible ? (<Animated.View style={style}>
          <WrapperComponent visible={visible} {...rest}/>
        </Animated.View>) : null;
        }, [visible, rest]);
    };
    WithVisibility.defaultProps = {
        flex: 1,
        clearFieldValues: false,
    };
    return WithVisibility;
};
export default withVisibility;
