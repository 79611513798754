import { TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
import theme from '../theme';
export const backgroundColor = ({ theme: { MediumText, WhiteColor }, checked }) => {
    if (checked) {
        return MediumText;
    }
    return WhiteColor;
};
export const borderColor = ({ theme: { LightText, MediumText }, checked }) => {
    if (checked) {
        return MediumText;
    }
    return LightText;
};
const CheckBox = styled(TouchableOpacity) `
  background-color: ${({ checked, theme }) => backgroundColor({ theme, checked })};
  border-color: ${({ checked, theme }) => borderColor({ theme, checked })};
  width: ${({ theme }) => theme.checkboxSize};
  height: ${({ theme }) => theme.checkboxSize};
  border-width: ${({ theme }) => theme.threePT};
  border-radius: ${({ theme }) => theme.threePT};
  justify-content: center;
  ${props => props.customStyle}
`;
CheckBox.defaultProps = {
    theme: {
        LightBackground: theme.LightBackground,
        MediumText: theme.MediumText,
        WhiteColor: theme.WhiteColor,
        FormElements: theme.FormElements,
        blockBorderRadius: theme.blockBorderRadius,
        checkboxSize: theme.checkboxSize,
        threePT: theme.threePT,
    },
};
export default CheckBox;
