import React from 'react';
import get from 'lodash/get';
import { ResponseSize } from '../utilities/types';
/**
 * drawDebug function is for grid system so developer can easily see layout grid
 */
export var drawDebug = function () { return ['background: rgba(0, 255, 255, 0.1);', 'border: 1px dashed rgba(255, 0, 255, 1);']; };
var translations = {
    direction: {
        horizontal: 'row',
        vertical: 'column',
    },
    wrap: {
        true: 'wrap',
        1: 'wrap',
        false: 'nowrap',
        0: 'nowrap',
    },
    align: {
        top: 'flex-start',
        left: 'flex-start',
        bottom: 'flex-end',
        right: 'flex-end',
        center: 'center',
        middle: 'center',
    },
};
export var flex = function (flexConfig) {
    // eslint-disable-next-line no-shadow
    var flex = flexConfig.flex, flexDirection = flexConfig.flexDirection, wrap = flexConfig.wrap, valign = flexConfig.valign, halign = flexConfig.halign;
    var props = [];
    if (flex) {
        props.push("flex: " + flex + ";");
    }
    if (flexDirection) {
        props.push("flex-direction: " + get(translations.direction, flexDirection, 'initial') + ";");
    }
    if (wrap) {
        props.push("flex-wrap: " + get(translations.wrap, wrap, '') + ";");
    }
    if (halign && flexDirection === 'horizontal') {
        props.push("justify-content: " + get(translations.align, halign, '') + ";");
    }
    if (halign && flexDirection !== 'horizontal') {
        props.push("align-items: " + get(translations.align, halign, '') + ";");
    }
    if (valign && flexDirection === 'horizontal') {
        props.push("align-items: " + get(translations.align, valign, '') + ";");
    }
    if (valign && flexDirection !== 'horizontal') {
        props.push("justify-content: " + get(translations.align, valign, '') + ";");
    }
    return props;
};
// getRandomId from https://stackoverflow.com/questions/6860853/generate-random-string-for-div-id
export var getRandomId = function () {
    var s4 = function () { return ((1 + Math.random()) * 0x10000 || 0).toString(16).substring(1); }; // eslint-disable-line no-bitwise
    return "" + s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
};
export var applyResponsiveness = function (responsiveClass, responsiveBreakPoint) {
    var breakPoints = [ResponseSize.XS, ResponseSize.SM, ResponseSize.MD, ResponseSize.LG, ResponseSize.XL];
    var responsiveClassPosition = breakPoints.indexOf(responsiveClass);
    var responsiveBreakPointPosition = breakPoints.indexOf(responsiveBreakPoint);
    return responsiveClassPosition <= responsiveBreakPointPosition;
};
// This method is to get a class which is used for responsiveness
export var getResponsiveClass = function (width, theme) {
    switch (true) {
        case width < theme.xsBrakePint:
            return ResponseSize.XS;
        case width >= theme.xsBrakePint && width < theme.smBrakePint:
            return ResponseSize.SM;
        case width >= theme.smBrakePint && width < theme.mdBrakePint:
            return ResponseSize.MD;
        case width >= theme.mdBrakePint && width < theme.lgBrakePint:
            return ResponseSize.LG;
        default:
            return ResponseSize.XL;
    }
};
export var recursiveMap = function (children, fn) {
    return React.Children.map(children, function (child) {
        if (!React.isValidElement(child)) {
            return child;
        }
        if (get(child, 'props.children', false)) {
            child = React.cloneElement(child, {
                children: recursiveMap(child.props.children, fn),
            });
        }
        return fn(child);
    });
};
