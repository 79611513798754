import React, { useState } from 'react';
import { isFunction } from 'lodash';
const Hoverable = props => {
    const [isHovered, setHover] = useState(false);
    const [showHover, setshowHover] = useState(true);
    const handleMouseEnter = () => {
        if (!isHovered) {
            const { onHoverIn } = props;
            if (isFunction(onHoverIn)) {
                onHoverIn();
            }
            setHover(true);
        }
    };
    const handleMouseLeave = e => {
        if (isHovered) {
            const { onHoverOut } = props;
            if (isFunction(onHoverOut)) {
                onHoverOut(e);
            }
            setHover(false);
        }
    };
    const handleGrant = () => {
        setshowHover(false);
    };
    const handleRelease = () => {
        setshowHover(true);
    };
    const { children } = props;
    const child = isFunction(children) ? children(showHover && isHovered) : children;
    return React.cloneElement(React.Children.only(child), {
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
        // prevent hover showing while responder
        onResponderGrant: handleGrant,
        onResponderRelease: handleRelease,
        // if child is Touchable
        onPressIn: handleGrant,
        onPressOut: handleRelease,
    });
};
export default Hoverable;
