import styled from 'styled-components/native';
import { CloseNormal } from '../../../components/Icons';
import theme from '../../theme';
const CloseIcon = styled(CloseNormal) `
  align-self: center;
  justify-content: center;
  position: relative;
  font-size: ${theme.twentyPT};
  color: ${theme.MediumText};
  padding-right: ${theme.tenPT};
  ${props => props.customStyle}
`;
CloseIcon.defaultProps = {
    theme: {
        MediumText: theme.MediumText,
        tenPT: theme.tenPT,
        twentyPT: theme.twentyPT,
    },
};
export default CloseIcon;
