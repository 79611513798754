import React from 'react';
import { createFormatter } from '@brighte/brighte-one-core/src/utilities/formatters/currency';
// Polyfill for Intl until properly supported in Android
import 'intl';
import 'intl/locale-data/jsonp/en';
import TextInputContainer from './TextInputContainer';
const defaultProps = {
    allowDecimal: false,
    allowZero: true,
    currency: 'AUD',
    decimalPlaces: 0,
    useGrouping: true,
    type: 'decimal',
    locale: 'en-AU',
    valuePreFormatter: false,
};
const CurrencyInputContainer = (props) => {
    const { allowDecimal, allowZero, currency, decimalPlaces = defaultProps.decimalPlaces, locale = defaultProps.locale, type = defaultProps.type, useGrouping = defaultProps.useGrouping, valuePreFormatter, } = props;
    const formatConfig = {
        locale,
        style: type,
        useGrouping,
        divisor: allowDecimal ? 1 : Math.pow(10, decimalPlaces),
        minimumFractionDigits: decimalPlaces,
    };
    if (type === 'currency') {
        formatConfig.currency = currency;
    }
    const formatter = createFormatter(formatConfig, { allowDecimal, allowZero });
    const handleValuePreFormatter = value => {
        if (valuePreFormatter && typeof valuePreFormatter === 'function') {
            const valueFormatter = valuePreFormatter;
            return valueFormatter(value);
        }
        return value.replace(/[^0-9\.]/g, '');
    };
    return (<TextInputContainer inputType="numeric" keyboardType="decimal-pad" {...props} formatter={formatter} valuePreFormatter={handleValuePreFormatter}/>);
};
CurrencyInputContainer.defaultProps = defaultProps;
export default CurrencyInputContainer;
