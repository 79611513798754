import React, { useState, createContext } from 'react';
import { useDispatch } from 'react-redux';
import { setInitData } from '../actions';
export var FormContext = createContext({});
var FormContainer = function (props) {
    var formName = props.formName, _a = props.initData, initData = _a === void 0 ? {} : _a, children = props.children;
    var dispatch = useDispatch();
    useState(function () { return dispatch(setInitData(initData, formName)); });
    return <FormContext.Provider value={{ formName: formName }}>{children}</FormContext.Provider>;
};
FormContainer.defaultProps = {
    initData: {},
};
export default FormContainer;
