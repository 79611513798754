import throttle from 'lodash/throttle';
import { saveState } from '@brighte/brighte-one-core';
import theme from '../../styles/theme';
export default storageId => store => next => action => {
    switch (action.type) {
        default:
            next(action);
            if (theme.platform === 'web') {
                throttle(() => {
                    saveState(storageId, store.getState());
                }, 1000)();
            }
            break;
    }
};
