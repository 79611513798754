import styled from 'styled-components/native';
import { View } from 'react-native';
import theme from '../../theme';
const Footer = styled(View) `
  flex: 1;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  z-index: 199;
  width: 100%;
  height: ${theme.oneHunredEight};
  max-height: ${theme.oneHunredEight};
  min-height: ${theme.thirtyPT};
  align-content: center;
  align-items: center;
  justify-content: center;
  background-color: ${theme.WhiteColor};
  ${props => props.customStyle}
`;
Footer.defaultProps = {
    theme: {
        BrighteGreen: theme.BrighteGreen,
        thirtyPT: theme.thirtyPT,
        tenPT: theme.tenPT,
        eightPT: theme.eightPT,
        oneHunredEight: theme.oneHunredEight,
        WhiteColor: theme.WhiteColor,
        ModalDividerLine: theme.ModalDividerLine,
    },
};
export default Footer;
