var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import StyledCheckBox from '../../styles/blocks/CheckBox';
const Checkbox = props => {
    const { onPress, customStyle: { containerCustomStyle, iconColor } = {}, checked, className, as, tickContainerStyle, isError } = props, rest = __rest(props, ["onPress", "customStyle", "checked", "className", "as", "tickContainerStyle", "isError"]);
    return (<StyledCheckBox dataSet={{ 'brighte-class': className !== null && className !== void 0 ? className : '', error: isError }} checked={checked !== null && checked !== void 0 ? checked : false} customStyle={containerCustomStyle} onPress={e => onPress && onPress(e)} {...rest}>
      <StyledCheckBox.IconWrapper as={as} style={tickContainerStyle}>
        <StyledCheckBox.Icon size={14} checked={checked} color={iconColor || ''}/>
      </StyledCheckBox.IconWrapper>
    </StyledCheckBox>);
};
Checkbox.defaultProps = {
    as: undefined,
    checked: false,
    customStyle: {},
    className: 'brighte-checkbox',
    onPress: () => { },
    tickContainerStyle: {},
    isError: false,
};
export default Checkbox;
