import React from 'react';
import { createIconSet } from 'react-native-vector-icons';
import brighteFontGlyph from '../../assets/fonts/brighte-font-icon.json';
export const Icon = createIconSet(brighteFontGlyph, 'brighte-font-icon', 'brighte-font-icon.ttf');
export const AirConditioningVentilation = props => <Icon {...props} name="air-conditioning-ventilation"/>;
export const ArrowDown = props => <Icon {...props} name="arrow-down"/>;
export const ArrowLeftThin = props => <Icon {...props} name="arrow-left-thin"/>;
export const ArrowLeft = props => <Icon {...props} name="arrow-left"/>;
export const ArrowRight = props => <Icon {...props} name="arrow-right"/>;
export const ArrowUp = props => <Icon {...props} name="arrow-up"/>;
export const Awnings = props => <Icon {...props} name="awnings"/>;
export const BathroomRenovations = props => <Icon {...props} name="bathroom-renovations"/>;
export const BatteryStorage = props => <Icon {...props} name="battery-storage"/>;
export const BlindsCurtainsShutters = props => <Icon {...props} name="blinds-curtains-shutters"/>;
export const BrighteLogo = props => <Icon {...props} name="brighte_logo"/>;
export const BrighteLogoText = props => <Icon {...props} name="brighte_logo_text"/>;
export const Carports = props => <Icon {...props} name="carports"/>;
export const Check = props => <Icon {...props} name="check"/>;
export const CloseNormal = props => <Icon {...props} name="close-normal"/>;
export const CloseThin = props => <Icon {...props} name="close-thin"/>;
export const Close = props => <Icon {...props} name="close"/>;
export const Decking = props => <Icon {...props} name="decking"/>;
export const DirectDebit = props => <Icon {...props} name="direct-debit"/>;
export const DrivewaysConcretePaving = props => <Icon {...props} name="driveways-concrete-paving"/>;
export const Electrical = props => <Icon {...props} name="electrical"/>;
export const EnergyEfficientProducts = props => <Icon {...props} name="energy-efficient-products"/>;
export const Fencing = props => <Icon {...props} name="fencing"/>;
export const Fireplaces = props => <Icon {...props} name="fireplaces"/>;
export const Flooring = props => <Icon {...props} name="flooring"/>;
export const GarageDoors = props => <Icon {...props} name="garage-doors"/>;
export const Guttering = props => <Icon {...props} name="guttering"/>;
export const Headphones = props => <Icon {...props} name="headphones"/>;
export const HomeTheatre = props => <Icon {...props} name="home-theatre"/>;
export const HotWaterSystemsElectricGas = props => <Icon {...props} name="hot-water-systems-electric-gas"/>;
export const ImgPlaceholder = props => <Icon {...props} name="img-placeholder"/>;
export const Information = props => <Icon {...props} name="information"/>;
export const KitchenRenovations = props => <Icon {...props} name="kitchen-renovations"/>;
export const Landscaping = props => <Icon {...props} name="landscaping"/>;
export const Lighting = props => <Icon {...props} name="lighting"/>;
export const LoadingCircleContainer = props => <Icon {...props} name="loading-circle-container"/>;
export const LoadingCircleStatus = props => <Icon {...props} name="loading-circle-status"/>;
export const Loading = props => <Icon {...props} name="loading"/>;
export const Location = props => <Icon {...props} name="location"/>;
export const Mastercard = props => <Icon {...props} name="mastercard"/>;
export const Mattresses = props => <Icon {...props} name="mattresses"/>;
export const OffGridSystem = props => <Icon {...props} name="off-grid-system"/>;
export const Other = props => <Icon {...props} name="other"/>;
export const OutdoorBlinds = props => <Icon {...props} name="outdoor-blinds"/>;
export const Phone = props => <Icon {...props} name="phone"/>;
export const Plumbing = props => <Icon {...props} name="plumbing"/>;
export const PoolHeatingSystemsNonSolar = props => <Icon {...props} name="pool-heating-systems-non-solar"/>;
export const PoolsSpas = props => <Icon {...props} name="pools-spas"/>;
export const RealEstate = props => <Icon {...props} name="real-estate"/>;
export const Roofing = props => <Icon {...props} name="roofing"/>;
export const SecuritySystem = props => <Icon {...props} name="security-system"/>;
export const Sheds = props => <Icon {...props} name="sheds"/>;
export const SmartHomeTechnology = props => <Icon {...props} name="smart-home-technology"/>;
export const SolarBatterySystem = props => <Icon {...props} name="solar-battery-system"/>;
export const SolarHotWaterSystemsInclHeatPump = props => (<Icon {...props} name="solar-hot-water-systems-incl-heat-pump"/>);
export const SolarInverters = props => <Icon {...props} name="solar-inverters"/>;
export const SolarPanels = props => <Icon {...props} name="solar-panels"/>;
export const SolarPoolHeatingSystems = props => <Icon {...props} name="solar-pool-heating-systems"/>;
export const SolarSystem = props => <Icon {...props} name="solar-system"/>;
export const Solar = props => <Icon {...props} name="solar"/>;
export const TickRounded = props => <Icon {...props} name="tick-rounded"/>;
export const Tiling = props => <Icon {...props} name="tiling"/>;
export const TrailersCampers = props => <Icon {...props} name="trailers-campers"/>;
export const UploadShare = props => <Icon {...props} name="upload-share"/>;
export const VerandahsPatiosPergolas = props => <Icon {...props} name="verandahs-patios-pergolas"/>;
export const Visa = props => <Icon {...props} name="visa"/>;
export const WaterFiltration = props => <Icon {...props} name="water-filtration"/>;
export const WindowsDoors = props => <Icon {...props} name="windows-doors"/>;
export const World = props => <Icon {...props} name="world"/>;
export default {
    AirConditioningVentilation,
    ArrowDown,
    ArrowLeftThin,
    ArrowLeft,
    ArrowRight,
    ArrowUp,
    Awnings,
    BathroomRenovations,
    BatteryStorage,
    BlindsCurtainsShutters,
    BrighteLogo,
    BrighteLogoText,
    Carports,
    Check,
    CloseNormal,
    CloseThin,
    Close,
    Decking,
    DirectDebit,
    DrivewaysConcretePaving,
    Electrical,
    EnergyEfficientProducts,
    Fencing,
    Fireplaces,
    Flooring,
    GarageDoors,
    Guttering,
    Headphones,
    HomeTheatre,
    HotWaterSystemsElectricGas,
    ImgPlaceholder,
    Information,
    KitchenRenovations,
    Landscaping,
    Lighting,
    LoadingCircleContainer,
    LoadingCircleStatus,
    Loading,
    Location,
    Mastercard,
    Mattresses,
    OffGridSystem,
    Other,
    OutdoorBlinds,
    Phone,
    Plumbing,
    PoolHeatingSystemsNonSolar,
    PoolsSpas,
    RealEstate,
    Roofing,
    SecuritySystem,
    Sheds,
    SmartHomeTechnology,
    SolarBatterySystem,
    SolarHotWaterSystemsInclHeatPump,
    SolarInverters,
    SolarPanels,
    SolarPoolHeatingSystems,
    SolarSystem,
    Solar,
    TickRounded,
    Tiling,
    TrailersCampers,
    UploadShare,
    VerandahsPatiosPergolas,
    Visa,
    WaterFiltration,
    WindowsDoors,
    World,
};
