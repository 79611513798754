var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components/native';
import { View } from 'react-native';
import CloseWrapper from './CloseWrapper';
import CloseIcon from './CloseIcon';
import StyledText from './Text';
import CloseAllContainer from './CloseAllContainer';
import theme from '../../theme';
const TagContainer = styled((_a) => {
    var { theme, contentWidth } = _a, rest = __rest(_a, ["theme", "contentWidth"]);
    return <View {...rest}/>;
}) `
  width: ${({ contentWidth, theme }) => contentWidth + theme.sixtyPT};
  justify-content: center;
  flex-direction: row;
  align-items: center;
  border-radius: ${({ theme }) => theme.twoPT};
  background-color: ${({ theme }) => theme.LightBackground};
  height: ${({ theme }) => theme.thirtyFourPT};
  padding-left: ${({ theme }) => theme.fifteenPT};
  margin-right: ${({ theme }) => theme.tenPT};
  margin-top: ${({ theme }) => theme.fivePT};
  margin-bottom: ${({ theme }) => theme.fivePT};
  ${props => props.customStyle}
`;
TagContainer.defaultProps = {
    theme: {
        LightBackground: theme.LightBackground,
        thirtyFourPT: theme.thirtyFourPT,
        sixtyPT: theme.sixtyPT,
        tenPT: theme.tenPT,
        fivePT: theme.fivePT,
        twoPT: theme.twoPT,
    },
};
TagContainer.Text = StyledText;
TagContainer.CloseWrapper = CloseWrapper;
TagContainer.CloseIcon = CloseIcon;
TagContainer.CloseAllContainer = CloseAllContainer;
export default TagContainer;
