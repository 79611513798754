var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components/native';
import { View } from 'react-native';
import Text from '../../elements/Text';
import theme from '../../theme';
const StyleActionText = styled((_a) => {
    var { theme } = _a, rest = __rest(_a, ["theme"]);
    return <Text {...rest}/>;
}) `
  font-size: ${({ theme }) => theme.subTextFontSize};
  color: ${({ theme }) => theme.BrighteBlue};
`;
StyleActionText.defaultProps = {
    theme: {
        subTextFontSize: theme.subTextFontSize,
        BrighteBlue: theme.BrighteBlue,
    },
};
const StyledWrapper = styled(View) `
  ${({ rightAligned }) => (rightAligned ? 'position: absolute; right: 0; margin-left: auto;' : '')}
  padding-top: ${({ theme }) => theme.sixPT};
  ${props => props.customStyle}
  ${({ isManualEnter }) => (isManualEnter ? 'display: flex' : 'display: none')};
  ${props => props.customStyle}
`;
StyledWrapper.defaultProps = {
    theme: {
        thirtyPT: theme.thirtyPT,
    },
};
const StyledContextWrapper = ({ iconName, onPress, isManualEnter, displayText, rightAligned }) => (<StyledWrapper isManualEnter={isManualEnter} rightAligned={rightAligned}>
    <StyleActionText name={iconName} onPress={onPress}>
      {displayText}
    </StyleActionText>
  </StyledWrapper>);
export default StyledContextWrapper;
