/**
 * Navigation component which renders out a RN Button as the default Back button renderer.
 */
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { CoreNavigation } from '@brighte/brighte-one-core';
import Button from '../../components/Button';
const Navigation = (_a) => {
    var { children, label } = _a, restProps = __rest(_a, ["children", "label"]);
    return (<CoreNavigation defaultRenderer={(_a) => {
        var { id, direction, navigate } = _a, rest = __rest(_a, ["id", "direction", "navigate"]);
        return (<Button {...rest} id={id} classIdentifier={direction} onPress={navigate} label={label}/>);
    }} {...restProps}>
      {children}
    </CoreNavigation>);
};
Navigation.defaultProps = {
    direction: 'next',
};
export default Navigation;
