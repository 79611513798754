/* eslint-disable import/prefer-default-export */
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/**
 * Convert a string to a base 10 integer
 * @param str - String to convert to an integer
 * @returns A base 10 integer representation of the string
 */
export var toInt = function (str) { return parseInt(str, 10); };
/**
 * Creates a formatter function to format a numeric currency value.
 *
 * @param formatConfig IntlOptions to format
 * @returns The formatter function
 */
export var createFormatter = function (formatConfig, options) {
    if (options === void 0) { options = {}; }
    return function (value) {
        var allowDecimal = options.allowDecimal, allowZero = options.allowZero;
        var digitsPattern = allowDecimal ? /[0-9\.]+/g : /\d+/g;
        var parseFn = allowDecimal ? parseFloat : toInt;
        var hasDecimal = allowDecimal && value.includes('.');
        var isNegative = value.substring(0, 1) === '-';
        var digitsOnly = value.match(digitsPattern);
        // By default, currency should only allow maximum 2 decimal points unless stated otherwise
        var decimalPlaces = formatConfig.minimumFractionDigits ? formatConfig.minimumFractionDigits : 2;
        var decimalValue = hasDecimal ? value.split('.')[1].substring(0, decimalPlaces) : '';
        var returnValue = '';
        var numberValue = '';
        var formatted = '';
        if (digitsOnly) {
            var digitsStr = digitsOnly.join('');
            numberValue = parseFn(digitsStr) / formatConfig.divisor;
        }
        var stringValue = numberValue.toString();
        if (isNegative && stringValue.length === 0) {
            return value;
        }
        if (stringValue.includes('.') && stringValue.split('.')[1].length > decimalPlaces) {
            numberValue = parseFn(stringValue.split('.')[0] + "." + stringValue.split('.')[1].substring(0, decimalPlaces));
        }
        var shouldFormat = stringValue.length > 0 && (numberValue !== 0 || allowZero);
        if (shouldFormat) {
            var localeConfig = formatConfig.locale, config = __rest(formatConfig, ["locale"]);
            formatted = new Intl.NumberFormat(localeConfig, config).format(Number(isNegative ? -numberValue : numberValue));
            returnValue = hasDecimal ? formatted.split('.')[0] + "." + decimalValue : formatted;
        }
        return "" + returnValue;
    };
};
