import React from 'react';
import styled from 'styled-components';
import ContainerNative from './Container.native';
import theme from '../../theme';
const Div = styled.div `
  [data-brighte-class=${({ dataBrighteClass }) => dataBrighteClass}] {
    background-color: 'transparent';
    transition: all 0.2s ease-out;
  }
  [data-brighte-class=${({ dataBrighteClass }) => dataBrighteClass}]:hover {
    background-color: ${({ bgHoverColor }) => bgHoverColor};
    transition: all 0.2s ease-in;
  }
`;
const Container = props => {
    const { 'data-brighte-class': dataBrighteClass, theme, disabled } = props;
    const bgHoverColor = theme.LightBackground;
    return (<Div bgHoverColor={!disabled && bgHoverColor} dataBrighteClass={dataBrighteClass}>
      <ContainerNative {...props}/>
    </Div>);
};
Container.defaultProps = {
    disabled: false,
    theme: {
        LightBackground: theme.LightBackground,
    },
};
Container.Text = null;
Container.Renderer = null;
Container.GroupTitle = null;
Container.CheckedIcon = null;
export default Container;
