import { View, StyleSheet } from 'react-native';
import styled from 'styled-components/native';
import Ripple from './Ripple';
const TouchableRipple = styled(View) `
  background-color: transparent;
  overflow: hidden;
  border-radius: 0;
  ${() => StyleSheet.absoluteFillObject}
`;
TouchableRipple.Ripple = Ripple;
export default TouchableRipple;
