var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components/native';
import Button from '../Button';
import theme from '../../theme';
const AgreeButton = styled((_a) => {
    var { theme, label } = _a, rest = __rest(_a, ["theme", "label"]);
    return (<Button {...rest}>
    <Button.Text>{label}</Button.Text>
  </Button>);
}) `
  height: ${({ theme }) => theme.sixtyPT};
  width: auto;
  padding-right: ${({ theme }) => theme.fortyPT};
  padding-left: ${({ theme }) => theme.fortyPT};
  margin-left: ${({ theme }) => theme.thirtyPT};
  ${props => props.customStyle}
`;
AgreeButton.defaultProps = {
    theme: {
        sixtyPT: theme.sixtyPT,
        fortyPT: theme.fortyPT,
        thirtyPT: theme.thirtyPT,
    },
};
export default AgreeButton;
