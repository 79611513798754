import { applyResponsiveness } from '@brighte/brighte-one-core';
const inputGroupRadius = ({ inputGroupLength, theme }) => {
    // method for input group
    if (!inputGroupLength) {
        return null;
    }
    return `
      border-top-left-radius: ${inputGroupLength && inputGroupLength && inputGroupLength.index === 0 ? theme.borderRadius : 0};
      border-bottom-left-radius: ${inputGroupLength && inputGroupLength.index === 0 ? theme.borderRadius : 0};
      border-top-right-radius: ${inputGroupLength && inputGroupLength.index + 1 === inputGroupLength.length ? theme.borderRadius : 0};
      border-bottom-right-radius: ${inputGroupLength && inputGroupLength.index + 1 === inputGroupLength.length ? theme.borderRadius : 0};
    `;
};
const inputGroupBorderWidth = ({ inputGroupLength, responsiveClass, responsiveBreakPoint, theme, inputGroupState }) => {
    // method for input group
    if (!inputGroupLength) {
        return null;
    }
    const brakeField = applyResponsiveness(responsiveClass, responsiveBreakPoint);
    // This fixes border when responsive brakes
    if (brakeField) {
        return `
      border-top-left-radius:  ${theme.borderRadius};
      border-top-right-radius:  ${theme.borderRadius};
      border-bottom-left-radius:  ${theme.borderRadius};
      border-bottom-right-radius:  ${theme.borderRadius};
      `;
    }
    const activeField = Object.values(inputGroupState).find((item) => item && item.active === true);
    const errorField = Object.values(inputGroupState).map((item) => item.isError === true && item.position);
    // style border width for last field
    const inputBorderWidthStyles = inputGroupLength.index + 1 === inputGroupLength.length
        ? `border-right-width : ${theme.borderWidth};`
        : 'border-right-width: 0;';
    // style border color
    const inputBorderColorStyles = activeField && activeField.position + 1 === inputGroupLength.index + 1
        ? `border-left-color: ${activeField.isError && activeField.showRealtimeError ? theme.AlertRed : theme.colorInputActive}`
        : errorField.includes(inputGroupLength.index) && `border-left-color: ${theme.AlertRed}`;
    return inputBorderWidthStyles + inputBorderColorStyles;
};
const inputGroupResponseMarginContainer = ({ inputGroupLength, responsiveClass, responsiveBreakPoint, theme }) => {
    // method for input group
    if (!inputGroupLength) {
        return null;
    }
    const brakeField = applyResponsiveness(responsiveClass, responsiveBreakPoint);
    // This fixes border when responsive brakes
    if (brakeField) {
        return `
      margin-bottom: ${inputGroupLength.index + 1 !== inputGroupLength.length ? theme.fieldBottomGap : 0}px;
      `;
    }
    return null;
};
const inputGroupResponseLabelContainer = ({ inputGroupLength, responsiveClass, responsiveBreakPoint, theme }) => {
    // method for input group
    if (!inputGroupLength) {
        return null;
    }
    const brakeField = applyResponsiveness(responsiveClass, responsiveBreakPoint);
    // This fixes border when responsive brakes
    if (!brakeField) {
        return `
      margin-left: ${inputGroupLength.index !== 0 ? theme.fivePT : 0};
    `;
    }
    return null;
};
export { inputGroupRadius, inputGroupBorderWidth, inputGroupResponseMarginContainer, inputGroupResponseLabelContainer };
