import React from 'react';
import { View as RNView, Animated } from 'react-native';
import withVisibility from '../core/hoc/withVisibility';
import Loading from '../components/Loading';
import StyledLoading from '../styles/blocks/Loading';
import useAnimation from '../core/hooks/useAnimation';
const View = RNView;
const LoadingContainer = props => {
    const { children, color, colorOverlay, customLoader, size, loadingTheme, customStyle: { customStyleContainer, customStyleLoading } = {}, zIndex, visible, id, } = props;
    const [animationValue] = useAnimation(visible ? 1 : 0, [visible]);
    return (<View dataSet={{ 'brighte-class': 'brighte-loading-container' }} style={customStyleContainer} {...(id ? { nativeID: `brighte-loading-container-${id}` } : {})}>
      {children}
      {visible &&
        (customLoader ? (React.Children.only(customLoader)) : (<StyledLoading as={Animated.View} style={{ opacity: animationValue }} colorOverlay={colorOverlay} zIndex={zIndex}>
            <Loading color={color} size={size} loadingTheme={loadingTheme} customStyle={customStyleLoading}/>
          </StyledLoading>))}
    </View>);
};
LoadingContainer.defaultProps = {
    children: undefined,
    color: '',
    colorOverlay: '',
    customLoader: undefined,
    customStyle: {},
    loadingTheme: 'light',
    visible: true,
    zIndex: 2,
};
export default withVisibility({
    flagOnly: true,
})(LoadingContainer);
