var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import get from 'lodash/get';
import noop from 'lodash/noop';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import { connect } from 'react-redux';
import { setValue, setInitFieldValue, setFieldAttributes } from '../actions';
import { FormContext } from '../root/FormContainer';
import checkValidation from '../utilities/validation';
import customValidators, { addValidator, ValidatorType, getValidator } from '../utilities/customValidators';
var withForm = function (WrappedComponent, defaultProps) {
    if (defaultProps === void 0) { defaultProps = {}; }
    var WithForm = /** @class */ (function (_super) {
        __extends(WithForm, _super);
        function WithForm(props, _a) {
            var formName = _a.formName;
            var _this = _super.call(this, props) || this;
            _this.onChangeValue = function (inputValue, manualValidation) {
                if (manualValidation === void 0) { manualValidation = false; }
                var _a = _this.props, fieldName = _a.fieldName, required = _a.required, inputType = _a.inputType, setFieldData = _a.setFieldData, validationMessage = _a.validationMessage, validation = _a.validation, formData = _a.formData;
                var formName = _this.context.formName;
                var validationResult;
                if (manualValidation === false) {
                    var customValidator = customValidators.get(fieldName);
                    var validatorFn = customValidator && customValidator.type === ValidatorType.Override
                        ? getValidator(fieldName)
                        : checkValidation;
                    validationResult = validatorFn(inputValue, {
                        required: required,
                        inputType: inputType,
                        validationMessage: validationMessage,
                        validation: validation,
                    }, fieldName, {}, formData[formName]);
                }
                else if (isFunction(manualValidation)) {
                    validationResult = manualValidation({ inputValue: inputValue });
                }
                var fieldData = {
                    value: inputValue,
                    touched: true,
                    valid: validationResult ? validationResult.isValid : true,
                    errorMessage: validationResult ? validationResult.error : null,
                    visible: true,
                };
                if (get(formData, "[" + formName + "].fields[" + fieldName + "]")) {
                    setFieldData(fieldData, fieldName, formName);
                }
                else {
                    _this.setState(fieldData);
                }
            };
            _this.setFieldAttributesFunction = function (attributes) {
                var _a = _this.props, fieldName = _a.fieldName, setFieldAttributesDispatch = _a.setFieldAttributesDispatch;
                var formName = _this.context.formName;
                setFieldAttributesDispatch(fieldName, formName, attributes);
            };
            var _b = _this.props, fieldName = _b.fieldName, formData = _b.formData, setInitConfig = _b.setInitConfig, required = _b.required, inputType = _b.inputType, initValue = _b.initValue, validationMessage = _b.validationMessage, validation = _b.validation, validatorOverride = _b.validatorOverride;
            _this.state = {
                value: initValue || props.value || '',
                touched: false,
                valid: false,
                errorMessage: '',
                visible: true,
            };
            var belongsToPage = get(formData, "[" + formName + "].navigation.currentPage", '');
            var _c = get(formData, "[" + formName + "].fields[" + fieldName + "]", _this.state), value = _c.value, valid = _c.valid, errorMessage = _c.errorMessage, rest = __rest(_c, ["value", "valid", "errorMessage"]);
            if (fieldName && formName) {
                if (isFunction(validatorOverride)) {
                    addValidator(fieldName, validatorOverride, ValidatorType.Override);
                }
                else if (isFunction(validation) || validation instanceof RegExp) {
                    addValidator(fieldName, validation, ValidatorType.Extend);
                }
                setInitConfig(fieldName, __assign({ belongsToPage: belongsToPage,
                    required: required,
                    value: value, valid: checkValidation(value, {
                        required: required,
                        inputType: inputType,
                        validationMessage: validationMessage,
                    }, fieldName, { valid: valid, errorMessage: errorMessage }).isValid, touched: !!value, validationConfig: {
                        required: required,
                        inputType: inputType,
                        validationMessage: validationMessage,
                    }, errorMessage: errorMessage || null }, rest), formName);
            }
            return _this;
        }
        WithForm.prototype.shouldComponentUpdate = function (nextProps, nextState) {
            var _this = this;
            var value = this.state.value;
            var _a = this.props, fieldName = _a.fieldName, formData = _a.formData, _b = _a.watch, watch = _b === void 0 ? [] : _b;
            var formName = this.context.formName;
            var fieldValue = get(formData, "[" + formName + "].fields[" + fieldName + "]");
            var nextFieldValue = get(nextProps.formData, "[" + formName + "].fields[" + fieldName + "]");
            if (fieldValue && nextFieldValue && fieldValue !== nextFieldValue) {
                return true;
            }
            if (value !== nextState.value) {
                return true;
            }
            // if any props are being 'watched', check to see if they have changed
            var watchHaveChanges = watch.some(function (propName) {
                // @ts-ignore: Dynamic prop name
                var _a = _this.props, _b = propName, currentValue = _a[_b];
                return !isEqual(currentValue, nextProps[propName]);
            });
            if (watchHaveChanges) {
                return true;
            }
            return false;
        };
        WithForm.prototype.render = function () {
            var _a;
            var _b = this.props, fieldName = _b.fieldName, setFieldData = _b.setFieldData, setInitConfig = _b.setInitConfig, formData = _b.formData, validatorOverride = _b.validatorOverride, validationMessage = _b.validationMessage, rest = __rest(_b, ["fieldName", "setFieldData", "setInitConfig", "formData", "validatorOverride", "validationMessage"]);
            var formName = this.context.formName;
            var field = get(formData, "[" + formName + "].fields[" + fieldName + "]", this.state);
            return (<WrappedComponent onChangeValue={this.onChangeValue} value={(_a = field.value) !== null && _a !== void 0 ? _a : ''} errorMessage={field.errorMessage} fieldName={fieldName} touched={field.touched} setFieldAttributes={this.setFieldAttributesFunction} fieldAttributes={field} {...rest}/>);
        };
        // eslint-disable-next-line
        WithForm.defaultProps = __assign({ required: true, setFieldData: noop, inputType: 'text', watch: [] }, defaultProps);
        return WithForm;
    }(React.Component));
    WithForm.contextType = FormContext;
    return connect(function (_a) {
        var formData = _a.formData;
        return ({ formData: formData });
    }, function (dispatch) { return ({
        setFieldData: function (formData, fieldName, formName) { return dispatch(setValue(formData, fieldName, formName)); },
        setFieldAttributesDispatch: function (fieldName, formName, attributes) {
            return dispatch(setFieldAttributes(fieldName, formName, attributes));
        },
        setInitConfig: function (fieldName, initData, formName) {
            return dispatch(setInitFieldValue(fieldName, initData, formName));
        },
    }); })(WithForm);
};
export default withForm;
