var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components/native';
import { TouchableOpacity, View } from 'react-native';
import theme from '../../theme';
const StyledIconWrapper = styled((_a) => {
    var { theme, customStyle, touchable } = _a, rest = __rest(_a, ["theme", "customStyle", "touchable"]);
    return touchable ? <TouchableOpacity {...rest}/> : <View {...rest}/>;
}) `
  height: ${({ theme }) => theme.thirtyFourPT};
  padding-right: 0;
  background-color: ${({ hovered, theme }) => (hovered ? theme.FormElements : 'transparent')};
  justify-content: center;
  align-items: center;
  ${props => props.customStyle}
`;
StyledIconWrapper.defaultProps = {
    hovered: false,
    theme: {
        FormElements: theme.FormElements,
        fifteenPT: theme.fifteenPT,
        thirtyPT: theme.thirtyPT,
        thirtyFourPT: theme.thirtyFourPT,
        thirtyEightPT: theme.thirtyEightPT,
    },
};
export default StyledIconWrapper;
