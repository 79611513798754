import { useEffect, useRef, useContext, useState } from 'react';
import get from 'lodash/get';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import config from '../utilities';
import { setCurrentPage, validatePageFields, validateFields, validatePage } from '../actions';
import { FormContext } from '../root/FormContainer';
import pageMap from '../root/PageMap';
export var Direction;
(function (Direction) {
    Direction["NEXT"] = "next";
    Direction["BACK"] = "back";
})(Direction || (Direction = {}));
var useNavigation = function () {
    var formName = useContext(FormContext).formName;
    var mounted = useRef(false);
    var dispatch = useDispatch();
    var _a = useState(false), navigate = _a[0], setNavigate = _a[1];
    var _b = useSelector(function (state) { return get(state, "formData[" + formName + "].navigation", config.initNavigation); }, shallowEqual), currentPage = _b.currentPage, pages = _b.pages, valid = _b.valid, previousPage = _b.previousPage, currentPageIndex = _b.currentPageIndex, previousPageIndex = _b.previousPageIndex;
    useEffect(function () {
        if (!mounted.current) {
            mounted.current = true;
            return;
        }
        if (mounted.current && navigate && valid) {
            var current_1 = pageMap.get(currentPage);
            var _a = Array.from(pageMap).find(function (_a) {
                var index = _a[1].index;
                return index === current_1.index + 1;
            }) || [], nextPage = _a[0], pageDetails = _a[1];
            dispatch(setCurrentPage({ formName: formName, pageName: nextPage || '', data: pageDetails === null || pageDetails === void 0 ? void 0 : pageDetails.data }));
            setNavigate(false);
        }
    }, [valid, navigate]);
    var validateAndNavigate = function (direction) {
        if (direction === Direction.NEXT) {
            dispatch(validatePageFields(currentPage, formName));
            dispatch(validatePage(currentPage, formName));
            setNavigate(true);
        }
        if (direction === Direction.BACK) {
            var current_2 = pageMap.get(currentPage);
            if (current_2 && current_2.index > 0) {
                var _a = Array.from(pageMap).find(function (_a) {
                    var index = _a[1].index;
                    return index === current_2.index - 1;
                }) || [], prevPage = _a[0], pageDetails = _a[1];
                dispatch(setCurrentPage({ formName: formName, pageName: prevPage || '', data: pageDetails === null || pageDetails === void 0 ? void 0 : pageDetails.data }));
            }
        }
    };
    var validateCustomField = function (fieldsToValidate) {
        dispatch(validateFields(fieldsToValidate, formName));
    };
    var navigateTo = function (page) {
        if (page === void 0) { page = null; }
        if (typeof page === 'number') {
            dispatch(setCurrentPage({ formName: formName, pageName: Array.from(pageMap)[page][0], data: Array.from(pageMap)[page][1].data }));
        }
        else if (typeof page === 'string') {
            var index = Array.from(pageMap).findIndex(function (_a) {
                var pageName = _a[0];
                return pageName === page;
            });
            dispatch(setCurrentPage({ formName: formName, pageName: Array.from(pageMap)[index][0], data: Array.from(pageMap)[index][1].data }));
        }
        else {
            dispatch(setCurrentPage({
                formName: formName,
                pageName: Array.from(pageMap)[currentPage][0],
                data: Array.from(pageMap)[currentPage][1].data,
            }));
        }
    };
    return {
        validateAndNavigate: validateAndNavigate,
        currentPage: currentPage,
        pages: pages,
        valid: valid,
        navigateTo: navigateTo,
        validateCustomField: validateCustomField,
        pageMap: pageMap,
        previousPage: previousPage,
        currentPageIndex: currentPageIndex,
        previousPageIndex: previousPageIndex,
    };
};
export default useNavigation;
