export var variables = {};
var Config = {
    initNavigation: {
        currentPage: null,
        previousPage: null,
        pages: 1,
        valid: false,
    },
};
export default Config;
