var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { isEmpty, has } from 'lodash';
import isFunction from 'lodash/isFunction';
import customValidators from './customValidators';
import { InputType } from './types';
import isValidEmail from './validators/email';
/**
 * Perform subfield validation
 * @param options - Validation options
 * @returns The validation object
 */
export var doValidate = function (options) {
    var _a, _b;
    var key = options.key, val = options.val, validation = options.validation, validationMessage = options.validationMessage;
    if (validation instanceof RegExp && !validation.test(val)) {
        return _a = {}, _a[key] = { error: validationMessage || 'This field is invalid.' }, _a;
    }
    if (isFunction(validation) && validation(val) === false) {
        return _b = {}, _b[key] = { error: validationMessage || 'This field is invalid.' }, _b;
    }
    return {};
};
export default (function (value, rules, fieldName, fieldAttributes, state) {
    if (fieldAttributes === void 0) { fieldAttributes = {}; }
    if (state === void 0) { state = {}; }
    var isValid = true;
    var error = null;
    if (!rules) {
        return {
            isValid: isValid,
            error: error,
        };
    }
    var customValidator = customValidators.get(fieldName);
    // when validatorOverride is passed as a prop, the rest of validation is not executed
    if (customValidator && isFunction(customValidator.validator)) {
        var _a = customValidator.validator(value, state, rules), valid = _a.isValid, errorValidation = _a.error;
        isValid = valid;
        error = errorValidation;
        return {
            isValid: isValid,
            error: error,
        };
    }
    if (rules.required) {
        switch (typeof value) {
            case 'undefined':
                isValid = false;
                break;
            case 'number':
                isValid = !!value;
                break;
            case 'string':
                isValid = value.trim() !== '';
                break;
            case 'boolean':
                isValid = typeof value === 'boolean';
                break;
            case 'object':
                if (Array.isArray(value)) {
                    isValid = value.every(function (item) { return item; });
                }
                break;
            default:
                isValid = false;
        }
        if (!isValid) {
            error = rules.validationMessage || 'This field is required';
            isValid = false;
        }
    }
    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid;
        if (!isValid) {
            error = rules.validationMessage || "This field should minimum " + rules.minLength + " characters ";
        }
    }
    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid;
        if (!isValid) {
            error = rules.validationMessage || "This field should maximum " + rules.minLength + " characters";
        }
    }
    if (rules.inputType === InputType.EMAIL) {
        isValid = isValidEmail(value) && isValid;
        if (!isValid) {
            error = rules.validationMessage || 'This email is not valid';
        }
    }
    if (rules.inputType === InputType.SECURE_CODE) {
        isValid = fieldAttributes.valid || false;
        error = fieldAttributes.errorMessage || error;
        if ((Array.isArray(value) && value.some(function (item) { return item.length === 0; })) || typeof value === 'undefined') {
            isValid = false;
            error = rules.validationMessage || "This field should 4 characters characters";
        }
    }
    if (rules.inputType === InputType.NUMERIC) {
        var numericPattern = /^\d+$/;
        isValid = numericPattern.test(value) && isValid;
        if (!isValid) {
            error = rules.validationMessage || "This isn't a number";
        }
    }
    if (rules.inputType === InputType.MOBILE) {
        var mobilePattern = /^(([0]4\d{2})|(4\d{2}))\s(\d{3})\s(\d{3,4})$/;
        isValid = mobilePattern.test(value) && isValid;
        if (!isValid) {
            error = rules.validationMessage || "This isn't a mobile number";
        }
    }
    if (rules.inputType === 'datalookup') {
        error = {};
        if (value && typeof value === 'object') {
            Object.keys(value).forEach(function (key) {
                var _a;
                if (has(value[key], 'value')) {
                    var _b = value[key], val = _b.value, required = _b.required, validation = _b.validation, validationMessage = _b.validationMessage;
                    if (required && isEmpty(val)) {
                        if (typeof error === 'object') {
                            error = __assign(__assign({}, error), (_a = {}, _a[key] = { error: 'This field is required.' }, _a));
                        }
                    }
                    if (typeof error === 'object') {
                        error = __assign(__assign({}, error), doValidate({ key: key, val: val, validation: validation, validationMessage: validationMessage }));
                    }
                }
            });
            if (!isEmpty(error)) {
                error = __assign(__assign({}, error), { searchText: { error: true } });
            }
        }
        else {
            error = { searchText: { error: 'This field is required.' } };
        }
        isValid = isEmpty(error);
    }
    // This custom validation has priority that why its executed last
    if (rules.validation instanceof RegExp) {
        var customPattern = rules.validation;
        isValid = customPattern.test(value) && isValid;
        if (!isValid) {
            error = rules.validationMessage || 'This field is invalid!';
        }
    }
    if (rules.validation && isFunction(rules.validation)) {
        var _b = customValidator.validator(value, state, rules), valid = _b.isValid, errorValidation = _b.error;
        isValid = valid;
        if (!isValid) {
            error = errorValidation || rules.validationMessage || 'This field is invalid!';
        }
    }
    return {
        isValid: isValid,
        error: error,
    };
});
