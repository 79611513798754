import styled from 'styled-components/native';
import { StyleSheet, View } from 'react-native';
import theme from '../../theme';
const Header = styled(View) `
  flex: 1;
  flex-direction: row;
  position: absolute;
  top: 0;
  z-index: 100;
  width: 100%;
  height: auto;
  max-height: ${theme.oneHunredEight};
  min-height: ${theme.thirtyPT};
  align-content: center;
  align-items: center;
  justify-content: center;
  padding-top: ${theme.tenPT};
  padding-bottom: ${theme.eightPT};
  background-color: ${theme.WhiteColor};
  border-bottom-color: ${theme.ModalDividerLine};
  border-bottom-width: ${StyleSheet.hairlineWidth};
  ${props => props.customStyle}
`;
Header.defaultProps = {
    theme: {
        BrighteGreen: theme.BrighteGreen,
        thirtyPT: theme.thirtyPT,
        tenPT: theme.tenPT,
        eightPT: theme.eightPT,
        oneHunredEight: theme.oneHunredEight,
        WhiteColor: theme.WhiteColor,
        ModalDividerLine: theme.ModalDividerLine,
    },
};
export default Header;
