import { View } from 'react-native';
import styled from 'styled-components/native';
import Text from '../../elements/Text';
import theme from '../../theme';
const CountDown = styled(View) `
  background-color: ${({ theme }) => theme.WhiteColor};
  overflow: hidden;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  ${props => props.customStyle};
`;
const ContentContainer = styled(View) `
  justify-content: center;
  align-items: center;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size / 2};
  border-width: ${({ thickness }) => thickness};
  border-color: ${({ unfilledColor }) => unfilledColor};
  position: absolute;
  ${props => props.customStyle};
`;
const Half = styled(View) `
  width: ${({ size }) => size / 2};
  height: ${({ size }) => size};
  overflow: hidden;
  transform: ${({ isFlipped }) => (isFlipped ? `scaleX(-1)` : `scaleX(1)`)};
  ${props => props.customStyle};
`;
const InnerHalf = styled(View) `
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  ${props => props.customStyle};
`;
const InnerHalfWrapper = styled(View) `
  width: ${({ size }) => size / 2};
  height: ${({ size }) => size};
  overflow: hidden;
  ${props => props.customStyle};
`;
const InnerHalfBorder = styled(View) `
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size / 2};
  border-width: ${({ thickness }) => thickness};
  border-color: ${({ color }) => color};
  ${props => props.customStyle};
`;
const StatusWrapper = styled(View) `
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.customStyle};
`;
const Content = styled(Text) `
  display: flex;
  font-size: ${({ theme, status }) => (status ? theme.thirtyPT : theme.eightyTwoPT)};
  line-height: ${({ theme }) => theme.fiftyPT};
  align-items: center;
  justify-content: center;
  ${props => props.customStyle};
`;
const TextCircle = styled(Text) `
  font-size: ${({ status, theme }) => (status ? theme.thirtyPT : theme.textFontSize)};
  color: ${({ status, theme }) => (status ? theme.WhiteColor : theme.DarkText)};
  line-height: ${({ theme }) => theme.twentyPT};
  ${props => props.customStyle};
`;
CountDown.Content = Content;
CountDown.Half = Half;
CountDown.InnerHalf = InnerHalf;
CountDown.InnerHalfWrapper = InnerHalfWrapper;
CountDown.InnerHalfBorder = InnerHalfBorder;
CountDown.StatusWrapper = StatusWrapper;
CountDown.TextCircle = TextCircle;
CountDown.ContentContainer = ContentContainer;
CountDown.defaultProps = {
    radius: 100,
    theme: {
        WhiteColor: theme.WhiteColor,
    },
};
Content.defaultProps = {
    theme: {
        thirtyPT: theme.thirtyPT,
        fiftyPT: theme.fiftyPT,
        eightyTwoPT: theme.eightyTwoPT,
    },
};
TextCircle.defaultProps = {
    theme: {
        textFontSize: theme.textFontSize,
        WhiteColor: theme.WhiteColor,
        DarkText: theme.DarkText,
        twentyPT: theme.twentyPT,
    },
};
export default CountDown;
