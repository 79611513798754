import { View } from 'react-native';
import styled from 'styled-components/native';
import { applyResponsiveness } from '@brighte/brighte-one-core';
const ButtonListContainer = styled(View) `
  ${({ responsiveClass, responsiveBreakPoint }) => applyResponsiveness(responsiveClass, responsiveBreakPoint) ? 'flex-direction: column' : 'flex-direction: row'};
  display: flex;
  flex-wrap: wrap;
  ${props => props.customStyle}
`;
export default ButtonListContainer;
