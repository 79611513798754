/* eslint-disable import/prefer-default-export */
export var ResponseSize;
(function (ResponseSize) {
    ResponseSize["XS"] = "XS";
    ResponseSize["SM"] = "SM";
    ResponseSize["MD"] = "MD";
    ResponseSize["LG"] = "LG";
    ResponseSize["XL"] = "XL";
})(ResponseSize || (ResponseSize = {}));
export var InputType;
(function (InputType) {
    InputType["EMAIL"] = "email";
    InputType["NUMERIC"] = "numeric";
    InputType["PASSWORD"] = "password";
    InputType["DATE"] = "date";
    InputType["MOBILE"] = "mobile";
    InputType["SECURE_CODE"] = "secureCode";
    InputType["TEXT"] = "text";
})(InputType || (InputType = {}));
