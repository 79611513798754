import React from 'react';
import styled from 'styled-components';
import CheckBox, { backgroundColor, borderColor } from './CheckBox.native';
import { colorTransform } from '../mixin/animationHelper';
import theme from '../theme';
const Div = styled.div `
  [data-brighte-class=${({ dataBrighteClass }) => dataBrighteClass}] {
    animation: ${(props) => colorTransform(props.bgColor, props.bgHoverColor, props.bdColor, props.bdHoverColor)}
      0.2s ease-in-out;
  }
  &:hover [data-brighte-class=${({ dataBrighteClass }) => dataBrighteClass}] {
    background-color: ${({ bgColor }) => bgColor};
    border-color: ${({ bdColor }) => bdColor};
    animation: ${(props) => colorTransform(props.bgHoverColor, props.bgColor, props.bdHoverColor, props.bdColor)}
      0.2s ease-in-out;
  }
`;
const CheckBoxElement = props => {
    const { checked, DefaultBorderColor, DefaultBackgroundColor, dataSet: { 'brighte-class': dataBrighteClass }, } = props;
    const bgColor = checked ? DefaultBorderColor : DefaultBackgroundColor;
    const bgHoverColor = backgroundColor({ theme, checked });
    const bdHoverColor = borderColor({ theme, checked });
    return (<Div bgColor={bgColor} bdColor={DefaultBorderColor} bgHoverColor={bgHoverColor} bdHoverColor={bdHoverColor} dataBrighteClass={dataBrighteClass}>
      <CheckBox {...props}/>
    </Div>);
};
CheckBoxElement.defaultProps = {
    DefaultBorderColor: theme.LightText,
    DefaultBackgroundColor: theme.Background,
};
export default CheckBoxElement;
