var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components/native';
import Text from '../../elements/Text';
import theme from '../../theme';
const StyledGroupTitle = styled((_a) => {
    var { theme } = _a, rest = __rest(_a, ["theme"]);
    return <Text {...rest}/>;
}) `
  font-size: ${({ theme }) => theme.subTextFontSize};
  padding-top: ${({ theme }) => theme.tenPT};
  padding-bottom: ${({ theme }) => theme.sixPT};
  padding-left: ${({ theme }) => theme.fifteenPT};
  padding-right: ${({ theme }) => theme.thirtyPT};
  color: ${({ theme }) => theme.BrighteGreen};
  line-height: ${({ theme }) => theme.labelFontSize};
  ${props => props.customStyle}
`;
StyledGroupTitle.defaultProps = {
    theme: {
        sixPT: theme.sixPT,
        tenPT: theme.tenPT,
        fifteenPT: theme.fifteenPT,
        subTextFontSize: theme.subTextFontSize,
        BrighteGreen: theme.BrighteGreen,
        labelFontSize: theme.labelFontSize,
    },
};
export default StyledGroupTitle;
