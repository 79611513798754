import React from 'react';
import { Animated } from 'react-native';
import { isString, isEmpty } from 'lodash';
import { withForm } from '@brighte/brighte-one-core';
import withResponsive from '../core/hoc/withResponsive';
import CheckBox from '../components/CheckBox';
import StyledCheckBoxContainer from '../styles/blocks/CheckBox';
import withVisibility from '../core/hoc/withVisibility';
import useAnimation from '../core/hooks/useAnimation';
const CheckBoxContainer = props => {
    const { id, label, errorMessage, customStyle: { containerStyle, checkboxStyle, labelStyle, errorMessageStyle }, disabled, fieldName, onChangeValue, value, onPress, } = props;
    const isError = !isEmpty(errorMessage);
    const [animationValue] = useAnimation(isError ? 1 : 0, [isError]);
    const [successAnimation] = useAnimation(value ? 1 : 0, [value], 500);
    const click = () => {
        if (!disabled) {
            onChangeValue(!value);
        }
        if (!disabled && onPress) {
            onPress();
        }
    };
    return (<StyledCheckBoxContainer.Wrapper>
      <StyledCheckBoxContainer.Container customStyle={containerStyle} dataSet={{ 'brighte-class': 'brighte-checkbox-container' }} nativeID={id ? `brighte-checkbox-container-${id}` : `brighte-checkbox-container-${fieldName}`}>
        <CheckBox onPress={click} checked={value} customStyle={checkboxStyle} isError={isError} as={Animated.View} tickContainerStyle={{
        opacity: successAnimation,
        transform: [
            {
                rotateY: successAnimation.interpolate({
                    inputRange: [0, 1],
                    outputRange: ['0deg', '360deg'],
                }),
            },
        ],
    }}/>
        {isString(label) ? (<StyledCheckBoxContainer.Label dataSet={{ 'brighte-class': 'brighte-checkbox-label' }} customStyle={labelStyle} onPress={click}>
            {label}
          </StyledCheckBoxContainer.Label>) : (<StyledCheckBoxContainer.View dataSet={{ 'brighte-class': 'brighte-checkbox-label-view' }} customStyle={labelStyle}>
            {label}
          </StyledCheckBoxContainer.View>)}
      </StyledCheckBoxContainer.Container>
      {isError && (<StyledCheckBoxContainer.ErrorMessage as={Animated.Text} style={{ opacity: animationValue, transform: [{ scaleY: animationValue }] }} customStyle={errorMessageStyle}>
          {isError && errorMessage}
        </StyledCheckBoxContainer.ErrorMessage>)}
    </StyledCheckBoxContainer.Wrapper>);
};
CheckBoxContainer.defaultProps = {
    customStyle: {},
    label: '',
    checked: false,
    disabled: false,
};
export default withVisibility({ level: 'field' })(withForm(withResponsive(CheckBoxContainer)));
