var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useRef, useState, useContext, useEffect, } from 'react';
import { Animated, Platform, } from 'react-native';
import { isEmpty, noop } from 'lodash';
import { formatMasked, handleChangeMasked, InputType, withForm } from '@brighte/brighte-one-core';
import StyledTextInputContainer from '../styles/blocks/TextInputContainer';
import ValidationIndicator from '../components/ValidationIndicator';
import withVisibility from '../core/hoc/withVisibility';
import withResponsive from '../core/hoc/withResponsive';
import theme from '../styles/theme';
import useAnimation from '../core/hooks/useAnimation';
import { InputGroupContext } from '../components/Grid/Col';
import StyledPopover from '../styles/blocks/Popover';
import { PopoverResponsive } from '../components/Popover';
const defaultProps = {
    autoComplete: 'off',
    fieldAttributes: { displayErrorMessage: true },
    editable: true,
    formatter: false,
    inputType: 'text',
    label: '',
    prefix: '',
    customStyle: {},
    showRealtimeError: false,
    value: '',
    suffix: '',
    optionalText: '(optional)',
    validationIndicator: true,
};
const TextInputContainer = (props) => {
    const { autoComplete = defaultProps.autoComplete, customStyle: { customStyleContainer, customStyleInput, customStyleLabel, errorMessageStyle } = {}, editable = defaultProps.editable, errorMessage, fieldName, formatter = defaultProps.formatter, fieldAttributes: { displayErrorMessage = true } = {}, id, inputType = defaultProps.inputType, inputGroup, inputGroupLength, label = defaultProps.label, helpText, onFocus, onBlur, onChangeValue, onChangeText, onKeyPress, optionalText = defaultProps.optionalText, prefix = defaultProps.prefix, maxLength, placeholder, popoverContent, suffix = defaultProps.suffix, showRealtimeError = defaultProps.showRealtimeError, value = defaultProps.value, mask, maskPrefix, useMaskedValue, lazyMaskSeperator, reverseMask, underlineColorAndroid, touched, required, valuePreFormatter, validationIndicator } = props, rest = __rest(props, ["autoComplete", "customStyle", "editable", "errorMessage", "fieldName", "formatter", "fieldAttributes", "id", "inputType", "inputGroup", "inputGroupLength", "label", "helpText", "onFocus", "onBlur", "onChangeValue", "onChangeText", "onKeyPress", "optionalText", "prefix", "maxLength", "placeholder", "popoverContent", "suffix", "showRealtimeError", "value", "mask", "maskPrefix", "useMaskedValue", "lazyMaskSeperator", "reverseMask", "underlineColorAndroid", "touched", "required", "valuePreFormatter", "validationIndicator"]);
    const inputKey = useRef('');
    const inputEl = useRef(null);
    const cursorRef = useRef(null);
    const formatCharsCountRef = useRef(0);
    const [active, setActive] = useState(false);
    const isError = !isEmpty(errorMessage);
    const isShowError = showRealtimeError ? isError : isError && !active;
    const [animationValue] = useAnimation(isShowError ? 1 : 0, [isShowError]);
    const [inputGroupState, setState] = useContext(InputGroupContext);
    const isWeb = Platform.OS === 'web';
    // set formatting characters matcher based on input type / mask
    let formatCharsMatcher = null;
    if (inputType === InputType.NUMERIC && formatter) {
        formatCharsMatcher = /[^0-9.]/g;
    }
    else if (inputType === InputType.MOBILE) {
        formatCharsMatcher = /[^0-9]/g;
    }
    else if (mask) {
        formatCharsMatcher = /\s/g;
    }
    useEffect(() => {
        if (inputGroup) {
            // this is to track state of other fields in input group
            if (inputGroupLength) {
                setState(state => (Object.assign(Object.assign({}, state), { [inputGroupLength.index]: { position: inputGroupLength.index + 1, active, isError, showRealtimeError } })));
            }
        }
    }, [isError, active]);
    useEffect(() => {
        try {
            if (active && isWeb && formatCharsMatcher && (inputEl === null || inputEl === void 0 ? void 0 : inputEl.current) && (cursorRef === null || cursorRef === void 0 ? void 0 : cursorRef.current) != null) {
                const newValue = inputEl.current.value;
                const formattingCharsUptoCursor = (newValue.slice(0, cursorRef.current).match(formatCharsMatcher) || []).length;
                const cursorOffset = formattingCharsUptoCursor - formatCharsCountRef.current;
                inputEl.current.selectionStart = cursorRef.current + cursorOffset;
                inputEl.current.selectionEnd = cursorRef.current + cursorOffset;
            }
        }
        catch (err) {
            // swallow the error so user can continue even though cursor can't be repositioned
            noop();
        }
    }); // no deps as even no change in value could potentially affect cursor position
    const handleKeypressEvent = event => {
        const { nativeEvent: { key }, } = event;
        if (onKeyPress) {
            onKeyPress(event);
        }
        inputKey.current = key;
    };
    const onFocusInput = e => {
        if (onFocus) {
            onFocus(e);
        }
        setActive(true);
    };
    const onBlurInput = e => {
        if (onBlur) {
            onBlur(e);
        }
        setActive(false);
    };
    const handleChangeText = text => {
        if (isWeb) {
            const target = inputEl.current;
            if (formatCharsMatcher && target) {
                // capture the cursor position if the cursor is in the middle
                cursorRef.current = (text === null || text === void 0 ? void 0 : text.length) === target.selectionStart ? null : target.selectionStart;
                formatCharsCountRef.current = ((text === null || text === void 0 ? void 0 : text.slice(0, target.selectionStart).match(formatCharsMatcher)) || []).length;
            }
        }
        let valueText = mask ? handleChangeMasked(text, mask, inputType, useMaskedValue, maskPrefix) : text;
        if (valuePreFormatter) {
            valueText = valuePreFormatter(valueText);
        }
        if (onChangeText)
            onChangeText(valueText);
        if (onChangeValue)
            onChangeValue(valueText);
    };
    const formatValue = (text) => formatter || mask || inputType === InputType.MOBILE
        ? formatMasked({
            value: text,
            mask: mask || '',
            inputType,
            lazyMaskSeperator,
            onDelete: inputKey.current === 'Backspace',
            maskPrefix,
            reverseMask,
            formatter,
        })
        : text;
    return (<StyledTextInputContainer customStyle={customStyleContainer} nativeID={id ? `brighte-text-input-container-${id}` : `brighte-text-input-container-${fieldName}`} dataSet={{ 'brighte-class': 'brighte-text-input-container' }} inputGroupLength={inputGroupLength} {...rest}>
      {(label || popoverContent) && (<StyledTextInputContainer.LabelContainer dataSet={{ 'brighte-class': 'brighte-textinput-label-container' }} inputGroupLength={inputGroupLength}>
          <StyledTextInputContainer.Label customStyle={customStyleLabel} dataSet={{ 'brighte-class': 'brighte-textinput-label' }} text={label}/>
          {popoverContent && (<PopoverResponsive content={popoverContent}>
              <StyledPopover.Icon type="info" name="information" size={20} dataSet={{ 'brighte-class': 'brighte-popover-icon' }}/>
            </PopoverResponsive>)}
          {!required && (<StyledTextInputContainer.Optional dataSet={{ 'brighte-class': 'brighte-textinput-optional' }} text={optionalText}/>)}
        </StyledTextInputContainer.LabelContainer>)}
      <StyledTextInputContainer.TextInputWrapper as={Animated.View} style={{
        backgroundColor: animationValue.interpolate({
            inputRange: [0, 1],
            outputRange: [theme.WhiteColor, theme.LightRed],
        }),
    }}>
        <StyledTextInputContainer.TextInput active={active} dataSet={{ 'brighte-class': 'brighte-textinput', error: isShowError }} autoComplete={autoComplete || 'off'} customStyle={customStyleInput} editable={editable} hasError={isShowError} inputType={inputType} id={id || fieldName} inputGroupLength={inputGroupLength} maxLength={maxLength} onKeyPress={handleKeypressEvent} onChangeText={handleChangeText} onFocus={onFocusInput} onBlur={onBlurInput} prefix={prefix} placeholder={placeholder} value={formatValue(value !== null && value !== void 0 ? value : '')} underlineColorAndroid={underlineColorAndroid} inputGroupState={inputGroup && inputGroupState} ref={inputEl} {...rest}/>
        {!isEmpty(prefix) && (<StyledTextInputContainer.Prefix dataSet={{ 'brighte-class': 'brighte-textinput-prefix' }}>
            {prefix}
          </StyledTextInputContainer.Prefix>)}
        {!isEmpty(suffix) && (<StyledTextInputContainer.Suffix dataSet={{ 'brighte-class': 'brighte-textinput-suffix' }}>
            {suffix}
          </StyledTextInputContainer.Suffix>)}
        {validationIndicator && ((touched && !active) || isShowError) && (<StyledTextInputContainer.ValidationIndicatorInput>
            <ValidationIndicator type={isError ? 'cross' : 'check'}/>
          </StyledTextInputContainer.ValidationIndicatorInput>)}
      </StyledTextInputContainer.TextInputWrapper>
      {displayErrorMessage && isShowError ? (<StyledTextInputContainer.ErrorMessage as={Animated.Text} style={{ opacity: animationValue, transform: [{ scaleY: animationValue }] }} customStyle={errorMessageStyle}>
          {errorMessage}
        </StyledTextInputContainer.ErrorMessage>) : (helpText && <StyledTextInputContainer.HelpText>{helpText}</StyledTextInputContainer.HelpText>)}
    </StyledTextInputContainer>);
};
TextInputContainer.defaultProps = defaultProps;
export const TextInputContainerPlain = TextInputContainer;
export default withVisibility({ level: 'field' })(withForm(withResponsive(TextInputContainer)));
