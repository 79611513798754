import React from 'react';
import Text from '../Text';
import { ListItem } from '../../styles/elements/UnorderedList';
const UnorderedListItem = props => {
    const { customStyle, prefix, prefixSize, text } = props;
    return (<ListItem>
      {prefix && <Text customStyle={Object.assign(Object.assign({}, customStyle), { fontSize: prefixSize })}>{prefix}</Text>}
      <Text customStyle={customStyle}>{text}</Text>
    </ListItem>);
};
UnorderedListItem.defaultProps = {
    customStyle: {},
    prefix: '\u2022 ',
    prefixSize: 32,
};
export default UnorderedListItem;
