import { useEffect, useRef } from 'react';
import { Animated, Easing } from 'react-native';
export default (toValue = 1, check = [], duration = 200, initValue = 0.01, startAutomatically = true, useNativeDriver = false, delay = 0, easing = Easing.linear, loop = false) => {
    const value = useRef(new Animated.Value(initValue)).current;
    const animation = () => {
        Animated.timing(value, {
            toValue,
            duration,
            useNativeDriver,
            easing,
            delay,
        }).start(() => {
            if (loop) {
                value.setValue(0);
                animation();
            }
        });
    };
    useEffect(() => {
        if (startAutomatically) {
            animation();
        }
    }, check);
    const startAnimationManually = () => {
        animation();
    };
    return [value, startAnimationManually];
};
