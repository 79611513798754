var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import PopoverAntd from 'antd/lib/popover';
var Popover = styled(function (props) { return React.createElement(PopoverAntd, __assign({}, props)); })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  cursor: pointer;\n"], ["\n  cursor: pointer;\n"])));
var GlobalStyle = createGlobalStyle(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\n    [data-brighte-class=\"brighte-popover-icon\"] {\n        cursor: pointer;\n    }\n\n    .brighte-one-web-popover .ant-popover-inner {\n        background-color: #21262C;\n        border-radius: 6px;\n        max-width: 300px;\n}\n    }\n    .brighte-one-web-popover .ant-popover-arrow {\n        display: none;\n    }\n\n    .ant-popover-inner-content {\n        color: white;\n        font-family: Guillon-Regular, Roboto, Helvetica, Neue, Helvetica, Arial, sans-serif;\n    }\n"], ["\n\n    [data-brighte-class=\"brighte-popover-icon\"] {\n        cursor: pointer;\n    }\n\n    .brighte-one-web-popover .ant-popover-inner {\n        background-color: #21262C;\n        border-radius: 6px;\n        max-width: 300px;\n}\n    }\n    .brighte-one-web-popover .ant-popover-arrow {\n        display: none;\n    }\n\n    .ant-popover-inner-content {\n        color: white;\n        font-family: Guillon-Regular, Roboto, Helvetica, Neue, Helvetica, Arial, sans-serif;\n    }\n"])));
Popover.GlobalStyle = GlobalStyle;
export default Popover;
var templateObject_1, templateObject_2;
