import { View } from 'react-native';
import styled from 'styled-components/native';
import TextInput from '../../elements/TextInput';
import ValidationMessage from './ValidationMessage';
import ErrorMessage from '../../elements/ErrorMessage';
import Label from '../../../components/Label';
import LabelContainer from '../../elements/LabelContainer';
const SecureContainer = styled(View) `
  flex-basis: auto;
  ${props => props.customStyle};
`;
const SecureCodeWrapper = styled(View) `
  max-width: 290px;
  flex-direction: row;
  justify-content: space-between;
  ${props => props.customStyle};
`;
// This is wrapper to animate background color
const TextInputWrapper = styled(View) ``;
const SecureCodeInput = styled(TextInput) `
  padding: 0px;
  text-align: center;
  width: ${({ theme }) => theme.sixtyPT};
`;
const LoadingContainer = styled(View) `
  font-size: ${({ theme }) => theme.errorFontSize};
  font-family: ${({ theme }) => `'${theme.fonts.regular}'`};
  bottom: ${({ theme }) => -(theme.errorFontSize * 1.5)};
  display: flex;
  flex-direction: row;
  margin-left: ${({ theme }) => theme.sixPT};
  color: ${({ theme }) => theme.Yellow};
  position: absolute;
  bottom: ${({ theme }) => -(theme.errorFontSize * 1.5)};
  ${props => props.customStyle};
`;
const LoadingText = styled(ErrorMessage) `
  color: ${({ theme }) => theme.Yellow};
  position: relative;
  top: 0px;
  bottom: 0px;
  padding-top: 0px;
  line-height: ${({ theme }) => theme.errorFontSize};
  align-self: flex-end;
  ${props => props.customStyle};
`;
const LoadingWrapper = styled(View) `
  margin-right: 9px;
  padding-top: 6px;
  justify-content: center;
  ${props => props.customStyle};
`;
SecureContainer.SecureCodeInput = SecureCodeInput;
SecureContainer.SecureCodeWrapper = SecureCodeWrapper;
SecureContainer.SecureCodeInputWrapper = TextInputWrapper;
SecureContainer.Label = Label;
SecureContainer.ValidationMessage = ValidationMessage;
SecureContainer.LoadingContainer = LoadingContainer;
SecureContainer.LoadingText = LoadingText;
SecureContainer.LoadingWrapper = LoadingWrapper;
SecureContainer.LabelContainer = LabelContainer;
export default SecureContainer;
