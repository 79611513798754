export var SET_VALUE = 'SET_VALUE';
export var SET_INITIAL_VALUE = 'SET_INITIAL_VALUE';
export var SET_VISIBILITY = 'SET_VISIBILITY';
export var SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export var SET_PAGE_COUNT = 'SET_PAGE_COUNT';
export var VALIDATE_PAGE = 'VALIDATE_PAGE';
export var VALIDATE_PAGE_FIELDS = 'VALIDATE_PAGE_FIELDS';
export var SET_VALID_PAGE = 'SET_VALID_PAGE';
export var SET_INITIAL_DATA = 'SET_INITIAL_DATA';
export var SET_FIELD_ATTRIBUTES = 'SET_FIELD_ATTRIBUTES';
export var VALIDATE_FIELDS = 'VALIDATE_FIELDS';
export var PREPOPULATE_VALUES = 'PREPOPULATE_VALUES';
export var RESET_FORM = 'RESET_FORM';
export var setInitData = function (initData, formName) { return ({
    type: SET_INITIAL_DATA,
    initData: initData,
    formName: formName,
}); };
export var setInitFieldValue = function (fieldName, initData, formName) { return ({
    type: SET_INITIAL_VALUE,
    fieldName: fieldName,
    initData: initData,
    formName: formName,
}); };
export var setValue = function (fieldData, fieldName, formName) { return ({
    type: SET_VALUE,
    fieldData: fieldData,
    fieldName: fieldName,
    formName: formName,
}); };
export var setVisibility = function (visible, fieldName, formName, clearFieldValues) {
    if (clearFieldValues === void 0) { clearFieldValues = false; }
    return ({
        type: SET_VISIBILITY,
        visible: visible,
        fieldName: fieldName,
        formName: formName,
        clearFieldValues: clearFieldValues,
    });
};
export var validatePageFields = function (page, formName) { return ({
    type: VALIDATE_PAGE_FIELDS,
    page: page,
    formName: formName,
}); };
export var validateFields = function (fields, formName) { return ({
    type: VALIDATE_FIELDS,
    fields: fields,
    formName: formName,
}); };
export var setFieldAttributes = function (fieldName, formName, attributes) { return ({
    type: SET_FIELD_ATTRIBUTES,
    fieldName: fieldName,
    formName: formName,
    attributes: attributes,
}); };
export var setPageCount = function (pages, formName) { return ({
    type: SET_PAGE_COUNT,
    pages: pages,
    formName: formName,
}); };
export var setCurrentPage = function (_a) {
    var currentPage = _a.currentPage, previousPage = _a.previousPage, formName = _a.formName, pageName = _a.pageName, _b = _a.data, data = _b === void 0 ? {} : _b;
    return ({
        type: SET_CURRENT_PAGE,
        currentPage: currentPage,
        previousPage: previousPage,
        formName: formName,
        pageName: pageName,
        data: data,
    });
};
export var setValidatePage = function (_a) {
    var page = _a.page, formName = _a.formName, isValid = _a.isValid;
    return ({
        type: SET_VALID_PAGE,
        page: page,
        formName: formName,
        isValid: isValid,
    });
};
export var validatePage = function (page, formName) { return ({
    type: VALIDATE_PAGE,
    page: page,
    formName: formName,
}); };
export var resetForm = function (_a) {
    var formName = _a.formName, fields = _a.fields, navigation = _a.navigation;
    return ({
        type: RESET_FORM,
        formName: formName,
        fields: fields,
        navigation: navigation,
    });
};
export var actionTypes = {
    SET_VALUE: SET_VALUE,
    SET_INITIAL_VALUE: SET_INITIAL_VALUE,
    SET_VISIBILITY: SET_VISIBILITY,
    SET_CURRENT_PAGE: SET_CURRENT_PAGE,
    SET_PAGE_COUNT: SET_PAGE_COUNT,
    VALIDATE_PAGE: VALIDATE_PAGE,
    VALIDATE_PAGE_FIELDS: VALIDATE_PAGE_FIELDS,
    SET_VALID_PAGE: SET_VALID_PAGE,
    SET_INITIAL_DATA: SET_INITIAL_DATA,
    SET_FIELD_ATTRIBUTES: SET_FIELD_ATTRIBUTES,
    VALIDATE_FIELDS: VALIDATE_FIELDS,
    PREPOPULATE_VALUES: PREPOPULATE_VALUES,
    RESET_FORM: RESET_FORM,
};
export default {
    setInitData: setInitData,
    setInitFieldValue: setInitFieldValue,
    setValue: setValue,
    setVisibility: setVisibility,
    validatePageFields: validatePageFields,
    validateFields: validateFields,
    setFieldAttributes: setFieldAttributes,
    setPageCount: setPageCount,
    setCurrentPage: setCurrentPage,
    setValidatePage: setValidatePage,
    validatePage: validatePage,
    resetForm: resetForm,
};
