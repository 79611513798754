import styled from 'styled-components/native';
import { Check } from '../../../components/Icons';
import theme from '../../theme';
const styledCheckIcon = styled(Check) `
  font-size: ${({ theme }) => theme.tenPT};
  color: ${({ theme }) => theme.MediumText};
`;
styledCheckIcon.defaultProps = {
    theme: {
        tenPT: theme.tenPT,
        MediumText: theme.MediumText,
    },
};
export default styledCheckIcon;
