var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components/native';
import { TouchableOpacity } from 'react-native';
import theme from '../../theme';
const OptionRowContainer = styled((_a) => {
    var { theme } = _a, rest = __rest(_a, ["theme"]);
    return <TouchableOpacity {...rest}/>;
}) `
  padding-left: ${({ theme }) => theme.tenPT};
  padding-right: ${({ theme }) => theme.thirtyPT};
  flex-direction: row;
  align-items: center;
  ${props => props.customStyle}
`;
OptionRowContainer.defaultProps = {
    theme: {
        tenPT: theme.tenPT,
        thirtyPT: theme.thirtyPT,
        LightBackground: theme.LightBackground,
    },
};
export default OptionRowContainer;
