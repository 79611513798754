var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components/native';
import { TouchableOpacity } from 'react-native';
import theme from '../../theme';
const StyledIconWrapper = styled((_a) => {
    var { theme, customStyle, fullRadius } = _a, rest = __rest(_a, ["theme", "customStyle", "fullRadius"]);
    return <TouchableOpacity {...rest}/>;
}) `
  height: 100%;
  padding-left: ${({ theme }) => theme.tenPT};
  padding-right: ${({ theme }) => theme.tenPT};
  width: ${({ theme }) => theme.thirtyPT};
  background-color: ${({ hovered, theme }) => (hovered ? theme.FormElements : 'transparent')};
  justify-content: center;
  ${({ fullRadius, theme }) => fullRadius
    ? `border-radius: ${theme.twoPT};`
    : `border-bottom-right-radius: ${theme.twoPT}; border-top-right-radius: ${theme.twoPT};`}
  ${props => props.customStyle}
`;
StyledIconWrapper.defaultProps = {
    theme: {
        FormElements: theme.FormElements,
        tenPT: theme.tenPT,
        thirtyPT: theme.thirtyPT,
        twoPT: theme.twoPT,
    },
    fullRadius: false,
};
export default StyledIconWrapper;
