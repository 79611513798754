import React from 'react';
import { Animated } from 'react-native';
import Icons from '../Icons';
import StyledValidationIcon from '../../styles/elements/ValidationIcon';
import theme from '../../styles/theme';
import useAnimation from '../../core/hooks/useAnimation';
const ValidationIndicator = ({ customStyle, type }) => {
    const [errorAnimation] = useAnimation(type !== 'check' ? 1 : 0, [type], 200);
    const [successAnimation] = useAnimation(type === 'check' ? 1 : 0, [type], 500);
    return type === 'check' ? (<Animated.View style={{
        opacity: successAnimation,
        transform: [
            {
                rotateY: successAnimation.interpolate({
                    inputRange: [0, 1],
                    outputRange: ['0deg', '360deg'],
                }),
            },
        ],
    }}>
      <StyledValidationIcon customStyle={customStyle} component={<Icons.Check color={theme.BrighteGreen}/>}/>
    </Animated.View>) : (<Animated.View style={{
        transform: [
            {
                translateX: errorAnimation.interpolate({
                    inputRange: [0, 0.5, 1, 1.5, 2, 2.5, 3],
                    outputRange: [-5, 5, -5, 5, -5, 5, -5],
                }),
            },
        ],
    }}>
      <StyledValidationIcon customStyle={customStyle} component={<Icons.Close color={theme.AlertRed}/>}/>
    </Animated.View>);
};
ValidationIndicator.defaultProps = {
    customStyle: {},
    type: undefined,
};
export default ValidationIndicator;
