import React from 'react';
import { Provider } from 'react-redux';
import { isFunction } from 'lodash';
import { createStore, applyMiddleware, compose } from 'redux';
import { ThemeProvider } from 'styled-components/native';
import { loadState, rootReducer } from '@brighte/brighte-one-core';
import { composeWithDevTools } from 'redux-devtools-extension';
import theme from '../styles/theme';
import ResponseProvider from './root/ResponseProvider';
let initilaizedStore;
export const getStore = () => initilaizedStore;
const App = ({ children, customReducers, customMiddleware, customTheme, configureMiddlewares, id, initialState, enhancer, }) => {
    let composeEnhancers = compose;
    const middlewares = [...customMiddleware];
    if (process.env.NODE_ENV === 'development') {
        composeEnhancers = composeWithDevTools({});
    }
    // TO DO: make more sophisticated loadState
    let initStore;
    if (theme.platform === 'web') {
        initStore = loadState(id) ? loadState(id) : initialState;
    }
    const setUpMiddlewares = enhancer
        ? composeEnhancers(applyMiddleware(...middlewares), enhancer)
        : composeEnhancers(applyMiddleware(...middlewares));
    const store = createStore(rootReducer(customReducers), initStore, setUpMiddlewares);
    initilaizedStore = store;
    // To be able to configure middlewares externally
    if (configureMiddlewares && isFunction(configureMiddlewares)) {
        configureMiddlewares(middlewares);
    }
    return (<Provider store={store}>
      <ThemeProvider theme={Object.assign(Object.assign({}, theme), customTheme)}>
        <ResponseProvider>{children}</ResponseProvider>
      </ThemeProvider>
    </Provider>);
};
App.defaultProps = {
    id: 'brighte-one',
    customMiddleware: [],
    initialState: undefined,
    enhancer: null,
};
export default App;
