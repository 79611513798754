import React, { useContext } from 'react';
import { ResponsiveContext } from '../root/ResponseProvider';
const withResponsive = (WrappedComponent) => {
    const Responsive = props => {
        const responsiveClass = useContext(ResponsiveContext);
        return <WrappedComponent responsiveClass={responsiveClass} {...props}/>;
    };
    return Responsive;
};
export default withResponsive;
