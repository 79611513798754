var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import isFunction from 'lodash/isFunction';
import useNavigation, { Direction } from '../hooks/useNavigation';
var Navigation = function (props) {
    var direction = props.direction, onPress = props.onPress, id = props.id, defaultRenderer = props.defaultRenderer, rest = __rest(props, ["direction", "onPress", "id", "defaultRenderer"]);
    var _a = useNavigation(), validateAndNavigate = _a.validateAndNavigate, currentPage = _a.currentPage, pages = _a.pages, valid = _a.valid, navigateTo = _a.navigateTo, validateCustomField = _a.validateCustomField, pageMap = _a.pageMap;
    var onePageForm = currentPage === pages;
    // retrieve current and previous page from page map
    var current = pageMap.get(currentPage);
    var prev = current ? Array.from(pageMap).find(function (_a) {
        var index = _a[1].index;
        return index === current.index - 1;
    }) : null;
    var prevPage = Array.isArray(prev) && prev.length > 0 ? prev[0] : '';
    var navigate = function () {
        if (direction === Direction.NEXT) {
            validateAndNavigate(Direction.NEXT);
            if (onPress) {
                onPress(currentPage, prevPage);
            }
        }
        if (direction === Direction.BACK) {
            if (currentPage !== 1) {
                validateAndNavigate(Direction.BACK);
            }
            if (onPress) {
                onPress(currentPage, prevPage);
            }
        }
    };
    var isFirstPage = current ? current.index === 0 : true;
    var isLastPage = current ? current.index === pages - 1 : false;
    var firstPageRenderBack = isFirstPage && direction === Direction.BACK;
    var lastPageRenderBack = isLastPage && direction === Direction.BACK;
    var firstPageRenderNext = isLastPage && direction === Direction.NEXT;
    var lastPageRenderNext = isLastPage && direction === Direction.NEXT;
    // dont render back button on the first page
    var render = isFunction(props.children)
        ? props.children(navigate, currentPage, pages, valid, direction, navigateTo, validateCustomField, pageMap)
        : defaultRenderer(__assign({ direction: direction, navigate: navigate, navigateTo: navigateTo, id: id }, rest));
    return (((!firstPageRenderBack && !lastPageRenderBack && !firstPageRenderNext && !lastPageRenderNext) || onePageForm) &&
        render);
};
Navigation.defaultProps = {
    defaultRenderer: function () { return null; },
    direction: Direction.NEXT,
};
export default Navigation;
