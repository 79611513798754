var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState, createContext } from 'react';
import StyledCol from '../../styles/blocks/Grid/Col';
import withResponsive from '../../core/hoc/withResponsive';
export const InputGroupContext = createContext([{}, undefined]);
const Col = props => {
    const { id, children, customStyle, inputGroup, responsiveBreakPoint, flexDirection } = props, rest = __rest(props, ["id", "children", "customStyle", "inputGroup", "responsiveBreakPoint", "flexDirection"]);
    // this state tracks the status of input group
    const [state, setState] = useState({});
    return (<StyledCol nativeID={id} dataSet={{ 'brighte-class': inputGroup ? 'brighte-one-col brighte-one-input-group' : 'brighte-one-col' }} customStyle={customStyle} responsiveBreakPoint={responsiveBreakPoint} flexDirection={inputGroup ? 'horizontal' : flexDirection} inputGroup={inputGroup} {...(id ? { nativeID: id } : {})} {...rest}>
      <InputGroupContext.Provider value={[state, setState]}>
        {inputGroup
        ? React.Children.map(children, (groupGroup, index) => React.cloneElement(groupGroup, {
            inputGroup: true,
            inputGroupLength: { index, length: React.Children.count(children) },
            responsiveBreakPoint,
        }))
        : children}
      </InputGroupContext.Provider>
    </StyledCol>);
};
Col.defaultProps = {
    customStyle: {},
    inputGroup: false,
    flex: 1,
    flexDirection: 'vertical',
    halign: null,
    responsiveBreakPoint: 'XS',
    responsiveClass: '',
    valign: null,
};
// This HOC can be splited to the containers later if required
export default withResponsive(Col);
