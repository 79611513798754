import { View } from 'react-native';
import styled from 'styled-components/native';
import OriginalCheckBox from '../../elements/CheckBox';
import CheckBoxIcon from './CheckBoxIcon';
import Container from './CheckBoxContainer';
import CheckBoxLabel from './CheckBoxLabel';
import CheckBoxView from './CheckBoxView';
import ErrorMessage from '../../../components/ErrorMessage';
const CheckBoxWrapper = styled(View) `
  ${props => props.customStyle};
`;
const IconWrapper = styled(View) `
  ${props => props.customStyle};
`;
const CheckBox = OriginalCheckBox;
CheckBox.Icon = CheckBoxIcon;
CheckBox.Label = CheckBoxLabel;
CheckBox.View = CheckBoxView;
CheckBox.ErrorMessage = ErrorMessage;
CheckBox.Container = Container;
CheckBox.Wrapper = CheckBoxWrapper;
CheckBox.IconWrapper = IconWrapper;
export default CheckBox;
