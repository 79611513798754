import React from 'react';
import styled from 'styled-components';
import ButtonNative from './Button.native';
import Theme from '../../theme';
const Div = styled.div `
  [data-brighte-class=${({ dataBrighteClass }) => dataBrighteClass}], 
    [data-brighte-class=brighte-button-next], 
    [data-brighte-class=${({ dataBrighteClass }) => dataBrighteClass}] > * {
    transition: all 0.2s ease-out;
  }
  [data-brighte-class=${({ dataBrighteClass }) => dataBrighteClass}]:hover {
    background-color: ${({ bgHoverColor }) => bgHoverColor};
    transition: all 0.2s ease-in;
    [data-brighte-class='brighte-button-text'] {
      transition: all 0.2s ease-in;
      color: ${({ hoverColorText }) => hoverColorText};
    }
  }
`;
const getBgHoverColor = (btnTheme, theme) => {
    switch (btnTheme) {
        case 'white':
            return theme.Background;
        case 'grey':
            return theme.hoverGreyButtonColor;
        default:
            return theme.hoverGreenButtonColor;
    }
};
const Button = props => {
    const { 'data-brighte-class': dataBrighteClass, btnTheme, theme, disabled } = props;
    const bgHoverColor = getBgHoverColor(btnTheme, theme);
    return (<Div bgHoverColor={!disabled && bgHoverColor} hoverColorText={btnTheme === 'grey' && !disabled && theme.WhiteColor} dataBrighteClass={dataBrighteClass}>
      <ButtonNative {...props}/>
    </Div>);
};
Button.defaultProps = {
    disabled: false,
    theme: {
        WhiteColor: Theme.WhiteColor,
        hoverGreyButtonColor: Theme.hoverGreyButtonColor,
        Background: Theme.Background,
        disabledGreenButtonColor: Theme.disabledGreenButtonColor,
        BrighteGreen: Theme.BrighteGreen,
        LightBackground: Theme.LightBackground,
        blockBorderRadius: Theme.blockBorderRadius,
        FormElements: Theme.FormElements,
        buttonHeight: Theme.buttonHeight,
        GreenShadow: Theme.GreenShadow,
        hoverGreenButtonColor: Theme.hoverGreenButtonColor,
    },
};
export default Button;
