import { View } from 'react-native';
import styled from 'styled-components/native';
export const ListItemContainer = styled(View) `
  flex-shrink: 1;
  ${(props) => props.customStyle};
`;
export const ListItem = styled.View `
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
`;
