var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components/native';
import Text from './Text';
import theme from '../theme';
const ErrorMessage = styled((_a) => {
    var { theme, customStyle, defaultProps, as, status } = _a, rest = __rest(_a, ["theme", "customStyle", "defaultProps", "as", "status"]);
    return <Text {...rest}/>;
}) `
  color: ${({ theme }) => theme.AlertRed};
  padding-top: ${({ theme }) => theme.sixPT};
  padding-right: ${({ theme }) => theme.fivePT};
  font-size: ${({ theme }) => theme.errorFontSize};
  font-family: ${({ theme }) => `'${theme.fonts.regular}'`};
  ${props => props.customStyle};
`;
ErrorMessage.defaultProps = {
    theme: {
        AlertRed: theme.AlertRed,
        sixPT: theme.sixPT,
        errorFontSize: theme.errorFontSize,
        position: 'absolute',
        fonts: {
            regular: theme.fonts.regular,
        },
    },
};
export default ErrorMessage;
