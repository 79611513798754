var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components/native';
import { Text } from 'react-native';
import themeObj, { headingStyles } from '../theme';
const Heading = styled((_a) => {
    var { theme, customStyle, level } = _a, rest = __rest(_a, ["theme", "customStyle", "level"]);
    return <Text {...rest}/>;
}) `
  font-size: ${({ theme, level }) => theme.headingStyles(level).fontSize};
  line-height: ${({ theme, level }) => theme.headingStyles(level).lineHeight};
  font-family: ${({ theme }) => `'${theme.fonts.regular}'`};
  ${({ customStyle }) => customStyle};
`;
Heading.defaultProps = {
    theme: {
        headingStyles,
        fonts: themeObj.fonts.regular,
    },
};
export default Heading;
