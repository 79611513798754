import styled from 'styled-components/native';
import Text from '../../elements/Text';
import theme from '../../theme';
const Placeholder = styled(Text) `
  color: ${({ theme, placeholderTextColor }) => placeholderTextColor || theme.MediumText};
  font-size: ${({ theme }) => theme.textInputFontSize};
`;
Placeholder.defaultProps = {
    MediumText: theme.MediumText,
    textInputFontSize: theme.textInputFontSize,
};
export default Placeholder;
