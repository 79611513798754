var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
// @ts-nocheck
/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import { View, Animated, Easing, TouchableWithoutFeedback, TouchableNativeFeedback, Platform } from 'react-native';
import { isFunction } from 'lodash';
import StyledTouchableRipple from '../../styles/blocks/TouchableRipple';
const ANDROID_VERSION_LOLLIPOP = 21;
const supported = Platform.OS === 'android' && Platform.Version >= ANDROID_VERSION_LOLLIPOP;
const Ripple = props => {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [ripples, setRipples] = useState([]);
    const mounted = useRef(false);
    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        }
        return () => {
            mounted.current = false;
        };
    }, []);
    const onLayout = event => {
        const { rippleEfect } = props;
        if (rippleEfect) {
            const { width, height } = event.nativeEvent.layout;
            setWidth(width);
            setHeight(height);
        }
    };
    const onAnimationEnd = () => {
        const { rippleEfect } = props;
        if (rippleEfect && mounted && mounted.current) {
            setRipples(ripplesList => ripplesList.slice(1));
        }
    };
    const startRipple = event => {
        const { rippleDuration, rippleCentered, rippleSize = 0, onRippleAnimation } = props;
        const w2 = 0.5 * width;
        const h2 = 0.5 * height;
        const { locationX, locationY } = rippleCentered ? { locationX: w2, locationY: h2 } : event.nativeEvent;
        const offsetX = Math.abs(w2 - locationX);
        const offsetY = Math.abs(h2 - locationY);
        const R = rippleSize > 0 ? 0.5 * rippleSize : Math.sqrt(Math.pow((w2 + offsetX), 2) + Math.pow((h2 + offsetY), 2));
        const ripple = {
            unique: Date.now(),
            progress: new Animated.Value(0),
            locationX,
            locationY,
            R,
        };
        const animation = Animated.timing(ripple.progress, {
            toValue: 1,
            easing: Easing.out(Easing.ease),
            duration: rippleDuration,
            useNativeDriver: true,
        });
        if (onRippleAnimation) {
            onRippleAnimation(animation, onAnimationEnd);
        }
        if (mounted) {
            setRipples((prevState) => [...prevState, ripple]);
        }
    };
    const onPress = event => {
        const { onPress, rippleSequential, rippleEfect } = props;
        if (!rippleSequential || !ripples.length) {
            if (rippleEfect) {
                startRipple(event);
            }
            if (isFunction(onPress)) {
                requestAnimationFrame(() => onPress(event));
            }
        }
    };
    const onLongPress = event => {
        const { onLongPress, rippleEfect } = props;
        if (rippleEfect) {
            startRipple(event);
        }
        if (isFunction(onLongPress)) {
            requestAnimationFrame(() => onLongPress(event));
        }
    };
    const onPressIn = event => {
        const { onPressIn } = props;
        if (isFunction(onPressIn)) {
            onPressIn(event);
        }
    };
    const onPressOut = event => {
        const { onPressOut } = props;
        if (isFunction(onPressOut)) {
            onPressOut(event);
        }
    };
    const renderRipple = ({ unique, progress, locationX, locationY, R }) => {
        const { rippleColor, rippleOpacity, rippleFades, radius = 10 } = props;
        const rippleStyle = {
            top: locationY - radius,
            left: locationX - radius,
            backgroundColor: rippleColor,
            transform: [
                {
                    scale: progress.interpolate({
                        inputRange: [0, 1],
                        outputRange: [0.5 / radius, R / radius],
                    }),
                },
            ],
            opacity: rippleFades
                ? progress.interpolate({
                    inputRange: [0, 1],
                    outputRange: [rippleOpacity, 0],
                })
                : rippleOpacity,
        };
        return <StyledTouchableRipple.Ripple radius={radius} style={rippleStyle} key={unique}/>;
    };
    const { accessible, accessibilityLabel, children, delayLongPress, delayPressIn, delayPressOut, disabled, hitSlop, nativeID, pressRetentionOffset, rippleContainerBorderRadius, testID, radius, rippleEfect } = props, rest = __rest(props, ["accessible", "accessibilityLabel", "children", "delayLongPress", "delayPressIn", "delayPressOut", "disabled", "hitSlop", "nativeID", "pressRetentionOffset", "rippleContainerBorderRadius", "testID", "radius", "rippleEfect"]);
    const touchableProps = {
        delayLongPress,
        delayPressIn,
        delayPressOut,
        disabled,
        hitSlop,
        pressRetentionOffset,
        onPress,
        onPressIn,
        testID,
        nativeID,
        accessible,
        accessibilityLabel,
        onPressOut,
        onLongPress: onLongPress || undefined,
        onLayout,
    };
    if (supported) {
        return (<TouchableNativeFeedback {...touchableProps}>
        <View {...rest}>{children}</View>
      </TouchableNativeFeedback>);
    }
    return (<TouchableWithoutFeedback {...touchableProps}>
      <View {...rest} pointerEvents="box-only">
        {children}
        {rippleEfect && <StyledTouchableRipple>{ripples.map(renderRipple)}</StyledTouchableRipple>}
      </View>
    </TouchableWithoutFeedback>);
};
Ripple.defaultProps = {
    rippleColor: 'rgb(0, 0, 0)',
    rippleOpacity: 0.3,
    rippleDuration: 500,
    rippleSize: 0,
    radius: 10,
    rippleCentered: true,
    rippleSequential: false,
    rippleFades: true,
    disabled: false,
    onPress: () => { },
    onPressIn: () => { },
    onRippleAnimation: (animation, callback) => animation.start(callback),
    testID: '',
    rippleEfect: false,
};
export default Ripple;
