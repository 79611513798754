var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components/native';
import Text from '../../elements/Text';
import theme from '../../theme';
const StyledOptionText = styled((_a) => {
    var { theme, disabled } = _a, rest = __rest(_a, ["theme", "disabled"]);
    return <Text {...rest}/>;
}) `
  flex: 1;
  font-size: ${({ theme }) => theme.labelFontSize};
  padding-top: ${({ theme }) => theme.tenPT};
  padding-left: ${({ theme }) => theme.twentyFivePT};
  padding-bottom: ${({ theme }) => theme.sixPT};
  color: ${({ theme, disabled }) => (disabled ? theme.FormElements : theme.DarkText)};
  line-height: ${({ theme }) => theme.labelFontSize};
  ${props => props.textCustomStyle}
`;
StyledOptionText.defaultProps = {
    theme: {
        sixPT: theme.sixPT,
        tenPT: theme.tenPT,
        twentyFivePT: theme.twentyFivePT,
        labelFontSize: theme.labelFontSize,
        DarkText: theme.DarkText,
        FormElements: theme.FormElements,
    },
};
export default StyledOptionText;
