var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/jsx-curly-newline */
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Animated, View, TouchableWithoutFeedback, Platform } from 'react-native';
import { reject, find, get, has, includes, isEmpty, groupBy } from 'lodash';
import { withForm } from '@brighte/brighte-one-core';
import Selection from '../styles/blocks/Selection';
import OptionRow from '../styles/blocks/OptionRow';
import Tag from '../styles/blocks/Tag';
import useAnimation from '../core/hooks/useAnimation';
import withVisibility from '../core/hoc/withVisibility';
import withResponsive from '../core/hoc/withResponsive';
import { InputGroupContext } from '../components/Grid/Col';
import { PopoverResponsive } from '../components/Popover';
import Loading from '../components/Loading';
import StyledPopover from '../styles/blocks/Popover/index';
import theme from '../styles/theme';
const SelectionContainer = props => {
    const { fieldName, id, label, customStyle: { customStyleContainer, customStyleInput, customStyleDropdown, customStyleOption, customStyleLabel, errorMessageStyle, }, fieldAttributes, items, loading, onSelectedItemsChange, multi, notFoundText, searchInputPlaceholderText, placeholderTextColor, onChangeInput, onSelectClick, placeholder, errorMessage, popoverContent, inputGroup, onChangeValue, value, inputGroupLength } = props, rest = __rest(props, ["fieldName", "id", "label", "customStyle", "fieldAttributes", "items", "loading", "onSelectedItemsChange", "multi", "notFoundText", "searchInputPlaceholderText", "placeholderTextColor", "onChangeInput", "onSelectClick", "placeholder", "errorMessage", "popoverContent", "inputGroup", "onChangeValue", "value", "inputGroupLength"]);
    const isError = !isEmpty(errorMessage);
    const [animationValue] = useAnimation(isError ? 1 : 0, [isError]);
    const [selector, setSelector] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [inputGroupState, setState] = useContext(InputGroupContext);
    const [animateDropdown] = useAnimation(selector ? 1 : 0, [selector]);
    const blur = useRef(null);
    // to get list of options internally (from redux)
    const optionItems = fieldAttributes.items || items;
    useEffect(() => {
        // this is to track state of other fields in input group
        if (inputGroup && inputGroupLength) {
            setState(state => (Object.assign(Object.assign({}, state), { [inputGroupLength.index]: { position: inputGroupLength.index + 1, active: selector, isError } })));
        }
    }, [isError, selector]);
    const onInputTextChange = (value) => {
        if (onChangeInput) {
            onChangeInput(value);
        }
        setSearchTerm(value);
    };
    const findItem = (itemKey) => find(optionItems, (singleItem) => singleItem.value === itemKey) || {};
    const removeItem = item => {
        const newItems = reject(value, singleItem => item.value === singleItem);
        if (onChangeValue) {
            onChangeValue(newItems);
        }
    };
    const removeAllItems = () => {
        if (onChangeValue) {
            onChangeValue([]);
        }
    };
    const displaySelectedItems = (optionalSelctedItems) => {
        const actualSelectedItems = optionalSelctedItems || value;
        const selectedItems = optionItems.filter((item) => actualSelectedItems.includes(item.value));
        return selectedItems.map((singleSelectedItem, index) => {
            const item = findItem(singleSelectedItem.value);
            if (!item) {
                return null;
            }
            return (<Tag 
            // eslint-disable-next-line
            key={`${item.value}-${index}`} dataSet={{ 'brighte-class': 'brighte-selection-tag-selected' }} contentWidth={item.label.length * 10}>
          <Tag.Text numberOfLines={1}>{item.label || item.value}</Tag.Text>
          <Tag.CloseWrapper dataSet={{ 'brighte-class': 'brighte-selection-selected-close' }} onPress={() => removeItem(item)}>
            <Tag.CloseIcon />
          </Tag.CloseWrapper>
        </Tag>);
        });
    };
    const getSelectedTags = () => {
        if (!value || isEmpty(value)) {
            return <Selection.TextLabel dataSet={{ 'brighte-class': 'brighte-selection-text-selected' }}/>;
        }
        if (!multi) {
            const itemTag = value[0];
            const foundItem = findItem(itemTag);
            const renderer = get(foundItem, 'renderer', false);
            return renderer !== false && !isEmpty(renderer) ? (<Selection.TextLabel dataSet={{ 'brighte-class': 'brighte-selection-text-selected' }}>
          {renderer}
        </Selection.TextLabel>) : (<Selection.TextLabel dataSet={{ 'brighte-class': 'brighte-selection-text-selected' }}>
          {get(foundItem, 'label') || ''}
        </Selection.TextLabel>);
        }
        return (<>
        <Selection.TagsWrapper>{Array.isArray(value) && displaySelectedItems(value)}</Selection.TagsWrapper>
        {value && (<Tag.CloseAllContainer>
            <Tag.CloseWrapper dataSet={{ 'brighte-class': 'brighte-selection-removal' }} onPress={() => removeAllItems()} fullRadius>
              <Tag.CloseIcon />
            </Tag.CloseWrapper>
          </Tag.CloseAllContainer>)}
      </>);
    };
    const clearSelector = () => setSelector(false);
    const toggleSelector = () => setSelector(!selector);
    const clearSearchTerm = () => setSearchTerm('');
    const submitSelection = () => {
        clearSearchTerm();
        if (onSelectClick) {
            onSelectClick(selector);
        }
        if (!(fieldAttributes.loading || loading)) {
            toggleSelector();
        }
    };
    const itemSelected = (item) => !!(Array.isArray(value) && value.length && find(value, ele => ele === item.value));
    const toggleItem = (item) => {
        if (!multi && !has(item, 'disabled' || item.disabled !== true)) {
            if (onChangeValue) {
                onChangeValue([item.value]);
            }
            onSelectedItemsChange([item.value]);
            submitSelection();
        }
        else if (!item.disabled) {
            const status = itemSelected(item);
            let newItems = [];
            if (status) {
                newItems = reject(value, (singleItem) => item.value === singleItem);
            }
            else {
                newItems = Array.isArray(value) ? [...value, item.value] : [item.value];
            }
            clearSearchTerm();
            if (onChangeValue) {
                onChangeValue(newItems);
            }
            onSelectedItemsChange(newItems);
        }
    };
    const getOptionRow = (item, index) => {
        const itemIsSelected = itemSelected(item);
        return !itemIsSelected ? (<OptionRow dataSet={{ 'brighte-class': 'brighte-selection-option-row' }} onPress={() => toggleItem(item)} customStyle={customStyleOption} disabled={item.disabled}>
        {has(item, 'renderer') && !isEmpty(item.renderer) ? (<OptionRow.Renderer>{item.renderer}</OptionRow.Renderer>) : (<OptionRow.Text nativeID={id
            ? `brighte-selection-option-value-${id}-${index}`
            : `brighte-selection-option-value-${fieldName}-${index}`} dataSet={{ 'brighte-class': 'brighte-selection-option-row-value' }} disabled={item.disabled}>
            {item.label || item.value}
          </OptionRow.Text>)}
        {itemIsSelected ? <OptionRow.CheckedIcon /> : null}
      </OptionRow>) : null;
    };
    const filterItems = (searchTermVal) => optionItems.filter((item) => includes(item.label.toLowerCase(), searchTermVal.toLowerCase()));
    const itemsToSections = (itemsList) => {
        const obj = groupBy(itemsList, (item) => ('group' in item ? item.group : null));
        return Object.keys(obj).map(key => ({
            group: key,
            data: obj[key],
        }));
    };
    const renderItems = () => {
        const renderItemsSelect = searchTerm ? filterItems(searchTerm) : optionItems;
        if (!isEmpty(renderItems)) {
            return;
        }
        return (<Selection.OptionsList ListEmptyComponent={<OptionRow.Text disabled>{fieldAttributes.loading || loading ? 'loading...' : notFoundText}</OptionRow.Text>} sections={itemsToSections(renderItemsSelect)} renderItem={({ item, index }) => getOptionRow(item, index)} keyExtractor={(item, index) => item.value + index.toString()} renderSectionHeader={({ section: { group } }) => group === 'null' || isEmpty(group) ? null : <OptionRow.GroupTitle>{group.toUpperCase()}</OptionRow.GroupTitle>}/>);
    };
    return (<Selection nativeID={id ? `brighte-selection-container-${id}` : `brighte-selection-container-${fieldName}`} dataSet={{ 'brighte-class': 'brighte-selection-container' }} inputGroupLength={inputGroupLength} inputGroupState={inputGroup && inputGroupState} {...rest}>
      {(label || popoverContent) && (<Selection.LabelContainer dataSet={{ 'brighte-class': 'brighte-selection-label-container' }} inputGroupLength={inputGroupLength}>
          <Selection.Label customStyle={customStyleLabel} dataSet={{ 'brighte-class': 'brighte-selection-label' }} text={label}/>
          {popoverContent && (<PopoverResponsive content={popoverContent}>
              <StyledPopover.Icon type="info" name="information" size={20} dataSet={{ 'brighte-class': 'brighte-popover-icon' }}/>
            </PopoverResponsive>)}
        </Selection.LabelContainer>)}
      <View style={{ zIndex: 1 }} {...Platform.select({
        web: {
            onMouseUp: () => {
                blur.current = true;
            },
            onMouseDown: () => {
                blur.current = false;
            },
        },
    })}>
        {selector ? (<>
            <Selection.InputCell dataSet={{ 'brighte-class': 'brighte-selection-inputcell', error: isError }} hasError={isError} nativeID={id ? `brighte-selection-input-options-${id}` : `brighte-selection-input-options-${fieldName}`} active inputGroupState={inputGroupState} inputGroupLength={inputGroupLength} customStyle={customStyleContainer} {...rest}>
              <Selection.TagsWrapper dataSet={{ 'brighte-class': 'brighte-selection-tags-wrapper' }}>
                {multi && Array.isArray(value) && displaySelectedItems(value)}
                <Selection.TextInput autoFocus dataSet={{ 'brighte-class': 'brighte-selection-input' }} onChangeText={onInputTextChange} placeholder={searchInputPlaceholderText} placeholderTextColor={placeholderTextColor} underlineColorAndroid="transparent" style={{ flex: 1 }} value={searchTerm} customStyle={customStyleInput} id={id || fieldName} onBlur={() => {
        if (blur.current) {
            toggleSelector();
        }
    }}/>
              </Selection.TagsWrapper>
              {fieldAttributes.loading || loading ? (<Loading size={25}/>) : (<Selection.ArrowIcon iconName="arrow-up" onPressIcon={clearSelector}/>)}
            </Selection.InputCell>
            <Selection.OptionsWrapper customStyle={customStyleDropdown} dataSet={{ 'brighte-class': 'brighte-selection-options-wrapper' }} style={{
        transform: [{ scaleY: animateDropdown }],
    }} as={Animated.View}>
              {renderItems()}
            </Selection.OptionsWrapper>
          </>) : (<Selection.InputCell style={{
        backgroundColor: animationValue.interpolate({
            inputRange: [0, 1],
            outputRange: [theme.WhiteColor, theme.LightRed],
        }),
    }} dataSet={{ 'brighte-class': 'brighte-selection-inputcell', error: isError }} nativeID={id ? `brighte-selection-input-tags-${id}` : `brighte-selection-input-tags-${fieldName}`} inputGroupState={inputGroupState} hasError={isError} active={false} inputGroupLength={inputGroupLength} customStyle={customStyleContainer} {...rest}>
            <TouchableWithoutFeedback onPress={submitSelection} hitSlop={{ top: 20, left: 15, bottom: 20, right: 15 }}>
              <Selection.TagsWrapper dataSet={{ 'brighte-class': 'brighte-selection-tags-wrapper' }}>
                {!isEmpty(placeholder) && !value && (<Selection.Placeholder dataSet={{ 'brighte-class': 'brighte-selection-inputcell-placeholder' }} placeholderTextColor={placeholderTextColor} nativeID={id
        ? `brighte-selection-inputcell-placeholder-${id}`
        : `brighte-selection-inputcell-placeholder-${fieldName}`}>
                    {placeholder}
                  </Selection.Placeholder>)}
                {getSelectedTags()}

                {fieldAttributes.loading || loading ? (<Loading size={25}/>) : (<Selection.ArrowIcon iconName="arrow-down"/>)}
              </Selection.TagsWrapper>
            </TouchableWithoutFeedback>
          </Selection.InputCell>)}
      </View>
      {isError && (<Selection.ErrorMessage as={Animated.Text} style={{ opacity: animationValue, transform: [{ scaleY: animationValue }] }} customStyle={errorMessageStyle}>
          {errorMessage}
        </Selection.ErrorMessage>)}
    </Selection>);
};
SelectionContainer.defaultProps = {
    customStyle: {},
    items: [],
    loading: false,
    multi: false,
    value: [],
    searchInputPlaceholderText: 'Search',
    placeholderTextColor: theme.MediumText,
    onSelectedItemsChange: () => { },
    placeholder: '',
    notFoundText: 'No option found',
};
export const SelectionContainerPlain = SelectionContainer;
export default withVisibility({ level: 'field' })(withForm(withResponsive(SelectionContainer)));
