/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from 'styled-components/native';
import { applyResponsiveness } from '@brighte/brighte-one-core';
import TouchableRipple from '../../../components/TouchableRipple';
const ButtonItem = styled(TouchableRipple) `
  align-items: center;
  background-color: ${({ theme, selected, hover, disabled }) => {
    if (disabled) {
        return theme.WhiteColor;
    }
    if (selected) {
        return hover ? theme.hoverGreyButtonColor : theme.MediumText;
    }
    return hover ? theme.hoverGreyButtonColor : theme.WhiteColor;
}}};
  border-top-left-radius: ${({ theme, listLength: [index] }) => (index === 0 ? theme.blockBorderRadius : 0)};
  border-top-right-radius: ${({ theme, listLength: [index, length] }) => index + 1 === length ? theme.blockBorderRadius : 0};
  border-bottom-right-radius: ${({ theme, listLength: [index, length] }) => index + 1 === length ? theme.blockBorderRadius : 0};
  border-bottom-left-radius: ${({ theme, listLength: [index] }) => (index === 0 ? theme.blockBorderRadius : 0)};
  border-color: ${({ theme }) => theme.FormElements};
  border-width: 1px;
  border-right-width: ${({ listLength: [index, length], responsiveClass, responsiveBreakPoint }) => applyResponsiveness(responsiveClass, responsiveBreakPoint) || index + 1 === length ? 1 : 0};
  elevation: 1;
  height: ${({ theme }) => theme.buttonHeight};
  min-height: ${({ theme }) => theme.buttonHeight};
  justify-content: center;
  width: ${({ listLength: [index, length] }) => `${100 / length}%`};

  ${({ responsiveClass, responsiveBreakPoint, theme, listLength: [index, length] }) => {
    // this function handles responsive behaviour
    const responsive = applyResponsiveness(responsiveClass, responsiveBreakPoint);
    if (!responsive) {
        return null;
    }
    return `
    width: 100%;
    border-radius: 0;
    border-bottom-width: ${index + 1 === length ? 1 : 0};
    border-top-right-radius: ${index === 0 ? theme.blockBorderRadius : 0};
    border-bottom-left-radius: ${index + 1 === length ? theme.blockBorderRadius : 0};
    `;
}}

  ${props => props.customStyle}
`;
export default ButtonItem;
