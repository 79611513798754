import { View } from 'react-native';
import styled from 'styled-components/native';
import theme from '../../theme';
const LoadingContainer = styled(View) `
  background: ${({ theme, colorOverlay }) => colorOverlay || theme.lightOverlayBackground};
  height: 100%;
  width: 100%;
  opacity: 1;
  position: absolute;
  align-items: center;
  align-content: center;
  justify-content: center;
  z-index: ${({ zIndex }) => zIndex || 2};
  ${props => props.customStyle}
`;
LoadingContainer.defaultProps = {
    theme: {
        darkOverlayBackground: theme.darkOverlayBackground,
        lightOverlayBackground: theme.lightOverlayBackground,
    },
};
export default LoadingContainer;
