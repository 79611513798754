import styled from 'styled-components/native';
import { Icon } from '../../components/Icons';
import theme from '../theme';
const AlertIcon = styled(Icon) `
  align-self: center;
  color: ${({ type }) => {
    switch (type) {
        case 'info':
            return theme.MediumText;
        case 'danger':
            return theme.AlertRed;
        case 'warning':
            return theme.Yellow;
        default:
            return theme.BrighteGreen;
    }
}};
  justify-content: center;
  position: relative;
  padding-right: ${theme.tenPT};
  ${props => props.customStyle}
`;
AlertIcon.defaultProps = {
    theme: {
        BrighteGreen: theme.BrighteGreen,
        AlertRed: theme.AlertRed,
        LightBackground: theme.LightBackground,
    },
};
export default AlertIcon;
