var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useContext, useEffect } from 'react';
import { FormContext, useNavigation } from '@brighte/brighte-one-core';
import StyledProgressBarContainer from '../styles/blocks/ProgressBar';
import useAnimation from '../core/hooks/useAnimation';
const ProgressBarContainer = props => {
    const { id, customStyle: { customStyleContainer, customStyleProgress } = {}, totalStep, currentStep, lastStep } = props, rest = __rest(props, ["id", "customStyle", "totalStep", "currentStep", "lastStep"]);
    const { formName } = useContext(FormContext);
    const { currentPage, pages, previousPage, currentPageIndex, previousPageIndex } = useNavigation();
    const totalStepValue = totalStep || pages;
    const currentStepValue = currentStep || currentPageIndex + 1 || 1;
    const lastStepValue = lastStep || previousPageIndex || 0;
    const duration = 500;
    const [animationValue, startAnimation] = useAnimation(currentStepValue / pages, [currentStepValue], duration, lastStepValue / totalStepValue, false, false);
    useEffect(() => {
        // Dont start animation on SET_PAGE_COUNT is done
        if (startAnimation && pages !== 1) {
            startAnimation();
        }
    }, [currentPage, previousPage, pages]);
    return (<StyledProgressBarContainer customStyle={customStyleContainer} nativeID={`brighte-progress-container-${id || formName}`} dataSet={{ 'brighte-class': 'brighte-progress-container' }}>
      <StyledProgressBarContainer.Progress customStyle={customStyleProgress} dataSet={{ 'brighte-class': 'brighte-progress-progress' }} totalStep={totalStepValue} currentStep={currentStepValue} lastStep={lastStepValue} style={{
        width: animationValue.interpolate({
            inputRange: [0, 1],
            outputRange: ['0%', '100%'],
        }),
    }} {...rest}/>
    </StyledProgressBarContainer>);
};
ProgressBarContainer.defaultProps = {
    customStyle: {},
    id: '',
    totalStep: 0,
    currentStep: 0,
    lastStep: 0,
};
export default ProgressBarContainer;
