var customValidators = new Map();
/**
 * Type of validator
 */
export var ValidatorType;
(function (ValidatorType) {
    ValidatorType[ValidatorType["Extend"] = 0] = "Extend";
    ValidatorType[ValidatorType["Override"] = 1] = "Override";
})(ValidatorType || (ValidatorType = {}));
/**
 * Add a custom validator
 *
 * @param key - The key to store the validator against (generally the field name)
 * @param validator - The validator function
 * @param type - The optional validator type
 * @returns The returned `Map` object
 */
export var addValidator = function (key, validator, type) {
    if (type === void 0) { type = ValidatorType.Extend; }
    return customValidators.set(key, { validator: validator, type: type });
};
/**
 * Retrieve a validator function
 *
 * @param key - The key of the element to retrieve the validator function for
 * @returns The validator function
 */
export var getValidator = function (key) {
    var customValidator = customValidators.get(key);
    return customValidator ? customValidator.validator : undefined;
};
export default customValidators;
