import styled from 'styled-components/native';
import Text from '../../elements/Text';
import theme from '../../theme';
const Title = styled(Text) `
  font-size: ${theme.titleFontSize};
  ${props => props.customStyle}
`;
Title.defaultProps = {
    theme: {
        titleFontSize: theme.titleFontSize,
    },
};
export default Title;
