import styled from 'styled-components/native';
import Text from './Text';
import theme from '../theme';
const Label = styled(Text) `
  display: flex;
  min-height: ${({ theme }) => theme.twentyPT};
  height: auto;
  line-height: ${({ theme }) => theme.twentyPT};
  align-items: flex-end;
  color: ${({ theme }) => theme.MediumText};
  font-size: ${({ theme }) => theme.labelFontSize};
  ${({ customStyle }) => customStyle};
`;
Label.defaultProps = {
    theme: {
        MediumText: theme.MediumText,
        twentyPT: theme.twentyPT,
        labelFontSize: theme.labelFontSize,
    },
};
export default Label;
