/**
 * Platform specific booleans provided by RN
 */
import { Platform } from 'react-native';
const isWeb = Platform.OS === 'web';
const isNative = !isWeb;
const isIos = Platform.OS === 'ios';
const isAndroid = Platform.OS === 'android';
const isDesktop = isWeb && typeof matchMedia !== 'undefined' && matchMedia('(min-width: 768px)').matches;
const isMobile = !isDesktop;
export { isWeb, isNative, isIos, isAndroid, isDesktop, isMobile };
