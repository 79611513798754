var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import ProgressBar from '../../elements/ProgressBar';
import theme from '../../theme';
const ProgressBarContainer = styled((_a) => {
    var { theme, customStyle } = _a, rest = __rest(_a, ["theme", "customStyle"]);
    return <View {...rest}/>;
}) `
  width: 100%;
  height: ${({ theme }) => theme.sixPT};
  ${({ customStyle }) => customStyle};
`;
ProgressBarContainer.defaultProps = {
    theme: {
        sixPT: theme.sixPT,
    },
};
ProgressBarContainer.Progress = ProgressBar;
export default ProgressBarContainer;
