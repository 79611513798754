var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { isArray } from 'lodash';
import customValidators, { ValidatorType, getValidator } from '../utilities/customValidators';
import pageMap from '../root/PageMap';
import { SET_VALUE, SET_VISIBILITY, SET_INITIAL_VALUE, VALIDATE_PAGE_FIELDS, SET_INITIAL_DATA, SET_CURRENT_PAGE, SET_PAGE_COUNT, SET_VALID_PAGE, VALIDATE_PAGE, SET_FIELD_ATTRIBUTES, VALIDATE_FIELDS, PREPOPULATE_VALUES, RESET_FORM, } from '../actions';
import checkValidation from '../utilities/validation';
import config from '../utilities';
export default (function (state, action) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    var _t, _u, _v, _w;
    if (state === void 0) { state = {}; }
    switch (action.type) {
        // TO DO: tidy up SET_INITIAL_DATA action
        case SET_INITIAL_DATA: {
            return __assign(__assign({}, state), (_a = {}, _a[action.formName] = {
                fields: state[action.formName] && state[action.formName].fields
                    ? __assign({}, state[action.formName].fields) : __assign({}, action.initData.initFields),
                navigation: state[action.formName] && state[action.formName].navigation
                    ? __assign({}, state[action.formName].navigation) : __assign(__assign({}, config.initNavigation), action.initData.initNavigation),
            }, _a));
        }
        case SET_INITIAL_VALUE: {
            return __assign(__assign({}, state), (_b = {}, _b[action.formName] = __assign(__assign({}, state[action.formName]), { fields: __assign(__assign({}, state[action.formName].fields), (_c = {}, _c[action.fieldName] = action.initData, _c)) }), _b));
        }
        case SET_VALUE: {
            return __assign(__assign({}, state), (_d = {}, _d[action.formName] = __assign(__assign({}, state[action.formName]), { fields: __assign(__assign({}, state[action.formName].fields), (_e = {}, _e[action.fieldName] = __assign(__assign({}, state[action.formName].fields[action.fieldName]), action.fieldData), _e)) }), _d));
        }
        case PREPOPULATE_VALUES: {
            var fieldValue_1 = state[action.formName].fields;
            var fieldsOnPage = action.fields.reduce(function (acc, fieldObj) {
                if (state[action.formName]) {
                    var fieldName = Object.keys(fieldObj)[0];
                    var value = Object.values(fieldObj)[0];
                    acc[fieldName] = __assign(__assign({}, fieldValue_1[fieldName]), { value: value });
                }
                return acc;
            }, {});
            return __assign(__assign({}, state), (_f = {}, _f[action.formName] = __assign(__assign({}, state[action.formName]), { fields: __assign(__assign({}, state[action.formName].fields), fieldsOnPage) }), _f));
        }
        case SET_VISIBILITY: {
            var obj = isArray(action.fieldName)
                ? action.fieldName.reduce(function (acc, fieldName) {
                    var _a;
                    acc[fieldName] = __assign(__assign({}, state[action.formName].fields[fieldName]), { visible: action.visible, value: action.clearFieldValues ? '' : (_a = state[action.formName].fields[fieldName]) === null || _a === void 0 ? void 0 : _a.value });
                    return acc;
                }, {})
                : (_g = {},
                    _g[action.fieldName] = __assign(__assign({}, state[action.formName].fields[action.fieldName]), { visible: action.visible, value: action.clearFieldValues ? '' : (_t = state[action.formName].fields[action.fieldName]) === null || _t === void 0 ? void 0 : _t.value }),
                    _g);
            return __assign(__assign({}, state), (_h = {}, _h[action.formName] = __assign(__assign({}, state[action.formName]), { fields: __assign(__assign({}, state[action.formName].fields), obj) }), _h));
        }
        // This is part of naviagation
        case VALIDATE_PAGE_FIELDS: {
            var fieldValue_2 = state[action.formName].fields;
            var fieldsOnPage = Object.keys(fieldValue_2).reduce(function (acc, fieldName) {
                if (state[action.formName] &&
                    fieldValue_2[fieldName].visible &&
                    fieldValue_2[fieldName].belongsToPage === action.page) {
                    var _a = fieldValue_2[fieldName], value = _a.value, validationConfig = _a.validationConfig, valid = _a.valid, errorMessage = _a.errorMessage;
                    var customValidator = customValidators.get(fieldName);
                    var validatorFn = customValidator && customValidator.type === ValidatorType.Override
                        ? getValidator(fieldName)
                        : checkValidation;
                    var validationObj = validatorFn(value, __assign(__assign({}, validationConfig), { validation: getValidator(fieldName) }), fieldName, { valid: valid, errorMessage: errorMessage }, state[action.formName]);
                    acc[fieldName] = __assign(__assign({}, fieldValue_2[fieldName]), { errorMessage: validationObj.error, valid: validationObj.isValid });
                }
                return acc;
            }, {});
            return __assign(__assign({}, state), (_j = {}, _j[action.formName] = __assign(__assign({}, state[action.formName]), { fields: __assign(__assign({}, state[action.formName].fields), fieldsOnPage) }), _j));
        }
        // This is to validate custom field []
        case VALIDATE_FIELDS: {
            var _x = state[action.formName], fields_1 = _x.fields, navigation_1 = _x.navigation;
            var fieldsToValidate = action.fields ||
                Object.keys(fields_1).filter(function (fieldName) { return fields_1[fieldName].belongsToPage === navigation_1.currentPage; });
            var fieldsOnPage = fieldsToValidate.reduce(function (acc, fieldName) {
                if (state[action.formName]) {
                    var _a = fields_1[fieldName], value = _a.value, validationConfig = _a.validationConfig;
                    var customValidator = customValidators.get(fieldName);
                    var validatorFn = customValidator && customValidator.type === ValidatorType.Override
                        ? getValidator(fieldName)
                        : checkValidation;
                    var validationObj = validatorFn(value, __assign(__assign({}, validationConfig), { validation: getValidator(fieldName) }), fieldName, {}, state[action.formName]);
                    acc[fieldName] = __assign(__assign({}, fields_1[fieldName]), { errorMessage: validationObj.error, valid: validationObj.isValid });
                }
                return acc;
            }, {});
            return __assign(__assign({}, state), (_k = {}, _k[action.formName] = __assign(__assign({}, state[action.formName]), { fields: __assign(__assign({}, state[action.formName].fields), fieldsOnPage) }), _k));
        }
        case SET_FIELD_ATTRIBUTES: {
            return __assign(__assign({}, state), (_l = {}, _l[action.formName] = __assign(__assign({}, state[action.formName]), { fields: __assign(__assign({}, state[action.formName].fields), (_m = {}, _m[action.fieldName] = __assign(__assign({}, state[action.formName].fields[action.fieldName]), action.attributes), _m)) }), _l));
        }
        case SET_CURRENT_PAGE: {
            return __assign(__assign({}, state), (_o = {}, _o[action.formName] = __assign(__assign({}, state[action.formName]), { navigation: __assign({ pages: (_u = state[action.formName].navigation) === null || _u === void 0 ? void 0 : _u.pages, currentPage: action.currentPage || action.pageName, previousPage: action.previousPage || state[action.formName].navigation.currentPage, pageName: action.pageName, currentPageIndex: (_v = pageMap.get(action.currentPage || action.pageName)) === null || _v === void 0 ? void 0 : _v.index, previousPageIndex: (_w = pageMap.get(action.previousPage || state[action.formName].navigation.currentPage)) === null || _w === void 0 ? void 0 : _w.index, valid: false }, action.data) }), _o));
        }
        case SET_VALID_PAGE: {
            return __assign(__assign({}, state), (_p = {}, _p[action.formName] = __assign(__assign({}, state[action.formName]), { navigation: __assign(__assign({}, state[action.formName].navigation), { valid: action.isValid }) }), _p));
        }
        case SET_PAGE_COUNT: {
            return __assign(__assign({}, state), (_q = {}, _q[action.formName] = __assign(__assign({}, state[action.formName]), { navigation: __assign(__assign({}, state[action.formName].navigation), { pages: action.pages }) }), _q));
        }
        case VALIDATE_PAGE: {
            var _y = state[action.formName], fields_2 = _y.fields, navigation_2 = _y.navigation;
            var validPage = Object.keys(fields_2)
                .filter(function (field) { return fields_2[field].belongsToPage === navigation_2.currentPage && fields_2[field].visible; })
                .every(function (pageField) { return fields_2[pageField].valid === true; });
            return __assign(__assign({}, state), (_r = {}, _r[action.formName] = __assign(__assign({}, state[action.formName]), { navigation: __assign(__assign({}, state[action.formName].navigation), { valid: validPage }) }), _r));
        }
        case RESET_FORM: {
            return __assign(__assign({}, state), (_s = {}, _s[action.formName] = {
                fields: action.fields || {},
                navigation: action.navigation ? action.navigation : state[action.formName].navigation,
            }, _s));
        }
        default:
            return state;
    }
});
