import OptionRowContainer from './Container';
import CheckedIcon from './CheckedIcon';
import StyledText from './Text';
import StyledRenderer from './Renderer';
import GroupTitle from './GroupTitle';
OptionRowContainer.Text = StyledText;
OptionRowContainer.Renderer = StyledRenderer;
OptionRowContainer.GroupTitle = GroupTitle;
OptionRowContainer.CheckedIcon = CheckedIcon;
export default OptionRowContainer;
