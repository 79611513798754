import React from 'react';
import { Animated } from 'react-native';
import { noop, isEmpty } from 'lodash';
import { withForm } from '@brighte/brighte-one-core';
import StyledButtonList from '../styles/blocks/ButtonList';
import { ButtonList } from '../components';
import withResponsive from '../core/hoc/withResponsive';
import withVisibility from '../core/hoc/withVisibility';
import useAnimation from '../core/hooks/useAnimation';
import theme from '../styles/theme';
import StyledPopover from '../styles/blocks/Popover';
import { PopoverResponsive } from '../components/Popover';
const ButtonContainer = props => {
    const { errorMessage, label, id, customStyle: { customStyleContainer, customStyleButton, labelCustomStyle, errorMessageStyle }, fieldName, onPress, onChangeValue, optionalText, options = [], popoverContent, value, responsiveClass, responsiveBreakPoint, required, } = props;
    const isError = !isEmpty(errorMessage);
    const [animationValue] = useAnimation(isError ? 1 : 0, [isError]);
    const handlePress = (value) => {
        onChangeValue(value);
        onPress(value);
    };
    return (<StyledButtonList customStyle={customStyleContainer} dataSet={{ 'brighte-class': 'brighte-button-list-container', error: isError }} nativeID={id ? `brighte-button-list-container-${id}` : `brighte-button-list-container-${fieldName}`} responsiveClass={responsiveClass} responsiveBreakPoint={responsiveBreakPoint}>
      <StyledButtonList.LabelContainer>
        <StyledButtonList.Label text={label} dataSet={{ 'brighte-class': 'brighte-button-list-label' }} customStyle={labelCustomStyle}/>
        {popoverContent && (<PopoverResponsive content={popoverContent}>
            <StyledPopover.Icon type="info" name="information" size={20} dataSet={{ 'brighte-class': 'brighte-popover-icon' }}/>
          </PopoverResponsive>)}
        {!required && (<StyledButtonList.Label text={optionalText} dataSet={{ 'brighte-class': 'brighte-button-list-label-optional' }} customStyle={Object.assign(Object.assign({}, labelCustomStyle), { fontSize: theme.fifteenPT })}/>)}
      </StyledButtonList.LabelContainer>
      <ButtonList id={id || fieldName} customStyle={customStyleButton} options={options} onPress={handlePress} value={value} responsiveClass={responsiveClass} responsiveBreakPoint={responsiveBreakPoint}/>
      {isError && (<StyledButtonList.ErrorMessage as={Animated.Text} style={{ opacity: animationValue, transform: [{ scaleY: animationValue }] }} customStyle={errorMessageStyle}>
          {isError && errorMessage}
        </StyledButtonList.ErrorMessage>)}
    </StyledButtonList>);
};
ButtonContainer.defaultProps = {
    customStyle: {},
    options: [
        {
            value: 'yes',
            label: 'Yes',
        },
        { value: 'no', label: 'No' },
    ],
    label: '',
    onPress: noop,
    responsiveBreakPoint: 'XS',
    optionalText: '(optional)',
};
export default withVisibility({ level: 'field' })(withForm(withResponsive(ButtonContainer)));
