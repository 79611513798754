import styled from 'styled-components/native';
import { View } from 'react-native';
import theme from '../../theme';
const InnerCell = styled(View) `
  flex: 1;
  flex-direction: column;
  position: relative;
  max-width: ${theme.fourHundredFiftyPT};
  height: auto;
  bottom: 0;
  min-height: ${({ theme }) => theme.thirtyPT};
  align-content: flex-start;
  ${props => props.customStyle}
`;
InnerCell.defaultProps = {
    theme: {
        fourHundredFiftyPT: theme.fourHundredFiftyPT,
        thirtyPT: theme.thirtyPT,
        tenPT: theme.tenPT,
    },
};
export default InnerCell;
