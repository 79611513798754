import React, { useContext, useEffect, useMemo } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import config from '../utilities';
import { setPageCount, setCurrentPage } from '../actions';
import { FormContext } from './FormContainer';
import pageMap from './PageMap';
var setMap = function (navigationConfig) {
    pageMap.clear();
    navigationConfig.forEach(function (_a, index) {
        var pageName = _a.pageName, page = _a.page, _b = _a.data, data = _b === void 0 ? {} : _b;
        return pageMap.set(pageName, { page: page, index: index, data: data });
    });
};
var PageContainer = function (_a) {
    var navigationConfig = _a.navigationConfig, children = _a.children;
    var formName = useContext(FormContext).formName;
    var _b = useSelector(function (state) { return get(state, "formData[" + formName + "].navigation", config.initNavigation); }, shallowEqual), currentPage = _b.currentPage, previousPage = _b.previousPage;
    var dispatch = useDispatch();
    setMap(navigationConfig);
    useEffect(function () {
        // set the page count
        if (navigationConfig) {
            if (currentPage === null) {
                var initialPage = navigationConfig[0];
                dispatch(setCurrentPage({
                    formName: formName,
                    currentPage: initialPage.pageName,
                    pageName: initialPage.pageName,
                    data: initialPage === null || initialPage === void 0 ? void 0 : initialPage.data,
                }));
            }
            dispatch(setPageCount(navigationConfig.length, formName));
            setMap(navigationConfig);
        }
    }, [navigationConfig]);
    var pageObj = (currentPage && pageMap.get(currentPage)) || navigationConfig[0];
    var Render = pageObj.page;
    return useMemo(function () {
        if (isFunction(children)) {
            return children(navigationConfig, currentPage, previousPage);
        }
        return <Render />;
    }, [currentPage]);
};
export default PageContainer;
