import React from 'react';
import styled from 'styled-components';
import CloseWrapperNative from './CloseWrapper.native';
import theme from '../../theme';
const Div = styled.div `
  height: 100%;
  padding-left: ${({ theme }) => theme.tenPT};
  padding-right: ${({ theme }) => theme.tenPT};
  [data-brighte-class=${({ dataBrighteClass }) => dataBrighteClass}] {
    background-color: 'transparent';
    transition: all 0.2s ease-out;
  }
  [data-brighte-class=${({ dataBrighteClass }) => dataBrighteClass}]:hover {
    background-color: ${({ bgHoverColor }) => bgHoverColor};
    transition: all 0.2s ease-in;
  }
`;
const CloseWrapper = props => {
    const { 'data-brighte-class': dataBrighteClass, theme } = props;
    const bgHoverColor = theme.FormElements;
    return (<Div bgHoverColor={bgHoverColor} dataBrighteClass={dataBrighteClass} theme={theme}>
      <CloseWrapperNative {...props}/>
    </Div>);
};
CloseWrapper.defaultProps = {
    theme: {
        FormElements: theme.FormElements,
        tenPT: theme.tenPT,
        twoPT: theme.twoPT,
    },
    fullRadius: false,
};
export default CloseWrapper;
