var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { TextInput as TextInputRN } from 'react-native';
import styled from 'styled-components/native';
import theme from '../../theme';
const TextInput = styled((_a) => {
    var { theme, customStyle, prefix, inputType, hasError } = _a, rest = __rest(_a, ["theme", "customStyle", "prefix", "inputType", "hasError"]);
    return <TextInputRN {...rest}/>;
}) `
  font-size: ${({ theme }) => theme.textInputFontSize};
  font-family: ${({ theme }) => `'${theme.fonts.regular}'`};
  color: ${({ theme }) => theme.DarkText};
  width: 100%;
  min-width: ${({ theme }) => theme.fiftyPT};
  height: ${({ theme }) => theme.thirtyFourPT};
  margin-top: ${({ theme }) => theme.fivePT};
  margin-bottom: ${({ theme }) => theme.fivePT};
  padding-right: ${({ theme }) => theme.fifteenPT};
  padding-top: ${({ theme }) => theme.threePT};
  ${props => props.customStyle}
`;
TextInput.defaultProps = {
    active: false,
    theme: {
        borderWidth: theme.borderWidth,
        colorInputActive: theme.colorInputActive,
        FormElements: theme.FormElements,
        borderRadius: theme.borderRadius,
        colorInputHover: theme.colorInputHover,
        textInputHeight: theme.textInputHeight,
        threePT: theme.threePT,
        fivePT: theme.fivePT,
        thirtyFourPT: theme.thirtyFourPT,
        fifteenPT: theme.fifteenPT,
        fiftyPT: theme.fiftyPT,
        textInputFontSize: theme.textInputFontSize,
        DarkText: theme.DarkText,
        fonts: { regular: theme.fonts.regular },
    },
};
export default TextInput;
