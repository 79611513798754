import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import TextInput from '../../../components/TextInput';
import ErrorMessage from '../../../components/ErrorMessage';
import Label from '../../../components/Label';
import Icon from '../../elements/PrefixIcon';
import Prefix from '../../elements/PrefixText';
import Suffix from '../../elements/SuffixText';
import HelpText from '../../elements/HelpText';
import LabelContainer from '../../elements/LabelContainer';
import { inputGroupRadius, inputGroupBorderWidth, inputGroupResponseMarginContainer, inputGroupResponseLabelContainer, } from '../../mixin/inputGroup';
const TextInputContainer = styled(View) `
  flex-basis: auto;
  ${inputGroupResponseMarginContainer}
  ${props => props.customStyle};
`;
const ValidationIndicatorInputWrapper = styled(View) `
  bottom: ${({ theme }) => theme.twentyTwoPT};
  position: absolute;
  right: ${({ theme }) => theme.fifteenPT};
  ${({ customStyle }) => customStyle};
`;
// This is wrapper to animate background color
const TextInputWrapper = styled(View) ``;
const TextInputWithRef = (props, ref) => <TextInput ref={ref} {...props}/>;
const TextInputWithGroup = styled(React.forwardRef(TextInputWithRef)) `
  ${inputGroupRadius};
  ${inputGroupBorderWidth};
`;
const LabelWithGroup = styled(LabelContainer) `
  ${inputGroupResponseLabelContainer}
`;
const Optional = styled(Label) `
  color: ${({ theme }) => theme.LightText};
  display: flex;
  flex: auto;
  justify-content: flex-end;
  font-size: ${({ theme }) => theme.fifteenPT};
  ${({ customStyle }) => customStyle};
`;
TextInputContainer.TextInput = TextInputWithGroup;
TextInputContainer.TextInputWrapper = TextInputWrapper;
TextInputContainer.Label = Label;
TextInputContainer.Optional = Optional;
TextInputContainer.ErrorMessage = ErrorMessage;
TextInputContainer.Icon = Icon;
TextInputContainer.LabelContainer = LabelContainer;
TextInputContainer.ValidationIndicatorInput = ValidationIndicatorInputWrapper;
TextInputContainer.Prefix = Prefix;
TextInputContainer.Suffix = Suffix;
TextInputContainer.HelpText = HelpText;
TextInputContainer.LabelContainer = LabelWithGroup;
export default TextInputContainer;
