var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Text } from 'react-native';
import styled from 'styled-components/native';
import theme from '../theme';
const Hyperlink = styled((_a) => {
    var { theme, customStyle, defaultProps } = _a, rest = __rest(_a, ["theme", "customStyle", "defaultProps"]);
    return <Text {...rest}/>;
}) `
  color: ${({ theme }) => theme.BrighteBlue};
  font-size: ${({ theme }) => theme.textFontSize};
  font-family: ${({ theme }) => `'${theme.fonts.regular}'`};
  text-decoration: ${({ theme }) => `${theme.BrighteBlue} underline`};
  ${({ customStyle }) => customStyle};
`;
Hyperlink.defaultProps = {
    theme: {
        BrighteBlue: theme.BrighteBlue,
        textFontSize: theme.textFontSize,
        fonts: {
            regular: theme.fonts.regular,
        },
    },
};
export default Hyperlink;
