import { View } from 'react-native';
import styled from 'styled-components/native';
const ViewRN = styled(View) `
  margin-bottom: 2px;
  ${({ customStyle }) => customStyle};
`;
ViewRN.defaultProps = {
    theme: {},
};
export default ViewRN;
