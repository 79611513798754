import { keyframes } from 'styled-components';
export const colorTransform = (bgFromColor, bgToColor, bdFromColor, bdToColor) => keyframes `
  from {
    background-color: ${bgFromColor};
    ${bdFromColor && `border-color: ${bdFromColor}`};
  }

  to {
    background-color: ${bgToColor};
    ${bdToColor && `border-color: ${bdToColor}`};
  }
`;
export default { colorTransform };
