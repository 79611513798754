var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import StyledRow from '../../styles/blocks/Grid/Row';
import withResponsive from '../../core/hoc/withResponsive';
import withVisibility from '../../core/hoc/withVisibility';
const Row = props => {
    const { id, customStyle, children, bottomGap } = props, rest = __rest(props, ["id", "customStyle", "children", "bottomGap"]);
    return (<StyledRow bottomGap={bottomGap} dataSet={{ 'brighte-class': 'brighte-one-row' }} customStyle={customStyle} {...(id ? { nativeID: id } : {})} {...rest}>
      {children}
    </StyledRow>);
};
Row.defaultProps = {
    bottomGap: true,
    flex: 1,
    debug: false,
    flexDirection: 'horizontal',
    wrap: false,
    valign: 'center',
    responsiveBreakPoint: 'XS',
};
// This HOC can be splited to the containers later if required
export default withVisibility({ level: 'section' })(withResponsive(Row));
