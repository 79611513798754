import styled from 'styled-components/native';
import { View } from 'react-native';
import ButtonListItem from './Item';
import ButtonText from './Text';
import ButtonListItemContainer from './ListItemContainer';
import ErrorMessage from '../../../components/ErrorMessage';
import Label from '../../../components/Label';
import LabelContainer from '../../elements/LabelContainer';
const ButtonListContainer = styled(View) `
  display: flex;
  ${props => props.customStyle}
`;
ButtonListContainer.ItemContainer = ButtonListItemContainer;
ButtonListContainer.Item = ButtonListItem;
ButtonListContainer.Text = ButtonText;
ButtonListContainer.ErrorMessage = ErrorMessage;
ButtonListContainer.Label = Label;
ButtonListContainer.LabelContainer = LabelContainer;
export default ButtonListContainer;
