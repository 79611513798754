var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components/native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import theme from '../theme';
const Label = styled((_a) => {
    var { theme, customStyle, name } = _a, rest = __rest(_a, ["theme", "customStyle", "name"]);
    return <Icon name={name} {...rest}/>;
}) `
  top: auto;
  bottom: auto;
  line-height: ${({ theme }) => theme.sixtyFivePT};
  position: absolute;
  left: ${({ theme }) => theme.twentyPT};
  ${({ customStyle }) => customStyle};
`;
Label.defaultProps = {
    theme: {
        twentyPT: theme.twentyPT,
        textInputHeight: theme.textInputHeight,
        sixtyFivePT: theme.sixtyFivePT,
    },
};
export default Label;
