var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components/native';
import ErrorMessage from '../../../components/ErrorMessage';
const Status = styled((_a) => {
    var { error, customStyle } = _a, rest = __rest(_a, ["error", "customStyle"]);
    return <ErrorMessage {...rest}/>;
}) `
  position: relative;
  top: 0px;
  bottom: 0px;
  padding-top: 0px;
  line-height: ${({ theme }) => theme.errorFontSize};
  margin-left: ${({ theme }) => theme.eightPT};
  color: ${({ theme, status, error }) => (status === 'verified' && !error ? theme.BrighteGreen : theme.AlertRed)};
  ${({ customStyle }) => customStyle};
`;
export default Status;
