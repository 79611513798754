var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Animated } from 'react-native';
import styled from 'styled-components/native';
import { inputGroupRadius, inputGroupBorderWidth } from '../mixin/inputGroup';
import inputField from '../mixin/inputField';
const InputCell = styled((_a) => {
    var { theme, customStyle, inputGroup } = _a, rest = __rest(_a, ["theme", "customStyle", "inputGroup"]);
    return <Animated.View {...rest}/>;
}) `
  flex-direction: row;
  align-items: center;
  ${inputField};
  ${inputGroupRadius};
  ${inputGroupBorderWidth};
  ${props => props.customStyle}
`;
InputCell.defaultProps = {
    active: false,
    theme: {},
};
export default InputCell;
