var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components/native';
import View from '../../elements/View';
import theme from '../../theme';
const StyledOptionRenderer = styled((_a) => {
    var { theme } = _a, rest = __rest(_a, ["theme"]);
    return <View {...rest}/>;
}) `
  flex: 1;
  padding-top: ${({ theme }) => theme.tenPT};
  padding-left: ${({ theme }) => theme.tenPT};
  padding-bottom: ${({ theme }) => theme.sixPT};
`;
StyledOptionRenderer.defaultProps = {
    theme: {
        sixPT: theme.sixPT,
        tenPT: theme.tenPT,
    },
};
export default StyledOptionRenderer;
