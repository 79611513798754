export var loadState = function (key) {
    if (!key) {
        return undefined;
    }
    try {
        var serializedState = sessionStorage.getItem(key);
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    }
    catch (err) {
        return undefined;
    }
};
export var saveState = function (key, state) {
    try {
        var serializedState = JSON.stringify(state);
        sessionStorage.setItem(key, serializedState);
    }
    catch (err) {
        // eslint-disable-next-line no-console
        console.warn("Problem to save redux state to local storage " + err);
    }
};
