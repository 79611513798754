const borderColor = ({ theme: { AlertRed, colorInputActive, FormElements }, active, hasError }) => {
    if (hasError) {
        return AlertRed;
    }
    if (!hasError && active) {
        return colorInputActive;
    }
    if (!hasError && !active) {
        return FormElements;
    }
};
export default ({ theme, active, hasError, prefix }) => `
  border-right-width:  ${theme.borderWidth};
  border-left-width: ${theme.borderWidth};
  border-top-width: ${theme.borderWidth};
  border-bottom-width: ${theme.borderWidth};
  border-color: ${borderColor({ theme, active, hasError })};
  border-radius: ${theme.borderRadius};
  height: ${theme.textInputHeight};
  padding-right: ${theme.thirtyEightPT};
  padding-left: ${prefix ? theme.fortyFivePT : theme.fifteenPT};
`;
