import styled from 'styled-components/native';
import Icon from '../../elements/Icon';
import theme from '../../theme';
const TooltipIcon = styled(Icon) `
  display: flex;
  padding-left: ${({ theme }) => theme.sixPT};
  padding-right: ${({ theme }) => theme.sixPT};
  font-size: ${({ theme }) => theme.sixteenPT};
  ${props => props.customStyle};
`;
TooltipIcon.defaultProps = {
    theme: {
        sixteenPT: theme.sixteenPT,
    },
};
export default TooltipIcon;
