import styled from 'styled-components/native';
import { View } from 'react-native';
import theme from '../../theme';
const StyledContextWrapper = styled(View) `
  margin-top: ${({ theme }) => theme.thirtyPT};
  ${props => props.customStyle}
`;
StyledContextWrapper.defaultProps = {
    theme: {
        thirtyPT: theme.thirtyPT,
    },
};
export default StyledContextWrapper;
