var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components/native';
import Text from './Text';
import theme from '../theme';
const PrefixText = styled((_a) => {
    var { theme, customStyle } = _a, rest = __rest(_a, ["theme", "customStyle"]);
    return <Text {...rest}/>;
}) `
  top: auto;
  bottom: auto;
  line-height: ${({ theme }) => theme.sixtyPT};
  position: absolute;
  margin-left: ${({ theme }) => theme.twentyPT};
  font-size: ${({ theme }) => theme.twentyPT};
  color: ${({ theme }) => theme.LightText};
  ${({ customStyle }) => customStyle};
`;
PrefixText.defaultProps = {
    theme: {
        sixtyFivePT: theme.sixtyFivePT,
        textInputHeight: theme.textInputHeight,
        twentyPT: theme.twentyPT,
        LightText: theme.LightText,
    },
};
export default PrefixText;
