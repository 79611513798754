import isFunction from 'lodash/isFunction';
export var formatMaskedDate = function (value, mask, onDelete) {
    var cleanValue = value.replace(/(\s|[^\d\-\/])/g, '');
    var cleanMask = mask.replace(/[^#\-\/]/g, '');
    var i = 0;
    var lastReplacedIndex = -1;
    var addSeperator = false;
    var swapTemp = '';
    var filledMask = cleanMask.replace(/[#\-\/]/g, function (_, j) {
        if (i >= cleanValue.length) {
            return _;
        }
        if (cleanValue[i] === cleanMask[j + 1]) {
            lastReplacedIndex = j + 1;
            return '+';
        }
        if (((cleanMask[j] === '#' && (cleanMask[j + 1] === '#' || cleanMask[j + 1] === undefined)) ||
            !cleanMask.includes(cleanValue[i])) &&
            RegExp(/[^\d]/g).test(cleanValue[i])) {
            // eslint-disable-next-line no-plusplus
            i++;
            return '';
        }
        lastReplacedIndex = j;
        addSeperator = !!(RegExp(/[^#@\*]/g).test(mask[j + 1]) && !onDelete);
        // eslint-disable-next-line no-plusplus
        return cleanValue[i++];
    });
    filledMask = filledMask.replace(/[\d\+]/g, function (_, j) {
        if (swapTemp !== '') {
            return swapTemp;
        }
        if (RegExp(/\d/g).test(_) && filledMask[j + 1] === '+') {
            swapTemp = _;
            return '0';
        }
        swapTemp = '';
        return _;
    });
    return filledMask.substring(0, lastReplacedIndex + (addSeperator ? 2 : 1));
};
export var stringReverse = function (val) {
    if (val === void 0) { val = ''; }
    return val.split('').reverse().join('');
};
export var formatMasked = function (args) {
    var value = args.value, _a = args.formatter, formatter = _a === void 0 ? false : _a, _b = args.inputType, inputType = _b === void 0 ? '' : _b, _c = args.lazyMaskSeperator, lazyMaskSeperator = _c === void 0 ? false : _c, _d = args.onDelete, onDelete = _d === void 0 ? false : _d, _e = args.maskPrefix, maskPrefix = _e === void 0 ? '' : _e, _f = args.reverseMask, reverseMask = _f === void 0 ? false : _f;
    var _g = args.mask, mask = _g === void 0 ? '' : _g;
    var newVal = value;
    if (formatter && isFunction(formatter)) {
        newVal = formatter(value);
    }
    if (!mask) {
        return newVal;
    }
    newVal =
        maskPrefix.length && newVal.startsWith(maskPrefix) ? newVal.substring(maskPrefix.length, newVal.length) : newVal;
    if (inputType === 'date' && RegExp(/^[#\-\/]+$/s).test(mask)) {
        return formatMaskedDate(newVal, mask, onDelete);
    }
    if (inputType === 'mobile') {
        var initVal = newVal.length ? newVal.slice(0, 1) : '';
        mask = initVal === '0' ? '#### ### ###' : '### ### ###';
    }
    if (reverseMask) {
        mask = stringReverse(mask);
        newVal = stringReverse(newVal);
    }
    var i = 0;
    var lastReplacedIndex = -1;
    var addSeperator = false;
    var cleanValue = newVal.replace(/[\W]/g, '');
    var filledMask = mask.replace(/#|@|\*/g, function (_, j) {
        if (i >= cleanValue.length) {
            return _;
        }
        lastReplacedIndex = j;
        addSeperator = !!(RegExp(/[^#@\*]/g).test(mask[j + 1]) && !onDelete && !lazyMaskSeperator);
        // eslint-disable-next-line no-plusplus
        return cleanValue[i].length > 0 ? cleanValue[i++] : '';
    });
    var result = filledMask.substring(0, lastReplacedIndex + (addSeperator ? 2 : 1));
    if (reverseMask) {
        result = stringReverse(result);
    }
    return maskPrefix + result;
};
export var handleChangeMasked = function (value, mask, inputType, useMaskedValue, maskPrefix) {
    if (inputType === void 0) { inputType = ''; }
    if (useMaskedValue === void 0) { useMaskedValue = false; }
    if (maskPrefix === void 0) { maskPrefix = ''; }
    var newVal = maskPrefix.length && value.startsWith(maskPrefix.slice(0, maskPrefix.length - 1))
        ? value.substring(maskPrefix.length, value.length)
        : value;
    if (inputType === 'date') {
        useMaskedValue = true;
    }
    var cleanMask = useMaskedValue ? mask.replace(/[\s]/g, '') : mask.replace(/[^#@\*]/g, '');
    var cleanValue = useMaskedValue
        ? newVal.replace(/[\s]/g, '').substring(0, cleanMask.length)
        : newVal.replace(/[\W]/g, '').substring(0, cleanMask.length);
    var cleanResult = cleanValue.replace(/[\w]/g, function (val, i) {
        if (cleanMask[i] === '@' && RegExp(/[a-zA-Z]/g).test(val)) {
            return val;
        }
        if (cleanMask[i] === '#' && RegExp(/[\d]/g).test(val)) {
            return val;
        }
        if (cleanMask[i] === '*') {
            return val;
        }
        return '';
    });
    return useMaskedValue ? maskPrefix + cleanResult : cleanResult;
};
export default { formatMasked: formatMasked, formatMaskedDate: formatMaskedDate, handleChangeMasked: handleChangeMasked };
