var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components/native';
import { View } from 'react-native';
import InputCell from '../../elements/InputCell';
import TextInput from './TextInput';
import ArrowIcon from './ArrowIcon';
import OptionsWrapper from './OptionsWrapper';
import OptionsList from './OptionsList';
import TagsWrapper from './TagsWrapper';
import ContextWrapper from './ContextWrapper';
import SwitchWrapper from './SwitchWrapper';
import Placeholder from './Placeholder';
import ErrorMessage from '../../../components/ErrorMessage';
import Label from '../../../components/Label';
import LabelContainer from '../../elements/LabelContainer';
import Text from '../../elements/Text';
import theme from '../../theme';
import { inputGroupResponseMarginContainer, inputGroupResponseLabelContainer } from '../../mixin/inputGroup';
const SelectionContainer = styled((_a) => {
    var { customStyle } = _a, rest = __rest(_a, ["customStyle"]);
    return <View {...rest}/>;
}) `
  flex-basis: auto;
  z-index: 1;
  ${inputGroupResponseMarginContainer}
  ${props => props.customStyle}
`;
export const StyledInputCell = styled((_a) => {
    var { active } = _a, rest = __rest(_a, ["active"]);
    return <InputCell active={active} {...rest}/>;
}) `
  ${({ active, theme }) => active &&
    `border-bottom-color: ${theme.FormElements}; border-bottom-left-radius: 0; border-bottom-right-radius: 0;`}
  height: auto;
  padding-left: ${theme.fifteenPT};
  padding-right: ${theme.fifteenPT};
  min-height: ${theme.textInputHeight};
  ${props => props.customStyle}
`;
const TextLabel = styled(Text) `
  flex: 1;
  line-height: ${({ theme }) => theme.textInputFontSize};
  font-size: ${({ theme }) => theme.textInputFontSize};
`;
const LabelWithGroup = styled(LabelContainer) `
  ${inputGroupResponseLabelContainer}
`;
SelectionContainer.InputCell = StyledInputCell;
SelectionContainer.OptionsWrapper = OptionsWrapper;
SelectionContainer.OptionsList = OptionsList;
SelectionContainer.TagsWrapper = TagsWrapper;
SelectionContainer.ContextWrapper = ContextWrapper;
SelectionContainer.SwitchWrapper = SwitchWrapper;
SelectionContainer.TextInput = TextInput;
SelectionContainer.ErrorMessage = ErrorMessage;
SelectionContainer.Label = Label;
SelectionContainer.LabelContainer = LabelWithGroup;
SelectionContainer.ArrowIcon = ArrowIcon;
SelectionContainer.Placeholder = Placeholder;
SelectionContainer.TextLabel = TextLabel;
export default SelectionContainer;
