var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { TextInput as TextInputRN } from 'react-native';
import styled from 'styled-components/native';
import theme from '../theme';
import inputField from '../mixin/inputField';
const FilteredTextInput = (_a, ref) => {
    var { theme, customStyle, prefix, inputType, inputGroupLength, responsiveClass, inputGroup, hasError, inputGroupState, responsiveBreakPoint, touched, active, hovered, setFieldAttributes, fieldAttributes, visible, validationmessage, validation, setFieldAttributesDispatch, isHovered } = _a, rest = __rest(_a, ["theme", "customStyle", "prefix", "inputType", "inputGroupLength", "responsiveClass", "inputGroup", "hasError", "inputGroupState", "responsiveBreakPoint", "touched", "active", "hovered", "setFieldAttributes", "fieldAttributes", "visible", "validationmessage", "validation", "setFieldAttributesDispatch", "isHovered"]);
    return <TextInputRN ref={ref} {...rest}/>;
};
const TextInput = styled(React.forwardRef(FilteredTextInput)) `
  ${inputField};
  font-size: ${({ theme }) => theme.textInputFontSize};
  font-family: ${({ theme }) => `'${theme.fonts.regular}'`};
  color: ${({ theme }) => theme.DarkText};
  width: 100%;
  height: ${({ theme }) => theme.textInputHeight};
  ${props => props.customStyle};
`;
TextInput.defaultProps = {
    active: false,
    theme: {
        AlertRed: theme.AlertRed,
        borderWidth: theme.borderWidth,
        colorInputActive: theme.colorInputActive,
        FormElements: theme.FormElements,
        borderRadius: theme.borderRadius,
        colorInputHover: theme.colorInputHover,
        fifteenPT: theme.fifteenPT,
        fiftyPT: theme.fiftyPT,
        threePT: theme.threePT,
        textInputFontSize: theme.textInputFontSize,
        DarkText: theme.DarkText,
        LightRed: theme.LightRed,
        textInputHeight: theme.textInputHeight,
        fonts: { regular: theme.fonts.regular },
    },
};
export default TextInput;
