var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components/native';
import { SectionList, StyleSheet } from 'react-native';
import theme from '../../theme';
const StyledOptionsList = styled((_a) => {
    var { theme, customStyle, sections } = _a, rest = __rest(_a, ["theme", "customStyle", "sections"]);
    return (<SectionList sections={sections} {...rest}/>);
}) `
  flex-direction: column;
  box-shadow: ${({ theme }) => `0px 2px 4px ${theme.elevationColor}`};
  background-color: ${({ theme }) => theme.WhiteColor};
  border-color: ${({ theme }) => theme.FormElements};
  border-width: ${StyleSheet.hairlineWidth};
  border-top-width: 0;
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius};
  max-height: ${({ theme }) => theme.threeHundredPT};
  width: 100%;
  position: ${({ theme }) => (theme.platform === 'web' ? 'absolute' : 'relative')};
  ${props => props.customStyle}
`;
StyledOptionsList.defaultProps = {
    theme: {
        FormElements: theme.FormElements,
        threeHundredPT: theme.threeHundredPT,
        WhiteColor: theme.WhiteColor,
        borderRadius: theme.borderRadius,
        elevationColor: theme.elevationColor,
    },
};
export default StyledOptionsList;
