var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components/native';
import { View } from 'react-native';
import theme from '../../theme';
const CloseAllContainer = styled((_a) => {
    var { theme, contentWidth } = _a, rest = __rest(_a, ["theme", "contentWidth"]);
    return <View {...rest}/>;
}) `
  width: auto;
  justify-content: center;
  flex-direction: row;
  align-items: flex-end;
  height: ${({ theme }) => theme.thirtyFourPT};
  margin-right: ${({ theme }) => theme.tenPT};
  ${props => props.customStyle}
`;
CloseAllContainer.defaultProps = {
    theme: {
        LightBackground: theme.LightBackground,
        tenPT: theme.tenPT,
        thirtyFourPT: theme.thirtyFourPT,
        sixtyPT: theme.sixtyPT,
    },
};
export default CloseAllContainer;
