var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import { flex, drawDebug, applyResponsiveness } from '@brighte/brighte-one-core';
import theme from '../../theme';
const Row = styled((_a) => {
    var { debug, responsiveClass, flexDirection, customStyle, theme } = _a, props = __rest(_a, ["debug", "responsiveClass", "flexDirection", "customStyle", "theme"]);
    return <View {...props}/>;
}) `
  ${props => props.debug && drawDebug()};
  ${props => !applyResponsiveness(props.responsiveClass, props.responsiveBreakPoint) && flex(props)};
  flex-basis: auto;
  margin-bottom: ${({ theme, bottomGap }) => (bottomGap ? theme.fieldBottomGap : 0)};
  ${({ customStyle }) => customStyle};
`;
Row.defaultProps = {
    bottomGap: true,
    theme: {
        thirtyPT: theme.thirtyPT,
    },
};
export default Row;
