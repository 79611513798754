var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components/native';
import { ScrollView } from 'react-native';
import theme from '../../theme';
const BodyScrollWrapper = styled((_a) => {
    var { theme, width, childrenWidth } = _a, rest = __rest(_a, ["theme", "width", "childrenWidth"]);
    return <ScrollView {...rest}/>;
}) `
  padding-top: ${theme.hunredPT};
  padding-bottom: ${theme.hunredPT};
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: ${({ theme, childrenWidth, width }) => childrenWidth || width || theme.fiveHundredPT};
  ${props => props.customStyle}
`;
BodyScrollWrapper.defaultProps = {
    theme: {
        hunredPT: theme.hunredPT,
        tenPT: theme.tenPT,
        fiveHundredPT: theme.fiveHundredPT,
        hunredThirtyPT: theme.hunredThirtyPT,
    },
};
export default BodyScrollWrapper;
