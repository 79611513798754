import React from 'react';
import { isFunction } from 'lodash';
import theme from '../../styles/theme';
import StyledButton from '../../styles/blocks/Button';
const Button = props => {
    const { id, children, className, classNameText, label, btnTheme, highlighColor, disabled, customStyle: { textCustomStyle, customStyleButton }, onPress, classIdentifier, } = props;
    return (<StyledButton dataSet={{ 'brighte-class-identifier': classIdentifier, 'brighte-class': className }} btnTheme={btnTheme} onPress={e => onPress && onPress(e)} customStyle={customStyleButton} rippleColor={highlighColor || theme.BlackColor} accessible disabled={disabled} accessibilityRole="button" {...(id ? { nativeID: id } : {})}>
      {children ? (React.Children.only(isFunction(children) ? children() : children)) : (<StyledButton.Text disabled={disabled} style={textCustomStyle} btnTheme={btnTheme} dataSet={{ 'brighte-class': classNameText }}>
          {label}
        </StyledButton.Text>)}
    </StyledButton>);
};
Button.defaultProps = {
    btnTheme: 'green',
    customStyle: {},
    className: 'brighte-button',
    classNameText: 'brighte-button-text',
    disabled: false,
};
export default Button;
