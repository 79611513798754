var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components/native';
import { Check } from '../../../components/Icons';
import theme from '../../theme';
const styledCheckIcon = styled((_a) => {
    var { theme } = _a, rest = __rest(_a, ["theme"]);
    return <Check {...rest}/>;
}) `
  position: relative;
  justify-content: center;
  align-self: center;
  font-size: ${({ theme }) => theme.thirteenPT};
  color: ${({ theme, checked, color }) => (checked ? color || theme.WhiteColor : 'transparent')};
  ${props => props.customStyle}
`;
styledCheckIcon.defaultProps = {
    theme: {
        MediumText: theme.MediumText,
        WhiteColor: theme.WhiteColor,
        thirteenPT: theme.thirteenPT,
    },
};
export default styledCheckIcon;
