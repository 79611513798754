var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable react/no-array-index-key */
import React, { useRef, useState, useEffect } from 'react';
import { Animated } from 'react-native';
import { isEmpty, get } from 'lodash';
import { withForm, formatMasked, handleChangeMasked } from '@brighte/brighte-one-core';
import Loading from '../components/Loading';
import StyledSecureContainer from '../styles/blocks/SecureCode';
import withVisibility from '../core/hoc/withVisibility';
import withResponsive from '../core/hoc/withResponsive';
import theme from '../styles/theme';
import useAnimation from '../core/hooks/useAnimation';
import ValidationIndicator from '../components/ValidationIndicator';
var Status;
(function (Status) {
    Status["VERIFIED"] = "verified";
    Status["INVALID"] = "invalid";
    Status["ERROR"] = "error";
})(Status || (Status = {}));
const DEFAULT_CODE_LENGTH = 4;
const SecureCodeContainer = props => {
    const { codeLength = DEFAULT_CODE_LENGTH, customStyle: { customStyleContainer, customStyleInput, customStyleLabel, customStyleWrapper, customStyleLoadingContainer, } = {}, errorMessage, fieldName, fieldAttributes, focusOnError, id, label, mask, onFocus, onBlur, onChangeValue, onChangeText, optionalText, required, setFieldAttributes, successMessage, triggerApi, value = [], verifyingMessage, underlineColorAndroid, } = props;
    const inputEl = useRef('');
    const [loading, setLoading] = useState(false);
    const isError = !isEmpty(errorMessage);
    const [animationValueError] = useAnimation(isError ? 1 : 0, [isError]);
    const status = get(fieldAttributes, 'status', '');
    const loadingState = get(fieldAttributes, 'loading', loading);
    const [statusValue] = useAnimation(loadingState ? 1 : 0, [loadingState]);
    const [activeIndex, setActiveIndex] = useState(-1);
    const refs = useRef(Array(codeLength)
        .fill('')
        .map(() => ({
        current: null,
    }))).current;
    const handleKeypressEvent = (event) => {
        const { nativeEvent: { key }, } = event;
        inputEl.current = key;
    };
    const onFocusInput = (index) => e => {
        if (onFocus) {
            onFocus(e);
        }
        setActiveIndex(index);
    };
    const onBlurInput = e => {
        if (onBlur) {
            onBlur(e);
        }
        setActiveIndex(-1);
    };
    useEffect(() => {
        if (activeIndex !== codeLength - 1 && inputEl.current !== 'Backspace' && value[activeIndex]) {
            refs[activeIndex + 1].current.focus();
        }
        if (inputEl.current === 'Backspace' && activeIndex && isEmpty(value[activeIndex])) {
            refs[activeIndex - 1].current.focus();
        }
        if (triggerApi && value[codeLength - 1] && activeIndex === codeLength - 1) {
            const asyncFunction = () => __awaiter(void 0, void 0, void 0, function* () {
                setLoading(true);
                yield triggerApi(value, setFieldAttributes);
                setLoading(false);
            });
            asyncFunction();
        }
    }, [value]);
    useEffect(() => {
        if (focusOnError && refs[0] && status === Status.ERROR) {
            refs[0].current.focus();
        }
    }, [loadingState]);
    const handleCodeValue = (index) => (text) => {
        const valueText = mask ? handleChangeMasked(text, mask) : text;
        const code = Array.isArray(value) && !isEmpty(value) ? value : Array(codeLength).fill('');
        const enteredValue = code.map((item, i) => (index === i ? valueText : item));
        if (onChangeText) {
            onChangeText(enteredValue, index);
        }
        if (onChangeValue) {
            onChangeValue(enteredValue, true);
        }
    };
    const formatValue = (text) => (mask ? formatMasked({ value: text, mask }) : text);
    return (<StyledSecureContainer customStyle={customStyleContainer} nativeID={id ? `brighte-secure-code-container-${id}` : `brighte-secure-code-container-${fieldName}`} dataSet={{ 'brighte-class': 'brighte-secure-code-container', error: isError }}>
      <StyledSecureContainer.LabelContainer>
        <StyledSecureContainer.Label customStyle={customStyleLabel} dataSet={{ 'brighte-class': 'brighte-secure-code-label' }} text={label}/>
        {!required && (<StyledSecureContainer.Label dataSet={{ 'brighte-class': 'brighte-secure-code-optional' }} text={optionalText}/>)}
      </StyledSecureContainer.LabelContainer>
      <StyledSecureContainer.SecureCodeWrapper customStyle={customStyleWrapper}>
        {Array(codeLength)
        .fill(1)
        .map((item, index) => (<StyledSecureContainer.SecureCodeInputWrapper key={index} as={Animated.View} style={{
        backgroundColor: animationValueError.interpolate({
            inputRange: [0, 1],
            outputRange: [theme.WhiteColor, theme.LightRed],
        }),
    }}>
              <StyledSecureContainer.SecureCodeInput ref={refs && refs[index]} key={index} name={index} active={activeIndex === index} dataSet={{ 'brighte-class': `brighte-secure-code-input-${index}`, error: isError }} customStyle={customStyleInput} editable={!loadingState && !(status === Status.VERIFIED)} hasError={isError} id={`${fieldName}-${index}`} onKeyPress={handleKeypressEvent} onChangeText={handleCodeValue(index)} onFocus={onFocusInput(index)} onBlur={onBlurInput} value={!isEmpty(value) ? formatValue(value[index]) : ''} underlineColorAndroid={underlineColorAndroid}/>
            </StyledSecureContainer.SecureCodeInputWrapper>))}
      </StyledSecureContainer.SecureCodeWrapper>
      {loadingState && (<StyledSecureContainer.LoadingContainer customStyle={customStyleLoadingContainer} as={Animated.View} style={{ opacity: statusValue, transform: [{ scaleY: statusValue }] }}>
          <StyledSecureContainer.LoadingWrapper>
            <Loading color={theme.Yellow} size={18}/>
          </StyledSecureContainer.LoadingWrapper>
          <StyledSecureContainer.LoadingText status={status}>{verifyingMessage}</StyledSecureContainer.LoadingText>
        </StyledSecureContainer.LoadingContainer>)}
      {((!loadingState && isError) || (!loading && status === Status.VERIFIED)) && (<StyledSecureContainer.LoadingContainer customStyle={customStyleLoadingContainer}>
          <ValidationIndicator type={isError ? 'cross' : 'check'}/>
          <StyledSecureContainer.ValidationMessage status={status} error={isError}>
            {isError ? errorMessage : successMessage}
          </StyledSecureContainer.ValidationMessage>
        </StyledSecureContainer.LoadingContainer>)}
    </StyledSecureContainer>);
};
SecureCodeContainer.defaultProps = {
    focusOnError: true,
    label: '',
    customStyle: {},
    value: [],
    errorMessage: 'Error',
    onChangeText: () => null,
    onChangeValue: () => null,
    optionalText: '(optional)',
    required: false,
    successMessage: 'Secure code accepted!',
    verifyingMessage: 'Verifying secure code...',
    triggerApi: () => null,
    mask: '#',
    codeLength: DEFAULT_CODE_LENGTH,
    underlineColorAndroid: '',
};
export const SecureCodeContainerPlain = SecureCodeContainer;
export default withVisibility({ level: 'field' })(withForm(withResponsive(SecureCodeContainer), {
    inputType: 'secureCode',
}));
