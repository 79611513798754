var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { Linking } from 'react-native';
import StyledHyperlink from '../../styles/elements/Hyperlink';
import { isWeb } from '../../core/utilities/platform';
const Hyperlink = props => {
    const { customStyle, href, text, children, onClick, target, writingDirection } = props;
    const handleClick = () => __awaiter(void 0, void 0, void 0, function* () {
        if (onClick) {
            // if an onClick prop was passed through, honour it
            onClick(props);
            return false;
        }
        if (isWeb) {
            // on web, react-native-web will output hyperlink markup through the
            // accessibilityRole prop.
            return;
        }
        if (!href) {
            return;
        }
        const supported = yield Linking.canOpenURL(href);
        if (supported) {
            Linking.openURL(href);
        }
    });
    const hrefProps = { hrefAttrs: { target } };
    return (<StyledHyperlink dataSet={{ 'brighte-class': 'brighte-hyperlink' }} accessibilityRole="link" href={!onClick ? href : undefined} onPress={handleClick} style={{
        writingDirection,
    }} customStyle={customStyle} {...(href && target ? hrefProps : {})}>
      {children || text}
    </StyledHyperlink>);
};
Hyperlink.defaultProps = {
    onClick: undefined,
    writingDirection: 'ltr',
};
export default Hyperlink;
