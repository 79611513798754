/* eslint-disable react/no-array-index-key */
import React from 'react';
import { noop, isFunction } from 'lodash';
import Hoverable from '../Hoverable';
import StyledButtonList from '../../styles/blocks/ButtonList';
import theme from '../../styles/theme';
const ButtonList = props => {
    const { onPress, customStyle: { customStyleContainer, customStyleButton, textCustomStyle } = {}, id, highlighColor, options, responsiveClass, responsiveBreakPoint, } = props;
    return (<StyledButtonList.ItemContainer customStyle={customStyleContainer} dataSet={{ 'brighte-class': 'brighte-buttonlistitem-container' }} responsiveClass={responsiveClass} responsiveBreakPoint={responsiveBreakPoint} {...(id ? { nativeID: `brighte-buttonlistitem-container-${id}` } : {})}>
      {options.map((option, i) => {
        const { value, label, renderer, disableItem } = option;
        const selected = props.value === value;
        return (<Hoverable key={i}>
            {isHovered => (<StyledButtonList.Item key={i} dataSet={{ 'brighte-class': 'brighte-buttonlist-container' }} onPress={() => onPress && onPress(value)} customStyle={customStyleButton} rippleColor={highlighColor} accessible disabled={disableItem} hover={isHovered} accessibilityRole="button" selected={selected} listLength={[i, props.options.length]} responsiveClass={responsiveClass} responsiveBreakPoint={responsiveBreakPoint} {...(id ? { nativeID: id } : {})}>
                {renderer ? (React.Children.only(isFunction(renderer) ? renderer(selected, isHovered) : renderer)) : (<StyledButtonList.Text selected={selected} hover={isHovered} disabled={disableItem} customStyle={textCustomStyle}>
                    {label || value}
                  </StyledButtonList.Text>)}
              </StyledButtonList.Item>)}
          </Hoverable>);
    })}
    </StyledButtonList.ItemContainer>);
};
ButtonList.defaultProps = {
    customStyle: {},
    highlighColor: theme.WhiteColor,
    onPress: noop,
    value: '',
    responsiveBreakPoint: 'XS',
};
export default ButtonList;
