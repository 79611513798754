var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import StyledErrorMessage from '../../styles/elements/ErrorMessage';
const ErrorMessage = props => {
    const { id, text, children, customStyle } = props, rest = __rest(props, ["id", "text", "children", "customStyle"]);
    return (<StyledErrorMessage {...(id ? { nativeID: id } : {})} dataSet={{ 'brighte-class': 'brighte-error-message' }} customStyle={customStyle} {...rest}>
      {children || text}
    </StyledErrorMessage>);
};
ErrorMessage.defaultProps = {
    as: undefined,
    children: undefined,
    customStyle: {},
    style: {},
    text: '',
};
export default ErrorMessage;
