var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Text } from 'react-native';
import styled from 'styled-components/native';
import theme from '../theme';
const TextRN = styled((_a) => {
    var { theme, customStyle, defaultProps } = _a, rest = __rest(_a, ["theme", "customStyle", "defaultProps"]);
    return <Text {...rest}/>;
}) `
  color: ${({ theme }) => theme.DarkText};
  font-size: ${({ theme }) => theme.textFontSize};
  line-height: ${({ theme }) => theme.textLineFontSize};
  font-family: ${({ theme }) => `'${theme.fonts.regular}'`};
  ${({ customStyle }) => customStyle};
`;
TextRN.defaultProps = {
    theme: {
        textLineFontSize: theme.textLineFontSize,
        DarkText: theme.DarkText,
        textFontSize: theme.textFontSize,
        fonts: {
            regular: theme.fonts.regular,
        },
    },
};
export default TextRN;
