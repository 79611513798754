import { View } from 'react-native';
import styled from 'styled-components/native';
import TooltipIcon from './Icon';
import Text from '../../elements/Text';
import theme from '../../theme';
const Popover = styled(View) `
  flex-basis: auto;
  ${props => props.customStyle};
`;
const ContentText = styled(Text) `
  color: ${({ theme }) => theme.WhiteColor};
  ${props => props.customStyle};
`;
ContentText.defaultProps = {
    theme: {
        WhiteColor: theme.WhiteColor,
    },
};
Popover.LabelContainer = Popover;
Popover.Icon = TooltipIcon;
Popover.ContentText = ContentText;
export default Popover;
