var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { ActivityIndicator as RNActivityIndicator } from 'react-native';
import theme from '../../styles/theme';
const ActivityIndicator = RNActivityIndicator;
const Loading = props => {
    const { customStyle, size, loadingTheme, color, id } = props, rest = __rest(props, ["customStyle", "size", "loadingTheme", "color", "id"]);
    return (<ActivityIndicator nativeID={id} dataSet={{ 'brighte-class': 'brighte-loading' }} size={size} color={color || (loadingTheme === 'dark' ? theme.GreyLoaderColor : theme.BrighteGreen)} style={customStyle} {...rest}/>);
};
Loading.defaultProps = {
    color: '',
    customStyle: {},
    id: 'loader',
    loadingTheme: 'light',
    size: theme.defaultLoaderSize,
};
export default Loading;
