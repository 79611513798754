var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import PopoverStyled from '../../styles/blocks/Popover';
var PopoverComponent = function (props) {
    var content = props.content;
    return (React.createElement("div", null,
        React.createElement(PopoverStyled.GlobalStyle, null),
        React.createElement(PopoverStyled, __assign({ overlayClassName: "brighte-one-web-popover", id: "brighte-one-web-popover", content: content }, props))));
};
PopoverComponent.defaultProps = {
    placement: 'top',
    content: '',
};
export default PopoverComponent;
