var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Platform } from 'react-native';
import React from 'react';
import { Tooltip as TooltipComponent } from 'react-native-elements';
import StyledPopover from '../../styles/blocks/Popover';
import theme from '../../styles/theme';
const Popover = props => {
    const { content, containerStyle, children } = props, rest = __rest(props, ["content", "containerStyle", "children"]);
    const popover = typeof content === 'string' ? <StyledPopover.ContentText>{content}</StyledPopover.ContentText> : content;
    return (<TooltipComponent containerStyle={containerStyle} popover={popover} {...rest}>
      {children}
    </TooltipComponent>);
};
Popover.defaultProps = {
    containerStyle: { padding: theme.tenPT },
    content: '',
    placement: 'top',
    backgroundColor: theme.DarkText,
    pointerColor: 'transparent',
    height: 100,
    width: 250,
};
const PopoverResponsive = Platform.select({
    default: () => Popover,
    web: () => {
        const { Popover: PopoverWeb } = require('@brighte/brighte-one-web');
        return PopoverWeb;
    },
})();
export { PopoverResponsive };
export default Popover;
