var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components/native';
import { View } from 'react-native';
import theme from '../../theme';
const OuterCell = styled((_a) => {
    var { theme, width } = _a, rest = __rest(_a, ["theme", "width"]);
    return <View {...rest}/>;
}) `
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: center;
  justify-content: space-between;
  max-width: ${({ theme, width }) => width || theme.fiveHundredPT};
  min-height: ${({ theme }) => theme.thirtyPT};
  width: auto;
  height: auto;
  bottom: 0;
  padding-top: ${theme.tenPT};
  padding-bottom: ${theme.tenPT};
  margin-left: ${theme.fifteenPT};
  margin-right: ${theme.fifteenPT};
  ${props => props.customStyle}
`;
OuterCell.defaultProps = {
    theme: {
        fiveHundredPT: theme.fiveHundredPT,
        thirtyPT: theme.thirtyPT,
        tenPT: theme.tenPT,
        fifteenPT: theme.fifteenPT,
    },
};
export default OuterCell;
