var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { StyleSheet, View, Platform } from 'react-native';
import styled from 'styled-components/native';
import Header from './Header';
import Footer from './Footer';
import CloseIcon from './CloseIcon';
import CancelButton from './CancelButton';
import AgreeButton from './AgreeButton';
import InnerCell from './InnerCell';
import OuterCell from './OuterCell';
import BodyCell from './BodyCell';
import BodyScrollWrapper from './BodyScrollWrapper';
import Title from './Title';
import Subtitle from './Subtitle';
import Overlay from './Overlay';
import Container from './Container';
import theme from '../../theme';
const NativeModal = styled((_a) => {
    var { theme, customStyle, visible, device } = _a, rest = __rest(_a, ["theme", "customStyle", "visible", "device"]);
    return <View {...rest}/>;
}) `
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  z-index: 99;
  left: 0;
  top: 0;
  position: ${({ device }) => (device === 'web' ? 'fixed' : 'absolute')};
  flex-direction: column;
  flex: 1;
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  ${({ customStyle }) => customStyle};
`;
NativeModal.defaultProps = {
    device: Platform.OS,
};
const FooterOuterCell = styled((_a) => {
    var { theme, customStyle } = _a, rest = __rest(_a, ["theme", "customStyle"]);
    return <OuterCell {...rest}/>;
}) `
  justify-content: ${({ responsiveClass }) => (responsiveClass === 'XS' ? 'space-between' : 'flex-end')};
  border-top-color: ${({ theme }) => theme.ModalDividerLine};
  border-top-width: ${StyleSheet.hairlineWidth};
  height: 100%;
  ${({ customStyle }) => customStyle};
`;
FooterOuterCell.defaultProps = {
    theme: {
        ModalDividerLine: theme.ModalDividerLine,
    },
};
NativeModal.Container = Container;
NativeModal.Header = Header;
NativeModal.Title = Title;
NativeModal.Subtitle = Subtitle;
NativeModal.Overlay = Overlay;
NativeModal.CloseIcon = CloseIcon;
NativeModal.CancelButton = CancelButton;
NativeModal.AgreeButton = AgreeButton;
NativeModal.InnerCell = InnerCell;
NativeModal.OuterCell = OuterCell;
NativeModal.FooterOuterCell = FooterOuterCell;
NativeModal.BodyCell = BodyCell;
NativeModal.BodyScrollWrapper = BodyScrollWrapper;
NativeModal.Footer = Footer;
export default NativeModal;
