import React from 'react';
import StyledHeading from '../../styles/elements/Heading';
export var Level;
(function (Level) {
    Level[Level["One"] = 1] = "One";
    Level[Level["Two"] = 2] = "Two";
    Level[Level["Three"] = 3] = "Three";
    Level[Level["Four"] = 4] = "Four";
    Level[Level["Five"] = 5] = "Five";
    Level[Level["Six"] = 6] = "Six";
})(Level || (Level = {}));
const Heading = ({ id, customStyle, children, text, level }) => (<StyledHeading {...(id ? { nativeID: id } : {})} dataSet={{ 'brighte-class': 'brighte-heading' }} accessibilityRole="header" aria-level={level} customStyle={customStyle} level={level}>
    {text || children}
  </StyledHeading>);
Heading.defaultProps = {
    children: undefined,
    customStyle: {},
    text: '',
};
export default Heading;
