import styled from 'styled-components/native';
import { View } from 'react-native';
const StyledTagsWrapper = styled(View) `
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: center;
  ${props => props.customStyle}
`;
export default StyledTagsWrapper;
