var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import theme from '../../theme';
import Icon from '../../elements/Icon';
const AlertContainer = styled((_a) => {
    var { type, theme } = _a, props = __rest(_a, ["type", "theme"]);
    return <View {...props}/>;
}) `
  flex: 1;
  flex-basis: auto;
  align-items: center;
  border-color: ${({ type }) => {
    switch (type) {
        case 'info':
            return theme.MediumText;
        case 'danger':
            return theme.AlertRed;
        case 'warning':
            return theme.Yellow;
        default:
            return theme.BrighteGreen;
    }
}};
  background-color: ${({ type, backGround }) => {
    switch (type) {
        case 'info':
            return backGround ? theme.LightBackground : theme.WhiteColor;
        case 'danger':
            return backGround ? theme.LightRed : theme.WhiteColor;
        case 'warning':
            return backGround ? theme.LightYellow : theme.WhiteColor;
        default:
            return backGround ? theme.LightGreen : theme.WhiteColor;
    }
}};
  border-radius: ${theme.borderRadius}
  border-width: ${theme.borderWidth};
  display: flex;
  flex-direction: row;
  padding-top: ${theme.twentyPT};
  padding-bottom: ${theme.twentyPT};
  padding-left: ${theme.twentyPT};
  padding-right: ${theme.twentyPT};
  ${props => props.customStyle}
`;
AlertContainer.defaultProps = {
    theme: {
        BrighteGreen: theme.BrighteGreen,
        AlertRed: theme.AlertRed,
        LightBackground: theme.LightBackground,
    },
};
AlertContainer.Icon = Icon;
export default AlertContainer;
