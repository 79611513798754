var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { noop } from 'lodash';
import { InputType } from '@brighte/brighte-one-core';
import StyledTextInput from '../../styles/elements/TextInput';
import theme from '../../styles/theme';
const TextInputRNBase = (props, ref) => {
    const { active, autoComplete, customStyle, editable, hasError, id, keyboardType, maxLength, onFocus, onBlur, onKeyPress, onChangeText, placeholder, placeholderTextColor, prefix, value, underlineColorAndroid, inputType, isHovered } = props, rest = __rest(props, ["active", "autoComplete", "customStyle", "editable", "hasError", "id", "keyboardType", "maxLength", "onFocus", "onBlur", "onKeyPress", "onChangeText", "placeholder", "placeholderTextColor", "prefix", "value", "underlineColorAndroid", "inputType", "isHovered"]);
    return (<StyledTextInput active={active} autoCompleteType={autoComplete} dataSet={{ 'brighte-class': 'brighte-input' }} editable={editable} hasError={hasError} inputType={inputType} onFocus={onFocus} onBlur={onBlur} onKeyPress={onKeyPress} prefix={prefix} customStyle={customStyle} hovered={isHovered} keyboardType={keyboardType} onChangeText={onChangeText} placeholder={placeholder} placeholderTextColor={placeholderTextColor} secureTextEntry={inputType === InputType.PASSWORD && true} maxLength={maxLength} value={value} underlineColorAndroid={underlineColorAndroid} ref={ref} {...(id ? { nativeID: id } : {})} {...rest}/>);
};
const TextInputRN = React.forwardRef(TextInputRNBase);
TextInputRN.displayName = 'TextInputRN';
TextInputRN.defaultProps = {
    active: false,
    autoComplete: 'off',
    customStyle: {},
    inputType: 'text',
    maxLength: 255,
    onBlur: noop,
    onChangeText: noop,
    onFocus: noop,
    placeholder: '',
    placeholderTextColor: theme.MediumText,
    keyboardType: 'default',
    underlineColorAndroid: 'rgba(0,0,0,0)',
    value: '',
    editable: true,
};
export default TextInputRN;
