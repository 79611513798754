var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useContext, useState } from 'react';
import { Animated } from 'react-native';
import has from 'lodash/has';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { doValidate } from '@brighte/brighte-one-core';
import useAnimation from '../../core/hooks/useAnimation';
import { GroupContext } from './index';
import { TextInputContainerPlain } from '../TextInputContainer';
import { SelectionContainerPlain } from '../SelectionContainer';
const LookupField = (props) => {
    const { keyName, fieldType, required, fieldName, onChangeText, children, validation } = props, rest = __rest(props, ["keyName", "fieldType", "required", "fieldName", "onChangeText", "children", "validation"]);
    const [groupState, setGroupState] = useContext(GroupContext);
    const [fieldValue, setFieldValue] = useState('');
    const [animationValue] = useAnimation(groupState.isManualEnter ? 1 : 0, [groupState]);
    const onSelectedItemsChange = (txt) => {
        const valid = !isEmpty(txt);
        const error = valid ? '' : 'This field is required.';
        const update = { value: txt, required, valid, errorMessage: error, error };
        setGroupState((state) => (Object.assign(Object.assign({}, state), { [keyName]: Object.assign(Object.assign({}, state[keyName]), update) })));
        if (onChangeText) {
            onChangeText(txt);
        }
    };
    const handleOnBlur = () => {
        var _a, _b, _c;
        const options = {
            key: keyName,
            val: (_a = groupState[keyName]) === null || _a === void 0 ? void 0 : _a.value,
            validation,
            validationMessage: props.validationMessage,
        };
        const result = doValidate(options);
        const valid = !((_b = result[keyName]) === null || _b === void 0 ? void 0 : _b.error);
        const error = ((_c = result[keyName]) === null || _c === void 0 ? void 0 : _c.error) || null;
        setGroupState((state) => (Object.assign(Object.assign({}, state), { [keyName]: Object.assign(Object.assign({}, state[keyName]), { valid, errorMessage: error, error }) })));
    };
    const displayValue = get(groupState[keyName], 'value', '');
    return (<Animated.View style={{ opacity: animationValue, flex: 1 }}>
      {fieldType === 'Selection' && (<SelectionContainerPlain {...groupState.otherProps} {...rest} onSelectedItemsChange={onSelectedItemsChange} required={required} value={has(groupState[keyName], 'value') && Array.isArray(displayValue) ? displayValue : [displayValue]} errorMessage={has(groupState[keyName], 'error') ? groupState[keyName].error : undefined}/>)}
      {fieldType === 'TextInput' && (<TextInputContainerPlain onChangeText={onSelectedItemsChange} required={required} value={has(groupState[keyName], 'value') && displayValue !== fieldValue ? setFieldValue(displayValue) : fieldValue} errorMessage={has(groupState[keyName], 'error') ? groupState[keyName].error : undefined} onBlur={handleOnBlur} {...groupState.otherProps} {...rest}/>)}
      {children !== undefined && children(Object.assign(Object.assign({}, groupState.otherProps), rest))}
    </Animated.View>);
};
LookupField.defaultProps = {
    fieldType: 'TextInput',
    inputType: 'datalookup',
    required: true,
};
LookupField.displayName = 'LookupField';
export default LookupField;
