import React, { useState, useEffect, useMemo } from 'react';
import { Platform, Modal } from 'react-native';
import StyledModal from '../styles/blocks/Modal';
import withResponsive from '../core/hoc/withResponsive';
const ModalContainer = props => {
    const { id, children, childrenContentWidth, customStyle, boxStyle, contentWidth, visible, onClose, onCancel, onAgree, title, subtitle, displayOverlay, responsiveClass, } = props;
    const [isVisible, setVisible] = useState(visible);
    const { OS } = Platform;
    useEffect(() => {
        if (visible) {
            setVisible(visible);
        }
    }, [visible]);
    const closeModal = () => {
        setVisible(false);
        if (onClose) {
            onClose();
        }
    };
    const canelModal = () => {
        setVisible(false);
        if (onCancel) {
            onCancel();
        }
    };
    const agreeClick = () => {
        if (onAgree) {
            onAgree();
        }
    };
    useMemo(() => {
        if (visible === false) {
            setVisible(false);
        }
    }, [visible]);
    const ModalObj = OS === 'web' ? StyledModal : Modal;
    const isDisplayOverlay = OS === 'web' ? displayOverlay : false;
    return (<ModalObj visible={isVisible} customStyle={customStyle} dataSet={{ 'brighte-class': 'brighte-modal' }} nativeID={`brighte-modal-container-${id}`}>
      <StyledModal.Container dataSet={{ 'brighte-class': 'brighte-modal-container' }} customStyle={boxStyle}>
        <StyledModal.Header dataSet={{ 'brighte-class': 'brighte-modal-header' }}>
          <StyledModal.OuterCell width={contentWidth}>
            <StyledModal.InnerCell>
              <StyledModal.Title>{title && title}</StyledModal.Title>
              <StyledModal.Subtitle>{subtitle && subtitle}</StyledModal.Subtitle>
            </StyledModal.InnerCell>
            <StyledModal.CloseIcon onPress={closeModal}/>
          </StyledModal.OuterCell>
        </StyledModal.Header>
        <StyledModal.BodyScrollWrapper childrenWidth={childrenContentWidth} width={contentWidth}>
          <StyledModal.BodyCell dataSet={{ 'brighte-class': 'brighte-modal-body' }} responsiveClass={childrenContentWidth ? '' : responsiveClass}>
            {children && children}
          </StyledModal.BodyCell>
        </StyledModal.BodyScrollWrapper>
        {(onCancel || onAgree) && (<StyledModal.Footer dataSet={{ 'brighte-class': 'brighte-modal-footer' }}>
            <StyledModal.FooterOuterCell responsiveClass={responsiveClass} width={contentWidth}>
              {onCancel && (<StyledModal.CancelButton dataSet={{ 'brighte-class': 'brighte-modal-cancel' }} label="Cancel" btnTheme="white" onPress={canelModal}/>)}
              {onAgree && (<StyledModal.AgreeButton dataSet={{ 'brighte-class': 'brighte-modal-agree' }} label="Agree" onPress={agreeClick}/>)}
            </StyledModal.FooterOuterCell>
          </StyledModal.Footer>)}
      </StyledModal.Container>
      {isDisplayOverlay && isVisible && <StyledModal.Overlay dataSet={{ 'brighte-class': 'brighte-modal-overlay' }}/>}
    </ModalObj>);
};
ModalContainer.defaultProps = {
    customStyle: {},
    boxStyle: {},
    visible: false,
    displayOverlay: false,
};
export default withResponsive(ModalContainer);
