var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import { flex, drawDebug, applyResponsiveness } from '@brighte/brighte-one-core';
const Col = styled((_a) => {
    var { flexDirection, customStyle, theme, inputGroup } = _a, props = __rest(_a, ["flexDirection", "customStyle", "theme", "inputGroup"]);
    return <View {...props}/>;
}) `
  flex-basis: auto;
  ${props => props.debug && drawDebug()};
  ${props => !applyResponsiveness(props.responsiveClass, props.responsiveBreakPoint) && flex(props)};
  ${({ customStyle }) => customStyle};
`;
Col.defaultProps = {};
export default Col;
