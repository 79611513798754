var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState, useEffect, useRef, createContext, useContext } from 'react';
import { Animated, FlatList, View, Platform } from 'react-native';
import omit from 'lodash/omit';
import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { recursiveMap, withForm, useEffectOnChange } from '@brighte/brighte-one-core';
import Loading from '../../components/Loading';
import Selection from '../../styles/blocks/Selection';
import OptionRow from '../../styles/blocks/OptionRow';
import useAnimation from '../../core/hooks/useAnimation';
import withVisibility from '../../core/hoc/withVisibility';
import withResponsive from '../../core/hoc/withResponsive';
import { InputGroupContext } from '../../components/Grid/Col';
import theme from '../../styles/theme';
export const GroupContext = createContext({});
export const DataLookupContainer = (props) => {
    const { fieldName, id, label, customStyle: { customStyleContainer, customStyleInput, customStyleDropdown, customStyleOption, customStyleLabel }, inputType, defaultItems, children, onSelectedItemsChange, searchInputPlaceholderText, searchSeparator, onChangeInput, apiFunction, onSelectedItemAction, errorMessage, closeLabel, enterManuallyText, inputGroup, onChangeValue, value, required, inputGroupLength, displayDetailFields, hideSearchOnManual, touched } = props, rest = __rest(props, ["fieldName", "id", "label", "customStyle", "inputType", "defaultItems", "children", "onSelectedItemsChange", "searchInputPlaceholderText", "searchSeparator", "onChangeInput", "apiFunction", "onSelectedItemAction", "errorMessage", "closeLabel", "enterManuallyText", "inputGroup", "onChangeValue", "value", "required", "inputGroupLength", "displayDetailFields", "hideSearchOnManual", "touched"]);
    const initValueConstructor = () => {
        const initValue = {};
        if (isEmpty(value)) {
            recursiveMap(children, child => {
                if (child.type.displayName === 'LookupField') {
                    initValue[get(child, 'props.keyName', null)] = {
                        value: '',
                        required: get(child, 'props.required', false),
                        validation: get(child, 'props.validation', null),
                        validationMessage: get(child, 'props.validationMessage', null),
                    };
                }
            });
        }
        return value || initValue;
    };
    const [inputGroupState, setState] = useContext(InputGroupContext);
    const topErrorMessage = get(errorMessage, 'searchText.error', false);
    const isError = !isEmpty(errorMessage);
    const [itemList, setItemList] = useState(defaultItems);
    const [isActive, setActive] = useState(false);
    const [isTyping, setTyping] = useState(false); // monitor search box typing action
    const [isLoading, setLoading] = useState(false);
    const [isManualEnterOpen, setManualEnter] = useState(displayDetailFields);
    const [contextState, setContextState] = useState(initValueConstructor);
    const [animateDropdown] = useAnimation(isActive ? 1 : 0, [isActive]);
    const [animationValue] = useAnimation(isError && !isActive && !isLoading ? 1 : 0, [isError]);
    const blur = useRef(null);
    const mergeError = (contexts, errors) => {
        if (errors) {
            Object.keys(contexts).forEach(key => {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                    contexts[key].error = errors[key].error;
                }
                else {
                    delete contexts[key].error;
                }
            });
        }
        return contexts;
    };
    const formtSearchAddress = context => {
        let updateSearchText = '';
        const cleanContextState = omit(context, ['searchText']);
        Object.keys(cleanContextState).forEach(key => {
            updateSearchText += !isEmpty(get(cleanContextState[key], 'value'))
                ? get(cleanContextState[key], 'value') +
                    (Object.keys(cleanContextState).slice(-1)[0] === key ? '' : searchSeparator)
                : '';
        });
        return updateSearchText;
    };
    useEffect(() => {
        if (inputGroup) {
            // this is to track state of other fields in input group
            if (inputGroupLength) {
                setState(state => (Object.assign(Object.assign({}, state), { [inputGroupLength.index]: { position: inputGroupLength.index + 1, active: isActive, isError } })));
            }
        }
    }, [isError, isActive]);
    useEffectOnChange(() => {
        if (!required) {
            Object.keys(contextState).forEach(key => {
                contextState[key] = Object.assign(Object.assign({}, contextState[key]), { required });
            });
        }
        if (onChangeValue) {
            // manual validation to be triggered anytime contextState changes to ensure field valid state is up to date
            const manualValidation = (args) => {
                const isValid = Object.keys(args.inputValue).every((key) => { var _a, _b; return key === 'searchText' || ((_a = args.inputValue[key]) === null || _a === void 0 ? void 0 : _a.required) === false || ((_b = args.inputValue[key]) === null || _b === void 0 ? void 0 : _b.valid); });
                return { isValid, error: null };
            };
            const updatedValue = mergeError(Object.assign(Object.assign({}, contextState), { searchText: !isManualEnterOpen
                    ? get(contextState, 'searchText')
                    : { value: formtSearchAddress(contextState) } }), errorMessage);
            onChangeValue(updatedValue, manualValidation);
        }
    }, [contextState]);
    useEffectOnChange(() => {
        if (touched && !isEmpty(itemList)) {
            setContextState(Object.assign(Object.assign({}, contextState), { searchText: get(value, 'searchText') }));
            return;
        }
        const cleanContextState = omit(contextState, ['searchText']);
        // this resets the value if no itemlist found
        const updateState = Object.keys(cleanContextState).reduce((acc, item) => (Object.assign(Object.assign({}, acc), { [item]: Object.assign(Object.assign({}, contextState[item]), { value: '' }) })), {});
        setContextState(Object.assign(Object.assign({}, updateState), { searchText: Object.assign({}, get(value, 'searchText')) }));
    }, [itemList]);
    const performItemLookup = (val) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            return !isEmpty(val) && onSelectedItemAction ? yield onSelectedItemAction(val) : {};
        }
        catch (error) {
            console.log(error);
            return {};
        }
    });
    const toggleSelector = (display) => setActive(display !== undefined ? display : !isActive);
    const toggleManualEnter = () => {
        setManualEnter(!isManualEnterOpen);
        toggleSelector(false);
    };
    const performLookup = useRef(debounce((val) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (!isEmpty(val)) {
                setLoading(true);
                const listSuggestion = yield apiFunction(val);
                setLoading(false);
                setItemList(listSuggestion);
            }
        }
        catch (error) {
            // TODO This is temp solution for handling api error, Please redesign if needed.
            setLoading(false);
            if (!isManualEnterOpen) {
                toggleManualEnter();
            }
        }
    }), 1000));
    const resetContext = () => {
        const context = Object.assign({}, contextState);
        Object.keys(context).forEach((key) => {
            if (key !== 'searchText') {
                context[key].value = '';
            }
        });
        if (!isEqual(context, contextState)) {
            setContextState(context);
        }
    };
    const onInputTextChange = (val) => {
        if (!isActive) {
            toggleSelector();
        }
        if (onChangeInput) {
            onChangeInput(val);
        }
        if (onChangeValue) {
            onChangeValue(Object.assign(Object.assign({}, value), { searchText: { value: val, required } }));
        }
        performLookup.current(val);
        setTyping(true);
        resetContext();
    };
    // updates context values based on values returned from api lookup
    const updateContexts = (apiValue, labelField) => {
        const newContexts = {};
        Object.keys(apiValue).forEach(key => {
            var _a, _b;
            if (has(contextState, key)) {
                const contextValue = ((_a = apiValue[key]) === null || _a === void 0 ? void 0 : _a.toString()) || '';
                // ensure valid and error states are updated
                const valid = key === 'searchText' || ((_b = contextState[key]) === null || _b === void 0 ? void 0 : _b.required) === false || contextValue.trim().length > 0;
                const error = valid ? '' : 'This field is required.';
                newContexts[key] = Object.assign(Object.assign({}, contextState[key]), { value: contextValue, valid, error });
            }
        });
        setContextState(Object.assign(Object.assign({}, newContexts), { searchText: { value: labelField, required } }));
    };
    const toggleItem = (item) => () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (!has(item, 'disabled') || (has(item, 'disabled') && !item.disabled)) {
                let itemValue = {};
                toggleSelector();
                setLoading(true);
                itemValue = yield performItemLookup(item);
                setLoading(false);
                updateContexts(!isEmpty(itemValue) ? itemValue : item.value, item.label);
                onSelectedItemsChange(item);
                setTyping(false);
            }
        }
        catch (error) {
            setLoading(false);
            if (!isManualEnterOpen) {
                toggleManualEnter();
            }
        }
    });
    const renderOptions = (item) => {
        if (item === null) {
            return isManualEnterOpen ? '' : enterManuallyText;
        }
        return item.label;
    };
    const getOptionRow = (item, index = 0) => (<OptionRow dataSet={{
        'brighte-class': item === null ? 'brighte-datalookup-manualenter-row' : 'brighte-datalookup-option-row',
    }} onPress={item === null ? toggleManualEnter : toggleItem(item)} customStyle={customStyleOption}>
      <OptionRow.Text nativeID={id
        ? `brighte-datalookup-option-value-${id}-${index}`
        : `brighte-datalookup-option-value-${fieldName}-${index}`} disabled={get(item, 'disabled', false)}>
        {renderOptions(item)}
      </OptionRow.Text>
    </OptionRow>);
    const formatItem = (items) => items.map((item) => {
        const itemAfterFormat = Object.assign({ label: '', value: '' }, item);
        Object.keys(item).forEach(key => {
            switch (key) {
                case 'label':
                    itemAfterFormat.label = item[key];
                    break;
                case 'value':
                    itemAfterFormat.value = item[key];
                    break;
                case 'disabled':
                    itemAfterFormat.disabled = item[key];
                    break;
                default:
                    if (!itemAfterFormat.label) {
                        itemAfterFormat.label = item[key];
                    }
                    if (!itemAfterFormat.value) {
                        itemAfterFormat.value = item[key];
                    }
                    break;
            }
        });
        return itemAfterFormat;
    });
    const renderItems = () => {
        let itemListDom;
        const renderItemList = formatItem(itemList);
        if (itemList.length) {
            itemListDom = (<Selection.OptionsList keyExtractor={(item, index) => index.toString()} as={FlatList} data={renderItemList} renderItem={({ item, index }) => getOptionRow(item, index)}/>);
        }
        else {
            itemListDom = displayDetailFields !== true && (<Selection.OptionsList as={View}>{getOptionRow(null)}</Selection.OptionsList>);
        }
        return <>{itemListDom}</>;
    };
    const showSearch = !isManualEnterOpen || !hideSearchOnManual;
    return (<>
      {showSearch && (<Selection nativeID={`brighte-datalookup-container-${id}`} dataSet={{ 'brighte-class': 'brighte-datalookup-container' }}>
          <Selection.LabelContainer>
            <Selection.Label customStyle={customStyleLabel} dataSet={{ 'brighte-class': 'brighte-datalookup-label' }} text={label}/>
          </Selection.LabelContainer>
          <View {...Platform.select({
        web: {
            onMouseUp: () => {
                blur.current = true;
            },
            onMouseDown: () => {
                blur.current = false;
            },
        },
    })}>
            <Selection.InputCell style={{
        backgroundColor: animationValue.interpolate({
            inputRange: [0, 1],
            outputRange: [theme.WhiteColor, theme.LightRed],
        }),
    }} dataSet={{ 'brighte-class': 'brighte-datalookup-inputcell', error: isError }} nativeID={`brighte-datalookup-inputcell-${id}`} inputGroupState={inputGroup && inputGroupState} hasError={required && !isActive && !isLoading ? isError : false} active={isActive} onFocus={() => setActive(true)} inputGroupLength={inputGroupLength} customStyle={customStyleContainer} {...rest}>
              <Selection.TagsWrapper dataSet={{ 'brighte-class': 'brighte-datalookup-tags-wrapper' }}>
                <Selection.TextInput dataSet={{ 'brighte-class': 'brighte-datalookup-textinput' }} onChangeText={onInputTextChange} placeholder={searchInputPlaceholderText} underlineColorAndroid="transparent" style={{ flex: 1 }} value={get(value, 'searchText.value', '')} customStyle={customStyleInput} onBlur={() => {
        if (blur.current) {
            setActive(false);
        }
    }}/>
              </Selection.TagsWrapper>
              {isLoading && <Loading size={25}/>}
            </Selection.InputCell>
            {isActive && (<Selection.OptionsWrapper customStyle={customStyleDropdown} dataSet={{ 'brighte-class': 'brighte-datalookup-options-wrapper' }} style={{
        transform: [{ scaleY: animateDropdown }],
    }} as={Animated.View}>
                {renderItems()}
              </Selection.OptionsWrapper>)}
          </View>
        </Selection>)}
      <View>
        {isError && (<Selection.ErrorMessage as={Animated.Text} style={{ opacity: animationValue, transform: [{ scaleY: animationValue }] }}>
            {required &&
        ((topErrorMessage === true && !isManualEnterOpen && !isTyping && setManualEnter(true)) ||
            topErrorMessage)}
          </Selection.ErrorMessage>)}
        <Selection.SwitchWrapper onPress={toggleManualEnter} isManualEnter={displayDetailFields !== true} displayText={isManualEnterOpen ? closeLabel : enterManuallyText} rightAligned={showSearch}/>
        <GroupContext.Provider value={[
        Object.assign(Object.assign({}, mergeError(contextState, errorMessage)), { isManualEnter: displayDetailFields || isManualEnterOpen, otherProps: rest }),
        setContextState,
    ]}>
          {(displayDetailFields || isManualEnterOpen) && (<Selection.ContextWrapper>{children}</Selection.ContextWrapper>)}
        </GroupContext.Provider>
      </View>
    </>);
};
DataLookupContainer.defaultProps = {
    closeLabel: 'Back to search',
    customStyle: {},
    defaultItems: [],
    value: { searchText: { value: '', required: true, error: false } },
    inputType: 'datalookup',
    displayDetailFields: false,
    searchInputPlaceholderText: 'Search',
    enterManuallyText: 'Enter manually',
    onSelectedItemsChange: () => { },
    hideSearchOnManual: true,
    searchSeparator: ', ',
};
const DataLookup = withVisibility({ level: 'field' })(withForm(withResponsive(DataLookupContainer)));
const DataLookupWithType = (_a) => {
    var properties = __rest(_a, []);
    return <DataLookup {...properties} inputType="datalookup"/>;
};
export default DataLookupWithType;
