var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Animated } from 'react-native';
import styled from 'styled-components/native';
import theme from '../theme';
const ProgressBar = styled((_a) => {
    var { theme, customStyle, totalStep, currentStep, defaultProps } = _a, rest = __rest(_a, ["theme", "customStyle", "totalStep", "currentStep", "defaultProps"]);
    return (<Animated.View {...rest}/>);
}) `
  width: ${({ totalStep, currentStep }) => currentStep && totalStep ? ((currentStep / totalStep) * 100).toFixed(2) : 0}%;
  background-color: ${({ theme }) => theme.BrighteGreen};
  height: ${({ theme }) => theme.sixPT};
  z-index: 2;
  max-width: 100%;
  border-top-right-radius: ${({ totalStep, currentStep, theme }) => (currentStep / totalStep) * 100 >= 100 ? theme.borderRadius : 0};
  ${({ customStyle }) => customStyle};
`;
ProgressBar.defaultProps = {
    theme: {
        BrighteGreen: theme.BrighteGreen,
        sixPT: theme.sixPT,
    },
};
export default ProgressBar;
