var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import StyledText from '../../styles/elements/Text';
const Text = props => {
    const { customStyle, text, children, writingDirection } = props, rest = __rest(props, ["customStyle", "text", "children", "writingDirection"]);
    return (<StyledText dataSet={{ 'brighte-class': 'brighte-text' }} style={{
        writingDirection,
    }} customStyle={customStyle} {...rest}>
      {children || text}
    </StyledText>);
};
Text.defaultProps = {
    children: undefined,
    customStyle: {},
    id: '',
    text: '',
    writingDirection: 'ltr',
};
export default Text;
