import styled from 'styled-components/native';
import Text from '../../elements/Text';
import theme from '../../theme';
const Subtitle = styled(Text) `
  font-size: ${theme.subtitleFontSize};
  margin-top: ${theme.tenPT};
  ${props => props.customStyle}
`;
Subtitle.defaultProps = {
    theme: {
        subtitleFontSize: theme.subtitleFontSize,
        tenPT: theme.tenPT,
    },
};
export default Subtitle;
