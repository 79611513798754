import React from 'react';
import UnorderedListItem from './UnorderedListItem';
import { ListItemContainer } from '../../styles/elements/UnorderedList';
const UnorderedList = props => {
    const { customStyle, itemCustomStyle, items, prefix, prefixSize } = props;
    return (<ListItemContainer customStyle={customStyle}>
      {items.map(item => (<UnorderedListItem {...item} customStyle={itemCustomStyle} prefix={prefix} key={item.id} prefixSize={prefixSize}/>))}
    </ListItemContainer>);
};
UnorderedList.defaultProps = {
    customStyle: {},
    itemCustomStyle: {},
    prefix: '\u2022 ',
    prefixSize: undefined,
};
export default UnorderedList;
