import React, { useEffect, useRef } from 'react';
import { Animated } from 'react-native';
import IconMaterial from 'react-native-vector-icons/MaterialIcons';
import Icons from '../Icons';
import useAnimation from '../../core/hooks/useAnimation';
import theme from '../../styles/theme';
import StyledCountDown from '../../styles/blocks/CountDown';
export var Status;
(function (Status) {
    Status["SUCCESS"] = "success";
    Status["PROGRESS"] = "progress";
})(Status || (Status = {}));
const DEFAULT_VALUE = 0;
const CountDown = props => {
    const { status, count, value = DEFAULT_VALUE, size, iconSize, id, thickness, color, unfilledColor, children, duration, onChange, } = props;
    const animatedValue = useRef(new Animated.Value(value)).current;
    const [animatedStatus] = useAnimation(status ? 1 : 0, [status], duration);
    const [successAnimation] = useAnimation(status === Status.SUCCESS || status === Status.PROGRESS ? 1 : 0, [status], 500);
    const animateChange = (value) => Animated.spring(animatedValue, {
        toValue: value,
        useNativeDriver: true,
    }).start();
    useEffect(() => {
        animateChange(value);
        if (onChange) {
            onChange(value);
        }
    }, [value]);
    const animateColor = () => {
        if (status === Status.PROGRESS) {
            return theme.Yellow;
        }
        if (status === Status.SUCCESS) {
            return theme.BrighteGreen;
        }
        return color;
    };
    const renderHalfCircle = (isFlipped = false) => {
        return (<StyledCountDown.Half pointerEvents="none" size={size} isFlipped={isFlipped}>
        <StyledCountDown.InnerHalf as={Animated.View} size={size} style={{
            transform: [
                {
                    rotate: animatedValue.interpolate({
                        inputRange: isFlipped ? [0, 50] : [50, 100],
                        outputRange: isFlipped ? ['180deg', '0deg'] : ['-180deg', '0deg'],
                        extrapolate: 'clamp',
                    }),
                },
            ],
        }}>
          <StyledCountDown.InnerHalfWrapper size={size}>
            <StyledCountDown.InnerHalfBorder thickness={thickness} color={color} as={Animated.View} size={size} style={{
            borderColor: animatedStatus.interpolate({
                inputRange: [0, 1],
                outputRange: [color, status === 'success' ? theme.BrighteGreen : theme.Yellow],
            }),
        }}/>
          </StyledCountDown.InnerHalfWrapper>
        </StyledCountDown.InnerHalf>
      </StyledCountDown.Half>);
    };
    return (<StyledCountDown size={size} status={status} nativeID={id}>
      <StyledCountDown.ContentContainer dataSet={{ 'brighte-class': 'brighte-count-down-container' }} pointerEvents="box-none" size={size} unfilledColor={unfilledColor} thickness={thickness} status={status} as={Animated.View} style={{
        backgroundColor: animatedStatus.interpolate({
            inputRange: [0, 1],
            outputRange: [theme.WhiteColor, animateColor()],
        }),
    }}>
        {!children ? (<>
            {!status ? (<StyledCountDown.StatusWrapper dataSet={{ 'brighte-class': 'brighte-count-down-wrapper' }}>
                <StyledCountDown.Content status={status}>{count}</StyledCountDown.Content>
                <StyledCountDown.TextCircle status={status}>seconds</StyledCountDown.TextCircle>
              </StyledCountDown.StatusWrapper>) : (<>
                <StyledCountDown.Content dataSet={{ 'brighte-class': 'brighte-count-down-status-wrapper' }} status={status}>
                  {status === 'success' ? (<Animated.View style={{
        opacity: successAnimation,
        transform: [
            {
                rotateY: successAnimation.interpolate({
                    inputRange: [0, 1],
                    outputRange: ['0deg', '360deg'],
                }),
            },
        ],
    }}>
                      <Icons.Check size={iconSize} color={theme.WhiteColor}/>
                    </Animated.View>) : (<Animated.View style={{
        opacity: successAnimation,
        transform: [
            {
                rotateX: successAnimation.interpolate({
                    inputRange: [0, 1],
                    outputRange: ['0deg', '360deg'],
                }),
            },
        ],
    }}>
                      <IconMaterial style={{ color: theme.WhiteColor }} name="hourglass-empty" size={40}/>
                    </Animated.View>)}
                </StyledCountDown.Content>
                <StyledCountDown.TextCircle status={status}>
                  {status === 'success' ? 'Approved' : 'In progress'}
                </StyledCountDown.TextCircle>
              </>)}
          </>) : (children)}
      </StyledCountDown.ContentContainer>
      {renderHalfCircle()}
      {renderHalfCircle(true)}
    </StyledCountDown>);
};
CountDown.defaultProps = {
    value: DEFAULT_VALUE,
    size: 170,
    thickness: 7,
    color: theme.BrighteGreen,
    unfilledColor: theme.LightBackground,
    status: null,
    iconSize: 30,
    id: 'brighte-count-down',
    children: null,
    duration: 300,
};
export default CountDown;
