import styled from 'styled-components/native';
import Text from './Text';
import theme from '../theme';
const HelpText = styled(Text) `
  color: ${({ theme }) => theme.LightText};
  font-size: ${({ theme }) => theme.helpText};
  line-height: ${({ theme }) => theme.helpText};
  margin-top: ${({ theme }) => theme.fourPT};
  position: absolute;
  bottom: ${({ theme }) => -(theme.errorFontSize * 1.5)};
  ${({ customStyle }) => customStyle};
`;
HelpText.defaultProps = {
    theme: {
        MediumText: theme.MediumText,
        helpText: theme.helpText,
        LightText: theme.LightText,
        fourPT: theme.fourPT,
        errorFontSize: theme.errorFontSize,
    },
};
export default HelpText;
