import React from 'react';
import Text from '../Text';
import StyledAlert from '../../styles/blocks/Alert';
export var Type;
(function (Type) {
    Type["Success"] = "success";
    Type["Information"] = "information";
    Type["Danger"] = "danger";
    Type["Warning"] = "warning";
})(Type || (Type = {}));
const getIcon = ({ icon, type }) => {
    if (icon) {
        return icon;
    }
    switch (type) {
        case Type.Success:
            return 'tick-rounded';
        default:
            return 'information';
    }
};
const Alert = props => {
    const { backGround, children, customStyle: { iconCustomStyle, textCustomStyle, customStyleContainer } = {}, icon, type = 'success', size, text, } = props;
    return (<StyledAlert customStyle={customStyleContainer} type={type} backGround={backGround} dataSet={{ 'brighte-class': type ? `brighte-alert ${type}` : 'brighte-alert' }}>
      <StyledAlert.Icon type={type} name={getIcon({ icon, type })} size={size} customStyle={iconCustomStyle}/>
      {children || <Text customStyle={textCustomStyle}>{text}</Text>}
    </StyledAlert>);
};
Alert.defaultProps = {
    backGround: false,
    children: undefined,
    customStyle: {},
    icon: '',
    type: 'success',
    size: 19,
    text: '',
};
export default Alert;
