var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components/native';
import Text from '../../elements/Text';
import theme from '../../theme';
const ButtonText = styled((_a) => {
    var { btnTheme, disabled } = _a, props = __rest(_a, ["btnTheme", "disabled"]);
    return <Text {...props}/>;
}) `
  color: ${({ theme, btnTheme, disabled }) => {
    switch (btnTheme) {
        case 'green':
            return theme.WhiteColor;
        case 'grey':
            return disabled ? theme.FormElements : theme.MediumText;
        default:
            return disabled ? theme.Background : theme.MediumText;
    }
}};
  font-size: ${({ theme }) => theme.buttonFontSize};
  ${props => props.textCustomStyle}
`;
ButtonText.defaultProps = {
    btnTheme: 'green',
    disabled: false,
    theme: {
        FormElements: theme.FormElements,
        MediumText: theme.MediumText,
        WhiteColor: theme.WhiteColor,
        buttonFontSize: theme.buttonFontSize,
        DarkText: theme.DarkText,
        textFontSize: theme.textFontSize,
        textLineFontSize: theme.textLineFontSize,
        fonts: {
            regular: theme.fonts.regular,
        },
    },
};
export default ButtonText;
