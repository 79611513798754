var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components/native';
import Text from '../../elements/Text';
const color = ({ Background, DarkText, WhiteColor }, selected, disabled, hover) => {
    if (disabled) {
        return Background;
    }
    if (!disabled && hover) {
        return DarkText;
    }
    if (!disabled && !hover && selected) {
        return WhiteColor;
    }
    if (!disabled && !hover && !selected) {
        return DarkText;
    }
};
const StyledButtonText = styled((_a) => {
    var { btnTheme, selected, hover, theme } = _a, props = __rest(_a, ["btnTheme", "selected", "hover", "theme"]);
    return <Text {...props}/>;
}) `
  color: ${({ theme, selected, disabled, hover }) => color(theme, selected, disabled, hover)};
  font-size: ${({ theme }) => theme.labelFontSize};
  text-align: center;
  ${props => props.textCustomStyle}
`;
export default StyledButtonText;
