import { useRef, useEffect } from 'react';
export default (function (fn, dependecy) {
    var mounted = useRef(false);
    useEffect(function () {
        if (!mounted.current) {
            mounted.current = true;
            return;
        }
        if (mounted.current) {
            fn();
        }
    }, dependecy);
});
