import { View } from 'react-native';
import styled from 'styled-components/native';
import theme from '../theme';
const LabelContainer = styled(View) `
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-items: flex-start;
  margin-bottom: ${({ theme }) => theme.threePT};
  ${({ customStyle }) => customStyle};
`;
LabelContainer.defaultProps = {
    theme: {
        threePT: theme.threePT,
        twentyTwoPT: theme.twentyTwoPT,
    },
};
export default LabelContainer;
