import { Animated } from 'react-native';
import styled from 'styled-components/native';
const Ripple = styled(Animated.View) `
  width: ${props => props.radius * 2};
  height: ${props => props.radius * 2};
  border-radius: 100;
  overflow: hidden;
  position: absolute;
`;
export default Ripple;
